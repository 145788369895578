@import "~styles/color";

.wrapper {
  margin-bottom: 56px;
  text-align: left;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
}

.header {
  color: $gray_9;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 450px) {
    padding: 16px;
    color: $gray_10;
  }
}

.title {
  margin-bottom: 16px;
  color: $black;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
    padding: 11px 16px;
    font-size: 14px;
    color: $gray_7;
    background-color: $gray_1;
  }
}

.contentBox {
  border: 1px solid $gray_3;

  @media screen and (max-width: 450px) {
    border: none;
  }

  .modify {
    flex: 1;
    text-align: right;
    color: $blue;
    cursor: pointer;
  }

  .reset {
    @extend .modify;
  }

  .description {
    flex: 1;
    font-size: 12px;
    text-align: right;
    color: $gray_7;

    @media screen and (max-width: 450px) {
      display: inline-block;
      width: 200px;
      padding: 10px 0 10px 60px;
      line-height: 1.7;
      text-align: right;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 68px;
    line-height: 68px;
    font-size: 16px;
    color: $gray_8;

    @media screen and (max-width: 450px) {
      height: 60px;
      line-height: 60px;
      color: $gray_10;
      font-size: 14px;
    }

    &:not(:last-child) {
      box-sizing: border-box;
      border-bottom: 1px solid $gray_3;
    }

    svg {
      margin: 14px 12px 14px 0;
      vertical-align: bottom;

      @media screen and (max-width: 450px) {
        margin: 10px 12px 10px 0;
      }
    }
  }
}

.switch {
  flex: 1;
  margin: 22px 0;
  font-size: 16px;
  text-align: right;
  border: none;
  cursor: pointer;
  outline: none;
  color: $blue;

  &.connect {
    color: $red;
  }

  @media screen and (max-width: 450px) {
    margin: 0;
    line-height: 55px;
    font-size: 14px;
  }
}

.hide {
  visibility: hidden;
}

.accounts-tab {
  @media screen and (max-width: 450px) {
    margin-left: 0px;
  }
}
