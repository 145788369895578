.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 16px;
  border-radius: 4px;
  background-color: #495057;
  color: #fff;
  font-size: 9px;
  font-weight: 400;
}
