@import "~styles/color";

.wrapper {
  width: 592px;
  text-align: left;

  .title {
    display: flex;
    font-size: 16px;
    color: $gray_10;
    font-weight: 500;

    &.space-between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 21px;
    }

    .description {
      font-size: 14px;
      color: $gray_7;
    }
  }

  .bold {
    font-weight: bold;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    margin-bottom: 20px;

    .close {
      cursor: pointer;
    }
  }

  .product-wrapper {
    margin-bottom: 44px;
  }

  .option-wrapper {
    margin: 16px 0px;
  }

  .type {
    width: 100%;
    font-size: 15px;
    padding: 13px 12px;
    border: solid 1px $gray_6;
    border-radius: 4px;
    outline: none;

    .placeholder {
      color: $gray_6;
    }

    > option {
      color: $gray_8;
    }
    &::-ms-expand {
      display: none;
    }
  }

  .secret-label {
    display: flex;
    align-items: center;
    cursor: pointer;

    .checkbox {
      width: 20px;
      height: 20px;
    }

    .secret-title {
      font-size: 16px;
      color: $gray_8;
      margin-left: 8px;
    }
  }

  .contents {
    width: 94.5%;
    height: 200px;
    padding: 16px;
    margin-bottom: 19px;
    font-size: 14px;
    font-family: "Noto Sans KR", sans-serif;
    color: $gray_8;
    line-height: 1.5;
    background-color: $gray_1;
    border: none;
    border-radius: 4px;
    resize: none;
    outline: none;

    &::placeholder {
      color: $gray_6;
      font-family: "Noto Sans KR", sans-serif;
    }
  }
}

.footer {
  width: 100%;
  margin-top: 19px;
}

.completed {
  display: block;
  width: 100%;
  height: 46px;
  cursor: pointer;
  background-color: $blue;
  border-radius: 4px;
  font-size: 15px;
  color: $white;

  &:disabled {
    background-color: 4px;
    background-color: $gray_3;
  }
}

.claim-description {
  font-size: 13px;
  line-height: 1.7;
  color: $gray_half_8;
  margin-bottom: 48px;

  .claim-link {
    display: inline;
    color: $gray_half_8;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: calc(100% - 32px);
    padding: 0px 18px;

    .contents {
      width: calc(100% - 32px);
    }
  }

  .close {
    margin-right: 10px;
  }

  .title-text {
    padding-top: 3px;
    font-size: 18px;
    font-weight: normal;
  }

  .completed {
    width: 56px;
    font-size: 18px;
    padding: 0px;
    color: $blue;
    font-weight: normal;
    background-color: inherit;

    &:disabled {
      color: $gray_4;
      background-color: inherit;
    }
  }

  .claim-description {
    font-size: 12px;
    line-height: 1.83;
    color: $gray_7;
  }
}
