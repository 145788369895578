.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 70px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding-right: 0px;
  }
}
