@import "~styles/color";

.main {
  .background {
    .wrapper {
      display: flex;

      margin-bottom: 40px;
    }

    .thumbnail {
      flex-basis: 100px;
      max-width: 60px;
      height: 60px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      display: flex;
      align-items: center;

      height: 50%;
    }

    .bookmark {
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      color: $blue;
      border: 1px solid $blue;

      height: 30px;
      border-radius: 6px;
      padding: 6px 12px;
      font-size: 0.9rem;
      width: 79px;

      &.isBookmark {
        background-color: $blue;
        color: white;

        transition: 0.3s;
      }
    }

    .message {
      display: flex;
      align-items: center;

      height: 50%;

      &-text {
        color: $gray_6;

        font-size: 0.85rem;
      }
    }
  }

  .condition {
    display: flex;
    height: 68px;
    line-height: 68px;
    font-size: 14px;

    &-display {
      display: none;

      &.visible {
        display: block;
      }
    }

    &-text {
      color: $blue;
    }

    &-reset {
      cursor: pointer;
      background-color: $white;

      border: solid 0.5px $gray_7;
      height: 20px;
      line-height: 17px;
      border-radius: 22px;
    }
  }

  .hidden-card {
    width: 18.5%;
    visibility: hidden;
  }
}

.main {
  min-height: 8200px;
}

@media screen and (max-width: 450px) {
  .main {
    margin-left: 0;

    .background {
      background-color: $white;
      height: 108px;
      box-sizing: border-box;

      .wrapper {
        padding: 24px 16px;
      }
    }

    .hidden-card {
      width: 31.5%;
    }
  }
}
