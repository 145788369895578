@import "~styles/color";

.button-wrapper {
  position: fixed;

  bottom: 80px;
  right: 12px;
  width: 38px;
  height: 76px;
  z-index: 99;

  .button {
    cursor: pointer;
    padding: 0;
  }

  .gender {
    visibility: hidden;
  }
}

@media screen and (max-width: 450px) {
  .button-wrapper {
    .button {
      padding: 0;
    }

    .gender {
      visibility: visible;
      font-weight: bold;
      border: 1px solid $gray_4;
      background-color: $cream_light;
      color: $gray_10;

      font-size: 8px;

      margin-bottom: 10px;
      width: 38px;
      height: 38px;
    }
  }
}
