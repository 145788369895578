@import "~styles/color";

.coupon-tab {
  padding-left: 10px;
  box-sizing: content-box;

  select {
    border: 1px solid $gray_7;
    width: 97%;
    height: 36px;
    font-size: 14px;
    margin: 20px 15px 0;
    padding: 0 25px 0 10px;

    &:invalid {
      color: $gray_7;
      font-weight: 500;
    }
  }

  .wrapper-head {
    text-align: left;
    margin-bottom: 40px;

    button {
      padding: 0;
    }

    .head {
      color: $gray_9;
      font-size: 20px;
      letter-spacing: 0.5px;
    }

    .back {
      display: none;
    }
  }

  .list-contents {
    margin-bottom: 64px;

    .list {
      background-color: $gray_0;
      padding-bottom: 20px;
      font-size: 14px;

      .link {
        color: $gray_8;
      }

      .title {
        text-align: left;
        font-weight: 500;
        background-color: $gray_2;
        padding: 10px 12px;
      }

      li {
        display: flex;
        align-items: flex-start;
        padding: 20px 15px 0;

        .thumbnail {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          text-align: left;
          line-height: 180%;
          padding-left: 10px;
          flex: 0.7;

          .red {
            color: $red;
            font-weight: 400;
          }
        }

        .price {
          align-self: flex-end;
          text-align: right;
          font-weight: 500;
          flex: 0.3;

          .prev {
            text-decoration: line-through;
            color: $gray_4;
            font-weight: 500;
          }
        }
      }
    }
  }

  .not-orderable {
    pointer-events: none;
    background-color: $gray_5;
  }

  .btn-select {
    cursor: pointer;
    text-align: center;
    margin: auto;
    background-color: $red;
    color: $white;
    width: 40%;
    font-size: 18px;
    line-height: 52px;
    border-radius: 4px;
    z-index: 1;
  }

  @media screen and (max-width: 450px) {
    margin: 0 auto;
    width: 100%;
    padding-left: 0;

    select {
      width: 92%;
    }

    .wrapper-head {
      border-bottom: 1px solid $gray_3;
      margin: -15px 0 0;
      padding: 30px 12px 13px;

      .head {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 18px;
      }

      .back {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        padding: 0;
      }
    }

    .list-contents {
      .list {
        background-color: $white;
      }
    }

    .btn-select {
      position: fixed;
      border: 10px solid $white;
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 13px;
    }

    .prev {
      display: block;
    }
  }
}
