@import "~styles/color";

.title {
  box-sizing: border-box;
  font-weight: bold;
  color: $gray_10;
  text-align: left;

  font-size: 20px;
  margin-bottom: 40px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  height: 29px;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.store {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: $white;

  width: 424px;
  height: 84px;

  padding-top: 16px;
  padding-bottom: 18px;

  border-bottom: solid 1px $gray_3;
}

.mobile {
  display: none;
}

@media screen and (max-width: 450px) {
  .main {
    background-color: $gray_1;
    margin-left: 0;
  }

  .title {
    display: none;
  }

  .section {
    flex-direction: column;
  }

  .mobile {
    display: block;
  }
}
