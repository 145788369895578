@import "~styles/color";
@import "~styles/variables";

.review-wrapper {
  text-align: left;
  width: 100%;
  padding: 0px 3px 25px 3px;
  border-bottom: solid 1px $gray_3;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  > * {
    margin-right: 8px;
  }
}

.author-name {
  color: $gray_6;
  font-size: 11px;
}

.author-specifications {
  color: $gray_half_8;
  font-size: 11px;
}

.summary-wrapper {
  display: flex;
  align-items: center;
  max-width: 348px;
  height: 34px;
  border: 1px solid $gray_1;
  margin-top: 16px;
}

.reviewStarWrapper {
  padding-left: 8px;
  padding-right: 8px;
}

.summary-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  border-left: 1px solid $gray_1;

  &.good {
    color: $good;
  }

  &.soso {
    color: $soso;
  }

  &.bad {
    color: $bad;
  }
}

.body {
  text-align: left;
  width: 100%;
  font-size: 12px;
  margin-top: 23px;
  color: $gray_9;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 10px;
  color: $gray_6;
  margin-top: 18px;

  .recommend-wrapper {
    display: flex;

    &.isLookpinReview {
      cursor: pointer;
    }
  }

  .like-count {
    margin-left: 4px;
    font-size: 12px;

    &.isLiked {
      color: #d91a1d;
    }
  }
}

.images-wrapper {
  display: flex;
  margin-top: 16px;

  picture {
    cursor: pointer;
    margin-right: 4px;
  }
}

.review-contents {
  white-space: pre-wrap;
  color: $gray_9;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
}

.product-option-wrapper {
  font-size: 12px;
  margin-top: 16px;
  color: $gray_7;
}

.reviewStarBorder {
  padding: 8px;
  border: solid 1px $gray_3;
}

.review-images {
  width: 64px;
  height: 64px;
}

@media (max-width: 450px) {
  .review-wrapper {
    border-bottom: none;
    padding: 25px 0px 0px 0px;

    > * {
      width: calc(100% - 8px);
      margin-left: 4px;
      margin-right: 4px;
    }

    & + & {
      border-top: solid 1px $gray_3;
    }
  }
}
