@import "~styles/color";

.photoReviewDetailWrapper {
  display: flex;
  flex-direction: column;
  width: 882px;
  height: 568px;
  padding: 0px 11px 0px 11px;
  background-color: $white;
}

.photoReviewDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0px 11px;
  .detailHeadTextStyle {
    font-size: 16px;
    font-weight: bold;
    color: $gray_10;
  }
  .countByNumber {
    margin-left: 8px;
    font-size: 16px;
    color: $gray_8;
  }
  .selectNumber {
    font-weight: bold;
    color: $blue;
  }
}

.phtoReviewdetailBody {
  display: flex;
  height: 460px;
  margin-top: 20px;
  .photoReviewdetailNavigation {
    display: flex;
    align-items: center;
    width: 42px;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
  }
  .photoReviewDetailContentsWrapper {
    display: flex;
    width: 798px;
  }
}

.photoReviewDetailContents {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 16px;
  width: 368px;
  &.isCrema {
    justify-content: space-between;
  }
  .buyOptions {
    font-size: 12px;
    color: $gray_7;
    margin-bottom: 16px;
  }
  .reviewSumary {
    display: flex;
    margin-bottom: 20px;
  }
  .reviewContents {
    font-size: 12px;
    color: $gray_9;
    margin-bottom: 16px;
    line-height: 16px;
    > pre {
      white-space: pre-wrap;
    }
  }
  .reviewCreatedAt {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: $gray_6;
    margin-bottom: 32px;
    .recommendText {
      font-size: 12px;
    }
    &.isCrema {
      margin-bottom: 0px;
    }
  }
  .recommendWrapper {
    display: flex;
    justify-content: center;
  }
}

.reviewAuthor {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 18px;
  height: 14px;
  .levelWrapper {
    margin-right: 8px;
  }
  .authorName {
    color: $gray_6;
  }
  .authorSpecifications {
    margin-left: 8px;
    color: $gray_half_8;
  }
}

.modal-button-wrapper {
  cursor: pointer;
}

.photoWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 414px;
  height: 460px;
  background-color: $gray_0;
}
.photoAbsoluteWrapper {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 111%;
  overflow: hidden;

  > img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
