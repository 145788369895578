@import "~styles/color";
@import "~styles/variables";

.background {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  animation: fade 0.2s;

  @media screen and (max-width: 450px) {
    height: 100%;
  }
}

.center {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 450px) {
    top: 0;
    left: 0;
    transform: unset;
  }

  .wrapper {
    position: relative;
    background-color: $white;
    padding: 24px 22px;
    max-height: 80%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    border-radius: 4px;
    margin: auto 0 auto 0;

    @media screen and (max-width: 450px) {
      padding: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: unset;
      overflow: auto;
    }
  }
}

.isNotFullScreenModalAtMobile {
  padding: 0;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 95px));
  border-radius: 4px !important;
  overflow: hidden;

  .btnWrapper {
    @media screen and (max-width: 450px) {
      position: absolute;
      margin: 0;
      left: 0;
      bottom: 0;
      width: 100%;

      .cancel {
        display: inline-block;
        margin-right: 0;
        width: 50%;
      }

      button {
        border-radius: unset;
      }

      button[type="submit"] {
        background-color: $blue !important;
      }
    }
  }
}

.isCoverPopup {
  height: 100%;
  margin-top: 0;

  .wrapper {
    padding: 0;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// modal chunks
.header {
  margin-bottom: 37px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: $gray_10;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
    padding: 32px 22px;
  }
}

.close {
  position: relative;
  float: right;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: $gray_8;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.container {
  width: 320px;

  @media screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
  }
}

.cover-container {
  height: auto;

  .header {
    padding: 20px;
    margin-bottom: 0;
  }

  .contents {
    padding: 20px;
    font-family: "Noto Sans KR", sans-serif;
  }

  @media screen and (max-width: 450px) {
    height: 100%;

    .close {
      display: block;
      padding: 0;
    }
  }
}

.contents {
  font-family: "Noto Sans KR", sans-serif;

  form {
    @media screen and (max-width: 450px) {
      position: relative;
      padding: 10px 22px 81px;
    }
  }

  p {
    margin-bottom: 14px;
    text-align: left;
    font-size: 14px;
    color: $gray_8;
    line-height: 1.57;

    @media screen and (max-width: 450px) {
      font-size: 15px;
      color: $gray_10;
    }
  }

  input {
    padding: 0 12px;
    width: 100%;
    height: 42px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 4px;

    &::placeholder {
      color: $gray_5;
    }
  }
}

.login {
  width: 100%;
  height: 52px;
  background-color: $darknavy;
  color: $white;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: $gray_3;
  }
}

.btnWrapper {
  text-align: right;
  margin-top: 4px;
  margin-bottom: 24px;

  button {
    border: none;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled,
    &:invalid {
      cursor: not-allowed;
    }

    @media screen and (max-width: 450px) {
      font-size: 16px;
      padding: 16px 0;
      height: 56px;
    }

    &.cancel {
      display: none;
    }

    &.modify {
      height: 32px;
      width: 64.45px;
      vertical-align: bottom;
      background-color: $blue;
      color: $white;

      @media screen and (max-width: 450px) {
        background-color: $darknavy;
        width: 100%;
        height: 56px;
      }
    }

    &.modify02 {
      width: 100%;
      height: 52px;
      font-size: 16px;
      margin-bottom: 15px;
      background-color: $darknavy;
      color: $white;
    }
  }
}

.btnWrapper-line {
  display: flex;
  align-items: center;
  border-top: 1px solid $gray_3;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
  font-size: 14px;
  margin-top: 40px;

  @media screen and (max-width: 450px) {
    position: absolute;
    height: 56px;
    font-size: 16px;
  }

  button {
    flex: 1;
    height: 56px;
    margin: 0;
  }

  .cancel {
    display: none;

    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  .modify {
    color: white;
    background-color: $darknavy;
    border-radius: 4px;
    cursor: pointer;

    @media screen and (max-width: 450px) {
      border-radius: 0;
    }
  }
}

p.find-pwd-desc {
  color: $gray_7;
  text-align: center;
}

button.find-pwd {
  padding: 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: auto;
  height: 32px;
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    background-color: $white;
  }
}

.login__modalFooter {
  font-size: 14px;
  color: $gray_8;
  line-height: 17px;

  @media screen and (max-width: 450px) {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 16px;
  }

  .link--pwreset,
  .link--signup {
    font-size: 14px;
    border: none;
    text-decoration: none;
    color: $gray_8;
    background-color: transparent;
    padding: 0;

    span:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .link--pwreset span {
    padding-right: 17px;
    border-right: 2px solid #ced4da;
  }

  .link--signup span {
    padding-left: 17px;
  }
}

.login__socialWrapper {
  margin-top: 25px;

  div {
    height: 55px;
    margin-bottom: 10px;

    img {
      width: 222px;
    }
  }
  .auth {
    width: 210px;
    height: 32px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 11px;
    line-height: 1.5;
    color: $gray_6;
  }
}

.invalid-txt {
  text-align: left;
  color: $red;
  padding-left: 12px;
  margin: 0 0 10px;
  font-size: 12px;
}

.input-title {
  text-align: left;
  font-size: 12px;
  color: $gray_8;
  line-height: 1.5;
  margin-bottom: 4px;
  height: 18px;
}

.term-container {
  display: flex;
  align-items: flex-start;

  #term {
    flex: 0.05;
    height: 16px;
    padding: 0;
  }

  span {
    flex: 0.95;
    text-align: left;
    color: $gray_8;
    font-size: 14px;
    line-height: 140%;
  }
}
