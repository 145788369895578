@import "~styles/color";
.total-price {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray_3;
  color: $red;
  font-size: 14px;
  padding-top: 16px;
  margin-top: 16px;
}
