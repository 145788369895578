@import "./reset";
@import "./base";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./custom";
@import "./variables";

body {
  overflow: auto;
  font-family: "Noto Sans KR", sans-serif;
}

.App {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  min-width: 1100px;
  min-height: 100%;
  height: 100%;
  width: 1100px;
  padding: 0 20px;

  @media screen and (max-width: 450px) {
    min-width: auto;
    min-height: auto;
    width: auto;
    overflow: hidden;
    padding: 0;

    &.isBanner {
      nav {
        padding-top: 75px;
      }

      main {
        padding-top: $m-nav-block-height * 2 + 155px;
      }
    }
  }
}

main {
  display: block;
  min-height: 100%;
  height: 100%;
  margin-left: 100px;
  padding-bottom: 150px;
  padding-top: $nav-block-height * 2 + 36px;

  @media screen and (max-width: 450px) {
    margin-left: 8px;
    padding-top: $m-nav-block-height * 2 + 80px;
  }
}

// rank style

.rank {
  margin: 6px 0 6px 4px;
  color: #6696ff;
  font-size: 10px;
  text-align: left;
  letter-spacing: 2px;
}

.rank__number {
  padding-right: 2px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 1px;
}
