@import "~styles/color";

.wrapper {
  margin: 80px auto 0 auto;
}

.asset {
  margin-top: 32px;

  width: 120px;
  height: 80px;
}

.notice {
  color: $gray_9;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
  height: 27px;
  font-size: 18px;
}

.advise {
  color: $gray_7;
  text-align: center;

  margin-top: 16px;
  width: 207px;
  height: 44px;
  width: 100%;
  font-size: 14px;
  line-height: 1.57;
}

.request {
  display: inline-block;
  height: 30px;
  border-radius: 4px;
  border: solid 1px $blue;
  background-color: $white;
  margin-top: 26px;
  padding: 0;

  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: $blue;
}
