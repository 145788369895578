@import "~styles/color";

.stores {
  display: flex;
  text-align: left;
}

.brands {
  margin-left: 96px;
}

.title {
  color: $gray_10;

  width: 424px;
  height: 27px;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray_3;

  font-size: 18px;
  font-weight: 500;
}
