@import "~styles/color";
@import "~styles/infoBox";

.order-detail-tab {
  box-sizing: content-box;
  width: 947px;

  .orderDetail__tab {
    margin-bottom: 24px;
    text-align: left;

    &:first-child {
      .orderDetail__txt--head {
        background-color: $white;
        header {
          display: inline-block;
        }

        header.title {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          color: $gray_10;
          font-size: 20px;
          font-weight: bold;
          @media screen and (max-width: 450px) {
            font-weight: normal;
            margin-left: 5px;
          }
        }

        @media screen and (max-width: 450px) {
          left: -12px;
          width: 100%;
          padding-top: 16px;
          padding-bottom: 18px;
          font-size: 18px;
          font-weight: 900;
          color: $gray_9;
          position: relative;
        }
      }
    }

    @media screen and (max-width: 450px) {
      position: relative;
      margin-bottom: 0;
      border-bottom: none;
    }

    .orderDetail__txt--head {
      font-size: 14px;
      text-align: left;
      color: $gray_10;

      @media screen and (max-width: 450px) {
        display: flex;
        align-items: center;
        left: -12px;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 12px;
        font-size: 15px;
        color: $gray_9;
        border-bottom: none;
        position: relative;
        background-color: $gray_1;
      }
    }

    .orderDetail__orderProduct--head {
      text-align: left;

      div {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        color: $gray_8;
        line-height: 50px;

        &:nth-child(2) {
          margin-left: 84px;
          width: 340px;
        }

        &:nth-child(3) {
          width: 240px;
        }

        &:nth-child(4) {
          width: 152px;
        }

        &:nth-child(5) {
          width: 180px;
        }

        &:nth-child(6) {
          width: 124px;
        }
      }
    }

    .orderDetail__summary {
      height: 75px;
      margin-top: 32px;
      border-bottom: 1px solid $gray_3;
      color: $gray_8;

      @media screen and (max-width: 450px) {
        position: relative;
        left: -12px;
        width: calc(100% + 4px);
        height: 100%;
        padding-left: 8px;
        margin-top: 0;
        border-bottom: none;
      }

      .summary__mobile--head {
        @media screen and (max-width: 450px) {
          position: relative;
          left: -8px;
          width: calc(100% - 4px);
          height: 54px;
          line-height: 54px;
          padding-left: 12px;
          border-top: 1px solid $gray_4;
          font-size: 15px;
          text-align: left;
          color: $gray_9;
          background-color: $gray_1;
        }
      }

      .summary__mobile--wrapper {
        @media screen and (max-width: 450px) {
          padding: 20px 12px 6px 4px;
          text-align: left;
        }
      }

      .summary__infoBox {
        display: inline-block;
        margin: 0 30px;
        font-size: 13px;

        &:last-child {
          @media screen and (max-width: 450px) {
            padding-top: 20px;
            border-top: 1px solid $gray_3;
          }

          .infoBox__txt--head {
            @media screen and (max-width: 450px) {
              color: $red;
            }
          }
        }

        @media screen and (max-width: 450px) {
          position: relative;
          width: 100%;
          margin-left: 0;
          margin-bottom: 18px;
          text-align: left;
        }

        .infoBox__txt--head {
          margin-bottom: 17px;

          @media screen and (max-width: 450px) {
            display: inline-block;
            width: auto;
            margin-bottom: 0;
          }
        }

        .infoBox__txt--totalPrice,
        .infoBox__txt--totalDiscount {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.5px;

          @media screen and (max-width: 450px) {
            position: absolute;
            display: inline-block;
            width: auto;
            right: 0;
          }
        }

        .infoBox__txt--totalPayment {
          @extend .infoBox__txt--totalPrice;
          color: $red;
        }

        .infoBox__txt--expectPoint {
          @extend .infoBox__txt--totalPrice;
          color: $blue;
        }

        .totalPayment__bar {
          position: relative;
          top: -10px;
          opacity: 0.5;
          height: 14px;
          z-index: -1;
          background-color: #fffcb3;
        }
      }

      .summary__bar--minus,
      .summary__bar--sum {
        display: inline-block;
        margin-top: 26px;
        font-size: 20px;
        vertical-align: top;
      }
    }
  }
  .head {
    font-size: 16px;
    padding: 32px 0 24px;
  }
  .orderDetail__tab.summary {
    color: $gray_8;
    background-color: $gray_1;
    border-left: 1px solid $gray_3;
    border-right: 1px solid $gray_3;
    border-bottom: 1px solid $gray_3;
    .m-full-box {
      border-bottom: 1px solid $gray_3;
      padding: 0 32px 32px;
    }

    .m-flex {
      display: flex;
      justify-content: space-between;

      .m-box {
        &.left {
          flex: 2;
        }
        &.right {
          flex: 1;
        }
        padding: 0 32px 24px;
      }

      .m-box.right-border {
        border-right: 1px solid $gray_3;
      }
    }
  }

  .cartEntry__wrapper--optionPrice {
    .cartEntry__option {
      .option__txt--name {
        color: $gray_9;
      }
    }
  }

  @media screen and (max-width: 450px) {
    margin-left: 0;
    padding-bottom: 60px;
    padding-top: 176px;
    width: 100%;
    background-color: $gray_1;
    .card-wrapper {
      padding: 16px 10px 0px;
      width: calc(100% - 20px);
      background-color: $gray_1;
    }

    .claimWrapper {
      background-color: $gray_1;
    }

    .orderDetail__tab {
      .orderDetail__orderProduct--list {
        .orderDetail__bar--status {
          margin-left: -12px;
        }
      }
      .head {
        font-size: 16px;
        padding: 40px 0px 24px;
      }
    }

    .orderDetail__tab.summary {
      background-color: $gray_1;
      padding: 0px 10px;
      margin-bottom: 24px;
      border-bottom: none;
      ul {
        font-size: 12px;
      }

      .m-full-box {
        border: none;
        font-size: 12px;
        padding: 0;
      }

      .m-flex {
        display: block;

        .m-box {
          width: 100%;
          padding: 0;
        }

        .m-box.right-border {
          border: none;
        }
      }

      .total-price {
        font-size: 12px;
      }
    }
  }
}
