@import "~styles/color";
.term-container {
  text-align: left;
  margin-bottom: 24px;
  .label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:not(.first) {
      padding-left: 8px;
      margin-bottom: 6px;
    }
  }

  .text {
    font-size: 13px;
    line-height: 1.5;
    color: $gray_8;
    &.all {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      color: $gray_8;
    }
  }
  .link {
    text-decoration: underline;
    color: $gray_8;
  }

  .marketing-description {
    font-size: 12px;
    color: $gray_8;
    line-height: 1.5;
    margin-top: 4px;
  }
}

.input-label {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid $gray_4;
  border-radius: 2px;
  &.checked {
    position: relative;
    border-color: $blue;
    background-color: $blue;

    &::after {
      position: absolute;
      content: "";
      border-bottom: 3px solid white;
      border-right: 3px solid white;
      transform: rotate(45deg);
      height: 10px;
      width: 7px;
      top: 2px;
      left: 5px;
    }
  }

  .checkbox {
    display: none;
  }
}
