@import "~styles/color";
@import "~styles/animate";

$cardMargin: 4px;

.card,
.card-child {
  height: 379px;
  width: 270px;
  margin-bottom: 40px;
}

.thumbnail {
  position: relative;
}

.picture {
  display: flex;

  width: 270px;
  height: 315px;
}

.img {
  background-color: $gray_0;
  color: transparent;

  width: 100%;
  height: 100%;
}

.title {
  color: $gray_9;
  text-align: left;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 12px;
}

.tags {
  color: $gray_6;
  text-align: left;

  height: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  margin-top: 6px;
}

.tag {
  background-color: $gray_1;
  color: $gray_7;

  font-size: 12px;

  height: 20px;
  border-radius: 1px;
  margin-right: 8px;

  padding: 1px 4px;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }
}

.like {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  padding: 0;

  min-width: 53px;
  height: 30px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);

  bottom: 8px;
  right: 8px;

  &.isLiked {
    background-color: rgba(242, 53, 56, 0.9);
    @include animation(0.6s, move);
  }
}

.like-counts {
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  color: $white;
}

@media screen and (max-width: 450px) {
  .card,
  .card-child {
    width: calc(50% - #{$cardMargin});
    height: 280px;
  }

  .card-child {
    width: 100%;
  }

  .picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .img {
    background-color: $gray_0;
    color: transparent;
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 12px;
    font-weight: normal;
    color: $gray_10;
  }

  .tags {
    height: 18px;
    font-size: 12px;
    color: $gray_7;
    overflow-x: auto;
    white-space: nowrap;
  }

  .tag {
    margin-right: 4px;
  }
}
