@import "~styles/color";

.title {
  font-weight: bold;
  text-align: left;
  color: $gray_10;
  height: 27px;
  font-size: 18px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  box-sizing: border-box;
}

.size {
  height: 18px;
  font-size: 12px;
  padding-top: 3px;
  color: $gray_8;
  margin-top: 10px;
  text-align: left;
}

.remove {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 24px;

  .checked {
    text-align: left;
    color: $gray_8;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }
}

.button {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  color: $gray_9;
  background-color: $white;

  padding: 0;
  border-radius: 4px;
  border: solid 1px $gray_5;
  margin-left: 8px;
  width: 39px;
  height: 26px;
  font-size: 12px;
}

.stores {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.store {
  display: flex;
  box-sizing: border-box;
  height: 84px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 424px;

  border-bottom: solid 2px rgba(222, 226, 230, 0.5);

  &:nth-child(2n) {
    margin-left: 96px;
  }
}

.link {
  display: flex;
  flex: 1;
}

.picture {
  margin-left: 16px;
}

.img {
  height: 48px;
  width: 48px;
}

.right {
  margin-left: 16px;
  flex: 1;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 22px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
}

.name {
  color: $gray_9;

  font-size: 15px;
}

.message {
  box-sizing: border-box;
  color: $blue_lightgray;

  font-size: 11px;
  margin-left: 8px;
}

.new-products-cnt {
  color: $red;

  font-size: 11px;
}

.hide {
  display: none;
}

.tags {
  display: flex;
  color: $gray_6;
  align-items: center;
  text-align: left;

  margin-top: 8px;

  height: 18px;
  font-size: 12px;
}

@media screen and (max-width: 450px) {
  .bookmarks-tab {
    margin-left: 0;
    padding-top: 192px;
  }

  .title {
    margin-left: 12px;
    margin-top: 16px;
  }

  .size {
    margin-left: 12px;
  }

  .remove {
    background-color: $gray_1;
    padding: 10px 12px;
    margin: 16px 0;
  }

  .stores {
    flex-direction: column;
  }

  .store {
    flex: 1;

    margin-left: 12px;
    margin-right: 12px;

    width: calc(100% - 24px);

    &:nth-child(2n) {
      margin-left: 12px;
    }
  }

  .link {
    margin-left: 6px;
  }
}
