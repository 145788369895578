@import "~styles/color";
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 200px;
}
.header {
  display: flex;
  padding: 13px 22px 12px 22px;
  font-size: 16px;
  color: $gray_10;
  justify-content: space-between;
  align-items: center;
}

.authorWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0px 10px 0px;
  .author-name {
    font-size: 11px;
    margin-left: 6px;
    color: $gray_7;
  }
  .author-spec {
    font-size: 11px;
    margin-left: 6px;
    color: $gray_8;
  }
}
.reviewWrapper {
  padding: 0px 12px 0px 12px;
}
.reviewSummaryWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.optionWrapper {
  text-align: left;
  font-size: 11px;
  color: $gray_7;
  line-height: 1.55;
  margin-bottom: 6px;
}
.commentContentsWrapper {
  text-align: left;
  white-space: pre-wrap;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: $gray_8;
  margin-bottom: 32px;
}
.reviewFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0px;
  background-color: $white;

  width: calc(100% - 24px);
  height: 17px;
  padding: 2px 12px 12px 12px;
  color: $gray_6;
  font-size: 11px;

  .likeContents {
    display: flex;
    align-items: center;
    &.isLiked {
      color: #d91a1d;
    }
    > * {
      margin-right: 4px;
    }
  }
}

.reviewStarBorder {
  padding: 8px;
  border: 1px solid $gray_3;
}

.photoWrapper {
  position: relative;
  height: 400px;
  background-color: $gray_10;
  .photoNavHeadPositionWrapper {
    margin-top: 12px;
    width: 100%;
    position: absolute;
    left: 0px;
  }
  .photoNavBodyPositionWrapper {
    width: 100%;
    position: absolute;
    bottom: 180px;
    left: 0px;
  }
  .photoContent {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
