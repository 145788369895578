/* autoprefixer grid: autoplace */

@import "~styles/color";

.coordi-tab {
  box-sizing: content-box;
  text-align: left;

  header.title {
    text-align: left;
    color: $gray_9;
    font-size: 20px;

    .desc {
      color: $gray_8;
      font-size: 12px;
      margin-top: 12px;
    }
  }

  .remove {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    padding-bottom: 10px;
    font-size: 12px;

    .btn {
      cursor: pointer;
      text-align: center;
      color: $gray_8;
      border: 1px solid $gray_5;
      border-radius: 3px;
      width: 43px;
      height: 26px;
      margin-left: 8px;
      padding: 0;
    }

    .checked {
      text-align: left;
      color: $gray_8;
      width: 100%;
      margin-top: 10px;
    }
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: auto;

    .card {
      text-align: left;
      margin: 15px auto 15px 0;
      width: 30.5%;

      .checkbox {
        margin-bottom: 8px;
      }
    }

    .card.hidden {
      visibility: hidden;
      height: 0;
    }
  }

  @media screen and (max-width: 450px) {
    margin-left: 0;

    header.title {
      margin-left: 8px;
      padding-top: 16px;
    }

    .remove {
      background-color: $gray_1;
      padding: 10px 12px;
      margin: 16px 0;
    }

    .card-list {
      // grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: auto;

      margin-left: 8px;

      .card {
        width: 48%;
        // margin-right: 8px;

        .head-img {
          height: 196px;
        }

        .title {
          font-size: 0.85rem;
        }

        .tags {
          font-size: 0.75rem;
        }

        .title,
        .tags {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
