@import "~styles/color";

.bottom80 {
  margin-bottom: 80px;

  @media screen and (max-width: 450px) {
    margin-bottom: 40px;
  }
}

.title {
  text-align: left;
  font-size: 20px;

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.sub-title {
  font-size: 18px;
  margin-bottom: 15px;
  color: $gray_9;
}

.count {
  color: $gray_7;
  font-size: 12px;
  margin-left: 6px;
}

.m-go-directly {
  display: none;
}

.m-gender {
  display: none;
}

.account {
  text-align: left;
  padding: 0px 0 5px 0;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .info-key-value {
      display: flex;
    }

    .info-key {
      font-size: 14px;
      font-weight: 500;
      color: $gray_half_8;
      width: 72px;
      margin-right: 30px;
    }

    .info-value {
      font-size: 16px;
      font-weight: 500;
      color: $gray_9;

      &.needAuth {
        color: $gray_7;
      }
    }

    .account-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 32px;
      border: 1px solid $blue;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: $blue;
      padding: 0;
      cursor: pointer;
    }
  }
}

.dashboard {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  margin-bottom: 60px;

  .menu {
    flex: 6;

    ul {
      border-top: 1px solid $gray_3;

      .chevron {
        color: $blue;
        margin-right: 5px;
      }

      .division-chevron {
        margin-right: 16px;
      }

      .link-division-wrapper {
        display: flex;

        .link-division {
          width: 50%;
          text-decoration: none;
          color: $black;
          position: relative;
        }

        .new-icon-qna {
          position: absolute;
          top: 15px;
          left: 65px;
        }

        .new-icon-contacts {
          position: absolute;
          top: 15px;
          left: 100px;
        }

        .division-left {
          border-right: 1px solid $gray_3;
        }
      }

      li {
        display: flex;
        justify-content: space-between;
        padding: 15px 0 13px;
        border-bottom: 1px solid $gray_3;

        .list-key {
          text-align: left;
          font-size: 14px;
          vertical-align: center;
          color: $gray_half_8;
          font-weight: 500;
        }

        .division-right-key {
          margin-left: 16px;
        }

        .list-right {
          display: flex;
          align-items: flex-start;

          .list-value {
            margin-top: 2px;
            margin-right: 5px;
            font-weight: 500;
            width: 100px;
            text-align: right;
          }
        }
      }
    }
  }
}

.order-section {
  margin-bottom: 80px;
}

.cart {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .list {
    background-color: $gray_0;

    .button {
      width: 100%;
      padding: 0;

      .sold-out {
        opacity: 0.4;
      }
    }

    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px;
    }

    li {
      border-bottom: 1px solid $gray_3;
    }

    li.no-border {
      border-bottom: none;
    }

    .image {
      flex: 0.6;
      margin-right: 10px;
      height: 48px;
    }

    .info {
      display: flex;
      flex-direction: column;
      color: $gray_8;
      text-align: left;
      flex: 6.4;
      font-size: 0.857em;
      height: 42px;

      div:first-child {
        flex: 0.9;
        font-weight: 500;
      }

      .red {
        color: $red;
        font-weight: 400;
      }
    }

    .price {
      text-align: right;
      flex: 3;
      font-weight: 500;
      font-size: 0.857em;
    }
  }
}

.pin {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  article {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      width: 15%;
      padding: 0;
    }

    .store-thumbnail {
      display: inline-block;
      vertical-align: top;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-right: 5px;
    }

    .store-name {
      color: $gray_half_8;
      font-size: 0.75em;
    }

    .product-name {
      color: $gray_9;
      margin: 12px 0 6px 0;
      font-size: 0.812em;
      line-height: 160%;
    }

    .discount {
      text-decoration: line-through;
      color: $gray_4;
      font-size: 0.875em;
      line-height: 180%;
    }

    .price {
      color: $gray_9;
      font-size: 0.714em;
      font-size: 0.875em;

      span {
        color: $red;
      }
    }

    .pin-card {
      box-sizing: border-box;
      text-align: left;
      text-decoration: none;
      color: $gray_9;
      width: 180px;

      picture {
        display: flex;
        height: 210px;

        img {
          object-fit: cover;
          height: auto;
          width: 100%;
          margin-bottom: 0.625em;
        }
      }
    }
  }
}

.coordi {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  article {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .coordi-card {
      width: 174px;

      picture {
        display: flex;
        width: 100%;
        height: 203px;
        margin-bottom: 0.625em;

        img {
          object-fit: cover;
          height: auto;
          width: 100%;
        }
      }

      ul {
        text-align: left;
        font-size: 0.857em;
        margin-top: 5px;

        .coordi-title {
          height: 19px;
          box-sizing: border-box;
          padding-top: 3px;
          padding-bottom: 3px;
          font-size: 13px;
          color: $gray_9;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.bookmark {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  article {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .store {
      position: relative;
      width: 32%;
      height: 135px;
      margin-bottom: 30px;

      ul {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 180%;
      }

      .modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }

      .name {
        text-align: center;
        font-size: 1.125em;
      }

      .new-products {
        text-align: center;
        font-size: 0.875em;
      }
    }
  }

  article::after {
    content: "";
    flex-basis: 32%;
  }
}

.pin {
  .sold-out {
    position: relative;

    &::after {
      content: attr(data-msg);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-size: 12px;
      top: 0;
      left: 0;
      width: 100%;
      height: 133px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}

@media screen and (max-width: 450px) {
  * {
    font-size: 14px;
  }

  .member-tab {
    margin-left: 0;
  }

  .header {
    margin-top: 80px;
  }

  .sub-title {
    font-size: 1.14em;
    margin-left: 13px;
  }

  .page {
    visibility: hidden;
  }

  .m-gender {
    display: flex;
    font-size: 0.857em;
    margin: 10px;

    button {
      flex: 1;
      border: 0.5px solid $gray_4;
      height: 32px;
      padding: 5px 0;
      margin: 0;
    }

    button.wo-selected {
      color: $red;
      border: 1px solid;
    }

    button.me-selected {
      color: $blue;
      border: 1px solid;
    }
  }

  .account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 23px 12px 8px;
    padding: 0px;
  }

  .dashboard {
    display: block;
    padding-top: 0px;
    margin-bottom: 52px;

    .menu {
      ul {
        .list-key {
          position: relative;

          .new-icon-qna {
            position: absolute;
            top: 0px;
            left: 65px;

            .badgeNew-icon {
              width: 16px;
              height: 16px;
            }
          }

          .new-icon-contacts {
            position: absolute;
            top: 0px;
            left: 80px;

            .badgeNew-icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        li {
          padding: 18px 2px 18px 13px;

          span:first-child {
            font-size: 1em;
          }

          span:nth-child(2) {
            font-size: 1.142em;
          }

          span.chevron {
            color: $blue;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .order-section {
    margin-bottom: 60px;
  }

  .cart {
    .list {
      border-top: 1px solid $gray_3;

      .image {
        flex: 1.9;
      }
    }
  }

  .pin {
    article {
      flex-wrap: wrap;

      margin-left: 6px;
      margin-right: 6px;

      .pin-card {
        width: 112px;

        picture {
          height: 140px;
        }

        .store-thumbnail {
          vertical-align: bottom;
          width: 10px;
          height: 10px;
        }

        .store-name {
          font-size: 0.642em;
        }

        .product-name {
          line-height: 150%;
          font-size: 0.714em;
        }
      }

      .pin-card:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .coordi {
    article {
      margin-left: 8px;

      .coordi-card {
        width: calc(48% - 4px);
        box-sizing: border-box;
        margin-right: 8px;
        margin-bottom: 25px;

        img {
          height: auto;
        }
      }
    }
  }

  .bookmark {
    article {
      flex-direction: column;

      .store {
        width: 100%;
        margin-bottom: 0px;

        .name {
          font-size: 1.285em;
        }

        .new-products {
          font-size: 1em;
        }
      }
    }
  }

  .account {
    flex-direction: column;
    align-items: flex-start;

    .info {
      width: 100%;
      margin-bottom: 16px;

      .info-key-value {
        display: flex;
      }

      .info-key {
        display: none;
      }

      .info-value {
        font-size: 14px;
        font-weight: 500;
        color: $gray_9;

        &.needAuth::before {
          content: "핸드폰 번호를 ";
        }
      }
    }
  }
}
