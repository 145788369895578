@import "~styles/color";
@import "~styles/variables";

.option-select {
  border: 1px solid $gray_2;
  width: 100%;
  height: 32px;
  font-size: 13px;
  padding-left: 12px;
  margin-bottom: -1px;
  outline: none;
}
.option-select::-ms-expand {
  display: none;
}
