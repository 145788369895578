@import "~styles/color";

.wrapper {
  padding-bottom: 16px;
  border-bottom: 1px solid $gray_2;
}

.question-wrapper {
  padding: 32px 0px 16px;
}

.header {
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-bottom: 20px;

  .lock-icon-wrapper {
    margin-right: 6px;
  }

  .type {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: $gray_9;
  }

  .type-title {
    margin-right: 14px;
  }

  .member {
    display: flex;
    align-items: center;
  }

  .email {
    font-size: 10px;
    color: $gray_7;
    margin-left: 6px;
  }
}

.body {
  text-align: left;
  white-space: pre-wrap;
  font-size: 15px;
  color: $gray_8;
  line-height: 1.5;
  margin-bottom: 32px;
  overflow: hidden;
  word-wrap: break-word;

  &.hidden {
    color: $gray_8;
    opacity: 0.5;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .answer-store {
    cursor: pointer;
    font-size: 15px;
    color: $gray_9;
  }

  .created {
    font-size: 13px;
    color: $gray_6;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding-bottom: 4px;
    border-bottom: 1px solid $gray_2;
  }

  .question-wrapper {
    padding: 28px 16px 16px 16px;
  }

  .answer-wrapper {
    padding: 0px 10px;
  }

  .header {
    margin-bottom: 14px;

    .type-title {
      margin-right: 11px;
    }
  }

  .body {
    font-size: 14px;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 20px;
  }

  .answer-store {
    cursor: pointer;
    font-size: 14px;
    color: $gray_9;
  }

  .created {
    font-size: 11px;
    color: $gray_6;
  }
}
