@import "~styles/color";
@import "~styles/animate";

$cardMargin: 5px;

.card {
  text-align: left;
  background-color: $white;

  font-size: 16px;
  margin-bottom: 80px;
  width: 180px;
  border-radius: 4px 4px 0px 0px;
}

.rank {
  font-size: 10px;
  font-weight: bold;
  color: $blue;
  margin-bottom: 3px;

  &:after {
    content: "위";
  }

  &.hide {
    display: none;
  }
}

.thumbnail {
  position: relative;
  width: 180px;
}

.thumbnail-picture {
  display: block;
  overflow: hidden;

  height: 210px;
  width: 180px;
}

.thumbnail-picture-img {
  object-fit: cover;

  width: 100%;
  height: 100%;
}

.thumbnail-picture-img:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.desc {
  padding: 0;
}

.store-name,
.product-name,
.price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.store-name {
  color: $gray_6;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
}

.product-name {
  color: $gray_8;

  margin-top: 10px;

  font-size: 14px;
  line-height: 20px;
  height: 20px;
}

.price-shipping-speed-wrapper {
  display: flex;
  justify-content: space-between;
}

.price {
  color: $gray_8;
  font-weight: bold;

  margin-top: 10px;

  font-size: 14px;
  min-width: 75px;
  height: 20px;
  line-height: 20px;
}

.shipping-fast,
.shipping-slow {
  box-sizing: border-box;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 11px;
  padding: 2px 4px;
}

.shipping-fast {
  color: $green_light;
  border: 1px solid $green_light;
}

.shipping-slow {
  color: $battleship_gray;
  border: 1px solid $battleship_gray;
}

.review {
  display: flex;
  margin-top: 10px;
  vertical-align: middle;

  .star-icon {
    color: $red_star;
    font-size: 12px;
    box-sizing: border-box;
    padding-top: 1px;
    margin-right: 3px;
  }

  .satisfaction {
    color: $red_star;
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    letter-spacing: -0.5px;
  }

  .total-count {
    color: $gray_7;
    font-size: 14px;
    opacity: 0.8;
  }
}

.review-m {
  display: none;
}

.pin {
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 4px;
  padding: 0;
  height: 40px;
}

.animate {
  @include animation(0.6s, move);
}

.pin-img {
  width: 40px;
  height: 40px;
}

.m-pin {
  display: none;
}

.sold-out {
  position: relative;
  padding: 0;

  &::after {
    content: attr(data-msg);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 225px;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.flex-box {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

@media screen and (max-width: 450px) {
  .flex-box {
    margin-top: 8px;
  }
  .card {
    box-sizing: border-box;
    border: 1px solid $gray_2;
    font-size: 12px;
    width: calc(33% - (#{$cardMargin} + 1px));
    margin-left: $cardMargin;
    margin-bottom: 9px;
    position: relative;
  }

  .card:nth-child(3n),
  .card:last-child {
    margin-right: $cardMargin;
  }

  .rank {
    position: absolute;
    color: $white;
    background-color: rgba(66, 133, 244, 0.6);
    text-align: center;

    top: 0;
    left: 0;

    margin-bottom: 0;
    min-width: 14px;
    font-size: 10px;
    line-height: 1.4;

    z-index: 1;

    &:after {
      content: "";
    }
  }

  .thumbnail {
    position: relative;
  }

  .thumbnail-picture {
    height: 133px;
  }

  .desc {
    padding: 4px;
    padding-top: 0;
  }

  .review {
    display: none;
  }

  .review-m {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $white;
    opacity: 0.85;
    vertical-align: middle;
    padding: 3px 0 3px 4px;
    box-sizing: border-box;

    .star-icon {
      color: $red_star;
      font-size: 8px;
      margin-right: 2px;
    }

    .satisfaction {
      color: $red_star;
      font-size: 10px;
      font-weight: 500;
      margin-right: 2px;
    }

    .total-count {
      color: $gray_7;
      font-size: 10px;
      opacity: 0.8;
    }
  }

  .pin {
    display: none;
  }

  .store-name {
    font-size: 0.75em;
    font-weight: 500;
    height: 13px;
    line-height: 13px;
  }

  .product-name {
    font-size: 0.8333em;
    margin-top: 6px;
    height: 15px;
    line-height: 15px;
  }

  .price {
    font-size: 0.75em;
    margin-top: 4px;
    min-width: 55px;
    height: 13px;
    line-height: 13px;
  }

  .shipping-fast,
  .shipping-slow {
    text-align: center;
    font-size: 0.75em;
    margin-top: 4px;
  }

  .m-pin {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray_6;

    border-top: 1px solid $gray_2;
    width: 100%;
    height: 28px;
    font-size: 0.895em;

    svg {
      width: 8px;
      height: 12px;
      margin-right: 4px;
    }
  }

  .red {
    color: $red;

    svg {
      @include animation(0.6s, move);
    }

    span {
      @include animation(0.6s, move);
    }
  }

  .thumbnail {
    width: 100%;
  }

  .thumbnail-picture {
    width: 100%;
  }

  .sold-out {
    &::after {
      height: 140px;
    }
  }
}
