.wrapper {
  margin-top: 100px;
  text-align: center;
  > * {
    margin-bottom: 24px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.inspection-title {
  font-size: 36px;
}

.description {
  font-size: 18px;
  font-weight: 500;
}

.time {
  margin-bottom: 24px;
  .time-text {
    font-weight: bold;
  }
  @media screen and (max-width: 450px) {
    text-align: center;
    span {
      display: block;
    }
  }
}

.block-title {
  font-size: 32px;
  font-weight: bold;
}
