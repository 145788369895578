@import "~styles/color";

.wrapper {
  max-width: 100%;
  height: 100%;
  text-align: left;
  background-color: $gray_1;
  padding: 24px 16px;
  font-size: 14px;
  word-wrap: break-word;

  strong {
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  p {
    line-height: 1.5;
    font-size: 14px;
    color: $gray_8;
  }
  hr {
    height: 1px;
    background-color: $gray_8;
  }

  table {
    border-collapse: collapse;
    td {
      padding: 4px;
      border: 1px solid $gray_9;
    }
  }
}
