@import "~styles/color";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 401;
  background-color: rgba(0, 0, 0, 0.5);
}

.content-wrapper {
  width: 470px;
  max-height: 669px;
  border-radius: 8px;
  background-color: $white;
}

.content {
  padding: 0px 40px 40px;
}

.title-wrapper {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 40px;
  color: $gray_10;
}

.close-wrapper {
  text-align: right;
  padding: 16px 16px 0px 16px;
  .close-button {
    cursor: pointer;
    padding: 0px;
  }
}

.description-wrapper {
  max-height: 400px;
  text-align: left;

  font-size: 14px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    word-wrap: break-word;
  }
  strong {
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  p {
    line-height: 1.5;
    font-size: 14px;
    color: $gray_8;
  }
  hr {
    height: 1px;
    background-color: $gray_8;
  }

  table {
    border-collapse: collapse;
    td {
      padding: 4px;
      border: 1px solid $gray_9;
    }
  }
}

.footer {
  display: block;
  height: 68px;
}

.never-see {
  background-color: $black;
  color: $white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 8px 8px;
  display: block;
}

@media screen and (max-width: 450px) {
  .content-wrapper {
    width: calc(100% - 16px);
    border-radius: 4px;
  }

  .title-wrapper {
    font-size: 26px;
  }

  .content {
    padding: 0px 32px 32px;
  }

  .title-wrapper {
    margin-bottom: 32px;
  }

  .never-see {
    font-size: 16px;
    border-radius: 0px 0px 4px 4px;
  }

  .description-wrapper {
    max-height: 300px;
  }
}
