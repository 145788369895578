@import "~styles/color";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: $gray_0;
  border-radius: 4px;
  margin-left: 8px;
  width: 87px;
  height: 36px;
}
.text {
  margin-left: 5px;
  font-size: 13px;
  color: $gray_8;
}
