@import "~styles/color";
@import "~styles/variables";

.itemDetail {
  .view {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    min-height: 800px;

    .imageTab {
      width: 55%;

      .imageBtn {
        cursor: zoom-in;
        padding: 0;

        picture {
          display: flex;
          width: 100%;

          .image {
            object-fit: cover;
            transition: all ease 0.5s;
            width: 100%;
            height: 588px;
          }
        }
      }

      .imageBtn.zoom {
        cursor: zoom-out;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;

        picture {
          position: relative;
          display: block;
          height: 588px;

          img {
            object-fit: contain;
            transition: all ease 0.5s;
            transform: scale(1.3);
            width: auto;
            height: 100%;
          }
        }
      }

      .zoom-bg {
        position: fixed;
        background-color: $white;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      .thumbnails {
        text-align: left;

        button {
          cursor: pointer;
          padding: 0;
          margin: 6px 6px 0 0;
        }

        picture {
          display: flex;
          width: 64px;
          height: 64px;

          img {
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    .textTab {
      text-align: left;
      width: 375px;
      line-height: 200%;

      .soldOut {
        width: 366px;
        height: 52px;
        margin-top: 33px;
        background-color: #adb5bd;
        color: $white;
        text-align: center;
        line-height: 52px;

        @media screen and (max-width: 450px) {
          width: 100%;
        }
      }

      .discountText {
        color: $blue;
        font-size: 16px;
        font-weight: 500;

        .free {
          opacity: 0.4;
        }

        .copy {
          cursor: pointer;
          float: right;
          border: 1px solid $gray_4;
          color: $gray_8;
          text-align: center;
          width: 76px;
          font-size: 14px;
          font-weight: 400;
          border-radius: 2px;
        }
      }

      .title {
        display: block;
        color: $gray_8;
        font-weight: 500;
        width: 367px;
        line-height: 36px;
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 24px;
        letter-spacing: 0.2px;
      }

      .priceTab {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
        height: 46px;
        font-size: 21px;

        .price {
          color: $red;
          margin-right: 6px;
        }

        .prevPrice {
          color: $gray_4;
          text-decoration: line-through;
        }

        .rating {
          display: flex;
          flex-direction: column;
          text-align: right;
        }

        .starCount {
          font-size: 18px;
          color: #f74a56;
          margin-bottom: 5px;
          line-height: 1;
        }

        .rateCountText {
          font-size: 12px;
          font-weight: 500;
          color: $gray_half_8;
          line-height: 1;
        }
      }

      .methods-descripton {
        position: relative;
        font-size: 14px;
        padding: 15px 0;
        line-height: 1.8;
        border-top: 1px solid $gray_3;
      }

      .hr {
        height: 1px;
        margin: 0;
        border: none;
        background-color: $gray_3;
      }

      .count-multiple {
        margin: 7px 0 17px;

        .text-optionChoice {
          margin: 6px 0;
          font-size: 14px;
          color: $gray_8;
        }
      }

      .total {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 32px;

        .totalText {
          line-height: 20px;
          margin-right: 25px;
          color: $gray_10;
          font-size: 12px;
        }

        .totalPrice {
          color: $red;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .pin {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $gray_6;
        background-color: $white;
        box-sizing: border-box;
        padding: 0;
        width: 95px;
        height: 48px;
        border: solid 1px $gray_5;
        border-radius: 4px;
        font-size: 15px;

        &.isPin {
          border: 1px solid $red;
          color: $white;
          background-color: $red;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 18px;
          margin-right: 3px;
        }
      }

      .cart {
        color: $white;
        background-color: $black;
        cursor: pointer;
        padding: 0;
        width: 104px;
        height: 48px;
        border-radius: 4px;
        font-size: 14px;
      }

      .purchase {
        color: $white;
        background-color: $black;
        cursor: pointer;
        padding: 0;
        width: 156px;
        height: 48px;
        border-radius: 4px;
        font-size: 14px;
      }
    }
  }
}

.details {
  display: inline-block;
  height: auto;
  width: 820px;
  padding-top: 50px;

  img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 450px) {
    display: none;
    width: 100%;
    min-height: unset;
    margin-top: 0;

    &.isSelected {
      display: block;
      height: 720px;
    }
  }
}

.infoTab {
  margin-top: 20px;
  padding-left: 52px;
  font-size: 12px;
  text-align: left;
}

.hr {
  height: 1px;
  margin: 0;
  border: none;
  background-color: $gray_3;
}

.desktopTab-wrapper {
  display: flex;
  justify-content: space-between;
  height: 64px;
  border-bottom: solid 1px $gray_3;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .selectedTab {
    border-bottom: solid 3px $blue;
    color: $blue;
    font-weight: bold;
  }
}

.desktopReview-wrapper {
  margin: 40px 0px 40px 0px;

  .head-summary {
    display: flex;
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.review-sort {
  display: flex;
  color: $gray_6;

  .selected {
    font-weight: 500;
    color: $gray_10;
  }

  div {
    margin-left: 8px;
    cursor: pointer;
  }
}

.photo-reviews-img-wrapper {
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: 16px;
  }
}

.photo-reviews {
  display: flex;

  .dark-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 37, 41, 0.7);
    font-size: 15px;
    font-weight: bold;
    color: $white;
    cursor: pointer;
  }
}

.review-summary-contents-body {
  display: flex;
  border: 1px solid $gray_3;

  .review-averageSatisfaction {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 140px;
    padding: 18px 10px 18px 10px;
    border-right: 1px solid $gray_3;
  }

  .averageSatisfaction-count {
    font-size: 24px;
    color: #f74a56;
    font-weight: bold;
  }

  &.orderProductNone {
    border: none;

    .review-averageSatisfaction {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-right: none;
      padding: 0;
    }

    .averageSatisfaction-count {
      font-size: 20px;
      margin-left: 8px;
    }
  }
}

.review-summary-graph {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.averageSatisfaction-title {
  font-size: 15px;
  font-weight: bold;
  color: $gray_9;
}

.empty-review-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
  background-color: $gray_0;
  color: $gray_7;
  font-size: 16px;
}

.photoReviewList {
  margin-bottom: 80px;
  text-align: left;
}

.reviewTitleType {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: $gray_9;
  margin-bottom: 40px;

  .reviewTotalCount {
    font-weight: normal;
    color: $gray_7;
  }
}

.satisfactionWrapper {
  padding-bottom: 4px;
}

.empty-review-wrapper {
  text-align: left;

  .empty-review-title {
    display: inline-block;
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: bold;
    color: $gray_9;
  }
}

.reviewSummaryWrapper,
.reviewSection {
  margin-bottom: 80px;
}

.desktopQna-wrapper {
  .qna-title {
    font-size: 20px;
    font-weight: bold;
    color: $gray_9;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .qna-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    border-bottom: 1px solid $gray_1;

    .qna-hidden-label {
      display: flex;
      align-items: center;
      cursor: pointer;

      .qna-checkbox {
        width: 20px;
        height: 20px;
        margin: 0px;
      }

      .qna-checkbox-title {
        font-size: 16px;
        font-weight: 500;
        color: $gray_8;
        margin-left: 10px;
      }
    }
  }

  .qna-list-wrapper {
    margin-bottom: 24px;
  }

  .qna-button {
    cursor: pointer;
    width: 148px;
    height: 40px;
    border-radius: 4px;
    background-color: $blue;
    color: $white;
    font-size: 14px;
    font-weight: bold;
  }
}

.qna-list-no-exist {
  margin-top: 40px;
}
