@import "~styles/color";

.apply-coupons {
  text-align: left;
  width: 592px;
  font-size: 14px;

  .coupon-info {
    display: flex;
    align-items: flex-start;
    background-color: $gray_1;
    margin: -20px -20px 20px;
    padding: 10px 25px;

    .coupon-image {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
      }
    }

    ul {
      padding-left: 15px;

      .name {
        font-weight: 500;
      }

      .desc {
        color: $gray_half_8;
        line-height: 130%;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }

  .coupon-list {
    overflow-y: scroll;
    border-bottom: 1px solid $gray_3;
    max-height: 360px;

    .title {
      font-weight: 500;
    }

    ul {
      margin: 30px 0;

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;

        .check-product {
          margin-right: 10px;
        }

        .name {
          margin-right: 6px;
        }

        .price {
          color: $gray_7;
          margin-right: 6px;
        }

        &:not(.trigger)::after {
          content: attr(data-msg);
          color: $red;
          flex-basis: 100%;
          font-size: 12px;
          margin: 7px 0 0 28px;
        }
      }

      li.option {
        align-items: start;
      }

      li.trigger {
        margin-top: 7px;

        span {
          color: $gray_7;
          background-color: $gray_1;
          font-size: 12px;
          margin-left: 27px;
          padding: 0 4px;
          height: 19px;
          line-height: 19px;
          border-radius: 2px;
        }
      }
    }
  }

  .price-list {
    padding: 20px 0 10px;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .selected,
    .blue {
      font-weight: 500;
    }

    .blue {
      color: $blue;
    }
  }

  .apply-btn {
    cursor: pointer;
    color: white;
    background-color: black;
    width: 100%;
    height: 48px;
    font-size: 18px;
    border-radius: 4px;
  }

  @media screen and (max-width: 450px) {
    width: 100%;

    .coupon-list {
      max-height: auto;
    }
  }
}

button.unusable {
  opacity: 0.3;
}
