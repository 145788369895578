@import "~styles/color";
.row {
  display: flex;
  justify-content: space-between;
  .row-key {
    font-size: 16px;
    color: $gray_7;
    &.black {
      color: $gray_10;
      font-weight: 500;
    }
  }
  .row-value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      color: $red;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .total-count {
      color: $red;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 450px) {
  .row {
    justify-content: flex-start;
    .row-key {
      flex: 1;
      font-size: 14px;
    }
    .row-value {
      flex: 2;
      flex-direction: row;
      .price {
        margin-bottom: 0px;
        font-size: 14px;
      }
      .total-count {
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }
}
