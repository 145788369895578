@import "~styles/color";

.page-title {
  font-weight: bold;
  text-align: left;
  color: $gray_10;

  font-size: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 450px) {
  .page-title {
    margin-top: 24px;
    margin-left: 12px;
    margin-bottom: 0;
  }
}
