@import "~styles/color";
.wrapper {
  text-align: left;
}

.title-text {
  font-size: 18px;
  font-weight: bold;
  color: $gray_10;
  margin-bottom: 20px;
}

.select-menu-wrapper {
  display: flex;
  margin-bottom: 16px;
}

.select-menu-button {
  position: relative;
  width: 100%;
  height: 64px;
  color: $gray_5;
  cursor: pointer;
  border-bottom: solid 1px $gray_3;
  &.isSelect {
    color: $blue;
    font-weight: bold;
    &::after {
      position: absolute;
      content: " ";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 4px solid $blue;
    }
  }
}

.body {
  margin-bottom: 48px;
}

.footer {
  text-align: center;
}

.review-content-wrapper {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-content {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin-top: 18px;
  text-align: center;
  color: #999999;
}
