@import "~styles/color";

.search {
  display: flex;
  align-items: center;

  padding-bottom: 16px;
  border-bottom: 1px solid $gray_10;
}

.tag {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $blue;

  font-size: 12px;
  height: 36px;
  line-height: 36px;
  padding: 0 8px;

  border: solid 0.5px $gray_4;
  margin-right: 6px;
  border-radius: 4px;
}

.delete {
  color: $gray_8;
  display: inline-block;
  margin-right: 4px;
  transform: rotate(45deg);
  font-size: 20px;
}

.tag-name {
  display: inline-block;
}

.input {
  flex: 1;
  height: 36px;
  font-size: 22px;
  line-height: 36px;
  padding: 0;
  border: 0;
  padding-right: 24px;
  padding-left: 6px;

  &::placeholder {
    color: $gray_5;
  }
}

.submit {
  color: $white;
  background-color: $black;
  cursor: pointer;

  width: 70px;
  height: 36px;

  padding: 0;
}

.table {
  display: flex;
  box-sizing: border-box;
  border-top: solid 1px $gray_3;
  border-left: solid 1px $gray_3;
  border-right: solid 1px $gray_3;

  margin-top: 16px;
  margin-bottom: 24px;
}

.headers {
  width: 88px;
}

.keywords-wrapper {
  width: 100%;
}

.keywords {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: auto;

  padding-left: 18px;
  height: 42px;

  border-bottom: 1px solid $gray_3;
}

.head {
  color: $gray_9;
  text-align: left;
  box-sizing: border-box;

  font-size: 12px;
  font-weight: 500;
  height: 42px;
  width: 88px;
  background-color: $gray_1;
  padding: 15px 0 15px 16px;
  border-bottom: 1px solid $gray_3;
}

.body {
  color: $gray_8;
  cursor: pointer;

  margin-right: 8px;
  font-size: 12px;
  padding: 9px 8px;
}

.active {
  opacity: 0.95;
  color: $white;
  background-color: $gray_4;
  border-radius: 4px;
}

.coordi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 24px;

  .hidden-card {
    width: 270px;
  }
}

.main {
  min-height: 3500px;
}

@media screen and (max-width: 450px) {
  .main {
    background-color: $white;
    margin-left: 0;
  }

  .search {
    margin: 24px 8px 0 8px;
  }

  .input::placeholder {
    font-size: 16px;
  }

  .coordi {
    margin: 40px 8px 0 8px;
  }

  .table {
    margin: 16px 8px 24px 8px;
  }

  .keywords {
    height: 42px;
    padding-left: 10px;
    width: calc(100% - 88px);
  }

  .body {
    white-space: nowrap;
  }

  .tag {
    white-space: nowrap;
    border-radius: 4px;
  }

  .input {
    font-size: 16px;
    padding-right: 12px;
    padding-left: 0;
  }

  .submit {
    position: absolute;
    right: 8px;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
}
