@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($duration, $name) {
  animation-duration: $duration;
  animation-name: $name;
}

@include keyframes(move) {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(0.8);
  }
  45% {
    transform: scale(0.9);
  }
  55% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes unFold {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}
