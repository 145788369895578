@import "~styles/color";
@import "~styles/variables";

.expected-point-container {
  margin-bottom: 8px;
  position: relative;
  text-align: left;

  span {
    display: inline-block;
  }

  .method-txt {
    width: 12%;
    color: $gray_7;
  }

  .question {
    display: inline-block;
    vertical-align: text-top;
    height: 20px;
    margin-left: 5px;
    padding: 0;
  }
}
