@import "~styles/color";
.wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid $gray_4;
  & + & {
    margin-left: 40px;
  }
}

.title {
  font-size: 12px;
  color: $gray_8;
  height: 18px;
}

.input {
  border: none;
  font-size: 16px;
  color: $gray_10;
  padding: 0px;
  &::placeholder {
    color: $gray_7;
  }
}

.unit {
  font-size: 16px;
  color: $gray_7;
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 90%;
    & + & {
      margin-left: 0px;
    }
  }

  .input {
    width: 48%;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
