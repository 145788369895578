@import "~styles/color";

.search-input {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  background-color: #f1f3f5;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  &::placeholder {
    color: $gray_5;
  }
}
.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.icon-wrapper {
  position: absolute;
  right: 12px;
  top: 8px;
}

.icon-button {
  padding: 0px;
  cursor: pointer;
  & + & {
    margin-left: 16px;
  }
}

@media screen and (max-width: 450px) {
  .input-wrapper {
    width: calc(100% - 16px);
    padding: 12px 8px;
    margin-bottom: 0px;
  }
  .icon-wrapper {
    top: 20px;
  }
}
