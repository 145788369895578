@import "~styles/color";

.wrapper {
  display: block;
  background-color: $gray_1;
  border-radius: 4px;
  padding: 26px 16px;
  text-align: left;

  &.inVisible {
    display: none;
  }

  .store {
    font-size: 15px;
    font-weight: 500;
    color: $blue;
    margin-bottom: 21px;
  }

  .contents {
    white-space: pre-wrap;
    font-size: 15px;
    line-height: 1.5;
    color: $gray_10;
    margin-bottom: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }

  .updated {
    font-size: 13px;
    line-height: 1.5;
    color: $gray_7;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 16px 10px;
  }

  .store {
    font-size: 14px;
  }

  .contents {
    font-size: 14px;
    font-family: "Noto Sans KR", sans-serif;
  }

  .updated {
    font-size: 12px;
  }
}
