@import "~styles/color";
@import "~styles/variables";

.count-single {
  display: flex;
  justify-content: space-between;
  height: 26px;
  margin-top: 6px;

  .counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;

    .number {
      color: $gray_10;
      font-size: 12px;
      margin: 0 15px;
    }

    .count-btn {
      cursor: pointer;
      height: inherit;
      padding: 0;
    }
  }

  .price {
    text-align: right;
    flex: 1;
  }
}

@media screen and (max-width: 450px) {
  .count-single {
    width: 100%;
    height: 36px;
    border: 1px solid $gray_3;
    background-color: $white;
    padding: 0 6px;
    margin: 0;
  }
}
