@import "~styles/color";

.store {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  height: 72px;
  &.mobile {
    justify-content: space-between;
  }
  .leftWrapper {
    display: flex;
  }
}

.link {
  width: 42px;
  height: 42px;
  border-radius: 2px;

  .img {
    height: 100%;
    width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-content: center;

  margin-left: 8px;
}

.name-and-bookmark {
  display: flex;
  align-items: center;
}

.name {
  height: 22px;
  line-height: 22px;
  font-size: 15px;
  color: $gray_9;
}

.tags {
  height: 18px;
  font-size: 12px;
  color: $gray_6;
  text-align: left;
  margin-top: 8px;
}

@media screen and (max-width: 450px) {
  .store {
    margin-left: 12px;
    margin-right: 12px;
  }
}
