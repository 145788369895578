@import "~styles/color";
.category-button-wrapper {
  width: 100%;
  text-align: left;
}

.category-button {
  width: 95px;
  height: 52px;
  font-size: 13px;
  font-weight: 500;
  color: $gray_8;
  border: 1px solid $gray_4;
  background-color: $white;
  padding: 0px;
  margin: -0.5px;
  position: relative;
  cursor: pointer;
  &.textSelect {
    font-weight: bold;
    color: $blue;
  }
}

.isSelect {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 98.5%;
  height: 96%;
  color: $blue;
  font-weight: bold;
  border: 1px solid $blue;
  margin: 0px;

  z-index: 40;
}

@media screen and (max-width: 450px) {
  .category-button-wrapper {
    padding: 8px 0px 0px;
    width: 100%;
    border-bottom: 1px solid $gray_4;
  }

  .category-button {
    width: calc(25% + 1px);
    font-size: 13px;
  }
}
