@import "~styles/color";
.card-wrapper {
  background-color: $white;
  width: 100%;
  border-radius: 4px;
  & + & {
    margin-top: 16px;
  }
}

.card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 15px;
  border-bottom: 2px solid $gray_1;

  .title {
    color: $gray_8;
    font-weight: 500;
    font-size: 14px;
  }

  .more {
    display: flex;
    align-items: center;
    color: $blue;
    .order-detail-text {
      font-size: 13px;
    }
  }
}

.card-body {
  padding: 20px 15px;
  text-align: left;
  border-bottom: 2px solid $gray_1;
  .order-status {
    display: flex;
    align-items: center;
    color: $gray_9;
    .status-text {
      font-size: 15px;
    }
    .ship-tracking {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 24px;
      color: $blue;
      border-radius: 4px;
      border: 1px solid $blue;
      margin-left: 8px;
      font-size: 11px;
    }
  }
  .action-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
  }
}

.product-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  .product {
    display: flex;
    text-decoration: none;
    flex: 2;
    color: $gray_10;
    .image {
      width: 46px;
      height: 44.5px;
      margin-right: 10px;
    }
  }
  .product-description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .product-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;
  }
  .product-name {
    text-align: left;
    overflow: hidden;
    font-size: 12px;
    height: 12px;
    width: 55%;
  }
  .order-options {
    overflow: hidden;
    color: $gray_7;
    font-size: 12px;
    height: 12px;
  }

  .product-pay {
    text-align: right;
    width: 45%;
    margin-top: -3px;
    .pay-text {
      font-size: 12px;
    }
  }
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  .button-wrapper {
    display: flex;
  }
  .store-name {
    font-size: 13px;
    color: $gray_8;
  }
}

.blue-text {
  color: $blue;
}

.gray {
  color: $gray_7;
}

.cancle-line {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.text-margin {
  margin-left: 9px;
}

.pay_cancel {
  color: $gray_6;
}

.pay_wait,
.ship_ing,
.re_ship {
  color: $blue;
}

.claim {
  color: $red;
}
