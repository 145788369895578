@import "~styles/color";

.like-button {
  color: $gray_5;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px $gray_4;
  padding: 10px 16px;

  font-size: 12px;

  .thumb {
    margin-right: 8px;
  }
}

.like-button.active {
  background-color: rgb(242, 53, 56);
  color: $white;
  border: solid 1px $white;
}

@media screen and (max-width: 450px) {
  .like-button {
    flex: 0.95;
  }
}
