@import "~styles/color";
.button {
  width: 120px;
  height: 46px;
  font-size: 15px;
  border-radius: 6px;
  background-color: $white;
  border: 1px solid $gray_3;
  color: #98a0a8;
  cursor: pointer;
  &.isSelect {
    border: none;
    background-color: $blue;
    color: $white;
  }
}
