@import "~styles/color";

.pick-up-address {
  font-size: 14px;

  li {
    display: flex;
    height: 42px;
    margin-bottom: 20px;

    input {
      border-style: unset;
      border-bottom: 1px solid $gray_3;
      flex: 1;
      font-size: 1em;
    }
  }

  button.find {
    cursor: pointer;
    border: 1px solid;
    color: $blue;
    border-radius: 4px;
    padding: 12px 15px;
    margin-left: 15px;
  }
}

.title {
  display: flex;
  align-items: center;
}

.actions {
  font-size: 14px;
  margin-top: 48px;

  button {
    cursor: pointer;
    text-align: center;
    padding: 0;
    border-radius: 4px;
    width: 136px;
    height: 40px;
  }

  button.cancel {
    border: solid 1px $gray_7;
    background-color: $white;
    color: $gray_8;
  }

  button.submit {
    background-color: $darknavy;
    color: $white;
    margin-left: 20px;
  }
}

@media screen and (max-width: 450px) {
  .pick-up-address {
    padding: 0 12px;
  }
}
