@import "~styles/color";

.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
}

.card-container {
  padding: 0px 16px 21px 16px;
}

.qna-wrapper {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 28px;
  border-bottom: 1px solid $gray_2;
}

.qna-typeOf {
  text-align: left;
  padding-bottom: 16px;
}

.qna-content-wrapper {
  background-color: $gray_0;
  border-radius: 4px;
  display: flex;
  margin-bottom: 16px;
}

.qna-product-image-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 8px;
}

.qna-product-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.qna-product-name {
  padding: 6px 4px;
  font-size: 15px;
  font-weight: 400;
  color: $gray_10;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.content-wrapper {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  color: $gray_8;
  width: 100%;
  word-break: break-all;
  word-wrap: break-word;
  line-height: normal;
  margin: 16px 0 24px 0;
}

.qna-footer {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.qna-answer {
  font-size: 15px;
  font-weight: normal;
  color: $gray_9;
  cursor: pointer;
}

.qna-createdAt {
  font-size: 13px;
  font-weight: normal;
  color: $gray_7;
}

.qna-answer-wrapper {
  background-color: $gray_1;
  text-align: left;
  padding: 26px 16px;
  border-radius: 4px;
  margin-bottom: 17px;
}

.qna-answer-store {
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

.qna-answer-content {
  word-break: break-all;
  word-wrap: break-word;
  line-height: normal;
  width: 100%;
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 1.57;
}

.qna-answer-updateAt {
  font-size: 12px;
  font-weight: normal;
  color: $gray_7;
}

.no-list {
  margin-top: 30px;
}

@media screen and (max-width: 450px) {
  .qna-tab {
    margin: 0;
    padding-bottom: 100px;
    background: $gray_1;
  }

  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $gray_1;
  }

  .title {
    padding-top: 20px;
    text-align: left;
    padding-left: 12px;
    padding-bottom: 16px;
    background: $white;
    display: flex;
    margin-bottom: 0;
  }

  .close {
    margin-right: 8px;
    padding-top: 2px;
  }

  .title-text {
    padding-top: 0px;
    font-size: 18px;
    font-weight: normal;
    color: $gray_10;
  }

  .card-container {
    background: $white;
    padding: 0;
  }

  .qna-wrapper {
    padding: 0 16px;
    border-bottom: 1px solid $gray_2;
  }

  .qna-typeOf {
    text-align: left;
    padding-top: 28px;
    padding-bottom: 16px;
    font-size: 15px;
    font-weight: 500;
    color: $gray_9;
  }

  .qna-content-wrapper {
    width: 100%;
    height: 30px;
    background-color: $gray_0;
    padding: 3px 8px 3px 3px;
    border-radius: 4px;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 16px;
  }

  .qna-product-image-wrapper {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    margin-right: 8px;
  }

  .qna-product-image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .qna-product-name {
    padding: 5px 0;
    font-size: 13px;
    color: $gray_10;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
  }

  .content-wrapper {
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    color: $gray_8;
    width: 100%;
    word-break: break-all;
    word-wrap: break-word;
    line-height: normal;
    margin-bottom: 20px;
  }

  .qna-footer {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .qna-answer {
    font-size: 14px;
    font-weight: normal;
    color: $gray_9;
    cursor: pointer;
  }

  .qna-createdAt {
    font-size: 11px;
    font-weight: normal;
    color: $gray_7;
  }

  .qna-answer-wrapper {
    background-color: $gray_1;
    text-align: left;
    padding: 16px 10px;
    border-radius: 4px;
    margin-bottom: 17px;
  }

  .qna-answer-store {
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .qna-answer-content {
    word-break: break-all;
    word-wrap: break-word;
    line-height: normal;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 400;
    line-height: 1.57;
  }

  .qna-answer-updateAt {
    font-size: 12px;
    font-weight: normal;
    color: $gray_7;
  }

  .pagination-wrapper {
    margin-top: 20px;
  }

  .no-list {
    margin-top: 30px;
    background-color: $gray_1;
  }
}
