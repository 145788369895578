@import "~styles/color";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .button {
    cursor: pointer;
    color: $gray_4;
    padding: 0;
  }

  .button.page {
    text-align: center;
    color: $gray_5;

    width: 28px;
    height: 28px;
    font-size: 14px;
    margin: 0 8px;
    margin-bottom: 2px;
    &:hover:not(.current) {
      color: $gray_8;
      border: 1px solid $gray_1;
    }
  }

  .button.left,
  .button.right {
    color: $blue;
  }

  .button.current {
    color: $blue;
    font-weight: bold;

    border: solid 1px $blue;
  }
}

.hide {
  display: none;
}

.more {
  background-color: $gray_3;

  margin-top: 12px;
  width: 100%;
  height: 44px;
}
