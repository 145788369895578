.image-wrapper {
  position: relative;
  width: 64px;
  height: 64px;
  display: inline-block;
  margin-left: 20px;
}

.image {
  width: 100%;
  height: 100%;
}

.xmark-wrapper {
  position: absolute;
  padding: 0px;
  cursor: pointer;
  top: -10px;
  right: -10px;
}
