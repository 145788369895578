@import "~styles/color";
.wrapper {
  display: flex;
  align-items: center;
  height: 18px;
  cursor: pointer;
}
.text {
  font-size: 13px;
  color: $gray_8;
  margin-left: 5px;
}

@media (max-width: 450px) {
  .text {
    font-size: 14px;
  }
}
