@import "~styles/color";
.info-head {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: $gray_1;
  border-bottom: 1px solid $gray_3;
  cursor: pointer;
  .info-title {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: $gray_11;
  }
}
