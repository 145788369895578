@import "~styles/color";
.wrapper {
  width: 80px;
  height: 32px;
  font-size: 13px;
  color: $gray_8;
  cursor: pointer;
  border: 1px solid #b3b6bb;
  &.highlight {
    color: $blue;
    border: 1px solid $blue;
  }
  & + & {
    margin-top: 8px;
  }
}

@media (max-width: 450px) {
  .wrapper {
    width: 30%;
    height: 41px;
    border-radius: 4px;
    font-size: 15px;
    border: 1px solid $gray_1;
    color: $gray_9;

    & + & {
      margin-top: 0px;
    }
  }
}
