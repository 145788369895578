@import "../../styles/color";

.gray-row-outline-box__container {
  @media screen and (max-width: 450px) {
    background-color: $gray_1;
    padding: 8px;
  }
}

.gray-row-outline-box__outline {
  border: solid 1px $gray_4;
  box-sizing: border-box;
  height: 48px;
  width: 100%;

  @media screen and (max-width: 450px) {
    border: solid 1px $gray_4;
    height: 99px;
  }
}

.gray-row-outline-box__black-text {
  float: left;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 24px;
  letter-spacing: normal;
  line-height: 1.5;
  margin-left: 20px;
  margin-top: 12px;

  @media screen and (max-width: 450px) {
    display: inline-block;
    float: none;
    font-size: 14px;
    line-height: 1.71;
    margin-left: 6px;
    margin-left: 6px;
    text-align: left;
    width: 100%;
  }
}

.gray-row-outline-box__gray-text {
  color: $gray_7;
  float: right;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 24px;
  letter-spacing: normal;
  line-height: 2;
  margin-right: 16px;
  margin-top: 12px;
  text-align: right;

  @media screen and (max-width: 450px) {
    color: $gray_7;
    display: inline-block;
    float: none;
    height: 44px;
    line-height: 1.83;
    margin-left: 6px;
    text-align: left;
    width: 100%;
  }
}
