.banner {
  &.hide {
    display: none;
  }

  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 0px;
  position: fixed;
  left: 0px;
  top: 0px;
  padding: 0;
  z-index: 9998;
  height: 75px;
  width: 100%;
  background-color: white;

  .close {
    width: 33px;
    height: 80px;
    text-align: center;
    padding: 0 6px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
  }

  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 15px;

    .title {
      margin: 0 !important;
      font-size: 13.5px;
      color: rgba(45, 45, 60, 1);
      font-weight: 500;
    }

    .desc {
      margin: 0 !important;
      letter-spacing: -0.5px;
      color: rgba(45, 45, 60, 0.5);
      line-height: 1.3em;
      font-size: 12px;
      padding-top: 5px;
    }
  }

  #app_banner_btn {
    background-color: #0000ff;
    padding: 5px 10px;
    margin-right: 15px;
    font-size: 12px;
    color: #ffffff;
    font-weight: 500;
    border: 1px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    padding: 0 10px;
    height: 33px;
    line-height: 33px;
  }
}
