@import "~styles/color";

.condition {
  display: flex;
  height: 60px;
  line-height: 60px;
  font-size: 14px;

  &-display {
    display: none;

    &.visible {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &-text {
    color: $blue;
    font-weight: bold;
  }

  &-reset {
    cursor: pointer;
    background-color: $white;

    border: solid 0.5px $gray_7;
    height: 24px;
    line-height: 17px;
    border-radius: 22px;
    padding: 2px 7px 3px 5px;
  }
  &-reset:hover {
    color: $blue;
    border: solid 0.5px $blue;
    svg {
      path {
        fill: $blue;
      }
    }
  }

  .seperator {
    color: $gray_7;
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .condition {
    margin-left: 8px;
    height: 40px;
    font-size: 12px;
  }
}
