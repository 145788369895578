@import "~styles/color";
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 400;
}
.wrapper {
  background-color: white;
  width: 470px;
  height: 646px;

  .image-button {
    padding: 0px;
    cursor: pointer;
  }
  .image {
    width: 470px;
    height: 580px;
  }
}
.event-wrapper {
  position: relative;

  .header-wrapper {
    width: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
    text-align: right;
    z-index: 999;
    .close-wrapper {
      padding: 20px 16px 0px 0px;
      cursor: pointer;
    }
  }
}

.footer {
  display: flex;
  width: 100%;
  height: 50px;
}

.button {
  width: 50%;
  & + & {
    border-left: 1px solid $gray_2;
  }
}

.today-no-see {
  width: 100%;
  height: 68px;
  background-color: $gray_11;
  color: $white;
  margin-top: -2px;
  cursor: pointer;
}

.popup-prev-wrapper {
  position: absolute;
  z-index: 999;
  top: 45%;
  padding: 16px;
  cursor: pointer;
}

.popup-next-wrapper {
  position: absolute;
  right: 0px;
  top: 45%;
  padding: 16px;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .wrapper {
    background-color: white;
    width: 324px;
    height: 458px;

    .image {
      width: 324px;
      height: 400px;
    }
  }
  .today-no-see {
    height: 60px;
  }
}
