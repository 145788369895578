@import "~styles/color";

.logout {
  width: 100%;
  margin-top: 14px;
  margin-bottom: 24px;

  &__btn {
    display: inline-block;
    width: 188px;
    height: 40px;
    line-height: 40px;
    border: 1px solid $gray_7;
    text-align: center;
    font-size: 14px;
    color: $gray_8;
    box-sizing: border-content;
    padding: 0;
  }
}
@media screen and (max-width: 450px) {
  .logout {
    margin: 5px 0 20px 0;
  }
}
