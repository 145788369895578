@import "~styles/color";
@import "~styles/variables";

.shipping-container {
  margin-bottom: 8px;
  position: relative;
  text-align: left;

  span {
    display: inline-block;
  }
  .method-txt {
    width: 12%;
    color: $gray_7;
  }
  .shipping-fast,
  .shipping-slow {
    font-weight: bold;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
    margin-right: 6px;
    padding: 0 5px;
  }

  .shipping-fast {
    color: $white;
    background: $vibrant_green;
  }

  .shipping-slow {
    color: $gray_10;
    background: $gray_5;
  }

  .shipping-option-txt {
    color: $gray_6;
  }
}
