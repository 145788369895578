@import "~styles/color";

.order-tab {
  padding-left: 10px;
  box-sizing: content-box;

  .wrapper-head {
    margin-bottom: 40px;
    text-align: left;

    button {
      padding: 0;
    }

    .head {
      color: $gray_9;
      font-size: 20px;
      letter-spacing: 0.5px;
    }

    .back {
      display: none;
    }
  }

  .list-head {
    text-align: left;
    padding-bottom: 24px;
  }

  .list-contents {
    margin-bottom: 64px;

    .list {
      background-color: $gray_0;
      padding-bottom: 20px;
      font-size: 14px;

      .link {
        color: $gray_8;
      }

      .title {
        text-align: left;
        font-weight: 500;
        background-color: $gray_2;
        padding: 10px 12px;
      }

      li {
        display: flex;
        align-items: flex-start;
        padding: 20px 15px 0;

        .thumbnail {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          text-align: left;
          line-height: 180%;
          padding-left: 10px;
          flex: 0.7;

          .red {
            color: $red;
            font-weight: 400;
          }
        }

        .price {
          position: relative;
          align-self: flex-end;
          text-align: right;
          font-weight: 500;
          flex: 0.3;

          &::before {
            content: attr(data-coupon);
            position: absolute;
            color: $red;
            top: -25px;
            right: 0;
            font-size: 12px;
          }

          .prev {
            text-decoration: line-through;
            color: $gray_4;
            font-weight: 500;
          }

          @media screen and (max-width: 450px) {
            &::before {
              display: none;
            }
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      margin: 0 auto;
      padding: 0 0 25px;

      .list {
        li {
          .price {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .container {
    font-size: 16px;
    text-align: left;

    .member-info {
      vertical-align: top;
      display: inline-block;
      width: 654px;
      margin-right: 45px;

      @media screen and (max-width: 450px) {
        width: 100%;
        margin-right: 0;
      }

      .box {
        margin-bottom: 60px;

        input:not([type="radio"]) {
          box-sizing: border-box;
          border: 1px solid $gray_2;
          height: 100%;
          border-radius: 4px;

          &::placeholder {
            color: $gray_5;
          }
        }

        .blue-btn {
          display: flex;
          align-items: center;
          text-align: center;
          border: 1px solid;
          cursor: pointer;
          color: $blue;
          border-radius: 4px;
          padding: 12px 15px;
          margin-left: 15px;
        }
      }

      .coupon {
        .coupon-contents {
          display: flex;
          width: 65%;
          height: 42px;

          .count {
            border-bottom: 1px solid $gray_7;
            flex: 1;
            padding: 10px 0;
          }

          button.unusable {
            opacity: 0.3;
          }
        }
      }

      .point {
        .point-left {
          margin-bottom: 8px;
        }

        .point-contents {
          display: flex;
          width: 65%;
          height: 42px;
          margin-bottom: 10px;

          .point-box {
            flex: 1;

            input {
              text-align: right;
              color: $gray_8;
              width: 100%;
              padding: 0 16px;
              font-size: 16px;
            }

            input.unusable {
              background-color: $gray_1;
            }
          }

          button.unusable {
            opacity: 0.3;
          }
        }

        .point-desc {
          color: $gray_7;
          font-size: 14px;
          line-height: 1.5;
        }
      }

      .address {
        .address-contents {
          color: $gray_8;

          @media screen and (max-width: 450px) {
            margin: 20px 0 24px;
          }

          .row {
            height: 42px;
            margin-bottom: 16px;

            @media screen and (max-width: 450px) {
              &:last-child {
                margin-bottom: 0;
              }
            }

            .title {
              display: inline-block;
              width: 119px;
            }

            input {
              font-size: 16px;
              padding: 0 16px;
            }

            .input-name {
              width: 336px;
            }

            .input-number {
              width: 336px;
            }

            .input-post-num {
              color: $gray_10;
              background-color: $gray_1;
              width: 219px;
            }

            .input-address {
              color: $gray_10;
              width: 535px;
              height: 42px;

              &.background-gray {
                background-color: $gray_1;
              }

              &:first-child {
                margin-bottom: 16px;
              }
            }

            .input-request {
              width: 535px;
              height: 42px;
              padding: 0 16px;
              font-size: 16px;
            }

            .post-search {
              display: inline-block;
            }
          }
        }
      }

      .pay-method {
        .pay-method-title {
          padding-bottom: 4px;
        }

        .pay-method-contents {
          .btn-general-pay {
            display: inline-block;
            width: 25%;

            .label {
              display: flex;
              align-items: center;

              margin-top: 16px;
            }

            .radio {
              margin-right: 10px;
            }

            @media screen and (max-width: 450px) {
              &:not(.radio) {
                width: calc(100% + 12px);
                position: relative;
                left: -12px;
                display: block;
                padding: 20px 0 20px 12px;
                border-bottom: 1px solid $gray_5;
              }
            }
          }

          .btn-general-pay.naver img {
            vertical-align: text-top;
            width: 98px;
          }
        }

        .naver-pay-desc {
          color: $gray_8;
          margin-top: 16px;
          line-height: 1.5;

          .head {
            margin-bottom: 10px;
            font-weight: 600;
          }

          .body span {
            display: block;
            margin-bottom: 10px;
            margin-left: 15px;
            text-indent: -12px;
          }
        }
      }
    }
  }

  .payment {
    hr {
      border: none;
      height: 1px;
      margin: 10px 0 20px;
    }

    .payment-contents {
      font-size: 14px;

      div {
        padding-bottom: 18px;

        span:first-child {
          color: $gray_7;
        }

        span:last-child {
          float: right;
          color: $gray_10;
          letter-spacing: 0.5px;
        }

        span.red {
          color: $red;
        }

        span.blue {
          color: $blue;
        }
      }

      .total {
        font-weight: 500;
        font-size: 16px;
      }

      .points {
        font-weight: 500;
      }
    }
  }

  .payment.stick {
    position: -webkit-sticky;
    position: sticky;
    display: block;
    float: right;
    width: 280px;
    top: 130px;
    z-index: 1;

    hr {
      color: $gray_5;
      background-color: $gray_5;
    }

    .btn-order {
      display: block;
      width: 280px;

      &.unpayable {
        opacity: 0.3;
      }
    }

    @media screen and (max-width: 450px) {
      width: 100%;

      .btn-order {
        width: 100%;
      }
    }
  }

  .payment.inner {
    position: relative;
    display: none;
    float: none;
    z-index: unset;
    top: 0;
    width: 100%;

    hr {
      color: $gray_3;
      background-color: $gray_3;
    }

    .payment-contents {
      margin: 20px 0;

      div:last-child {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  .btn-order {
    display: inline-block;
    width: 175px;
    height: 52px;
    margin-top: 33px;
    font-size: 18px;
    color: $white;
    background-color: $red;
    text-align: center;
    line-height: 52px;
    cursor: pointer;
  }

  .box-head {
    padding-bottom: 20px;

    &::after {
      content: attr(data-coupon);
      color: $red;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 450px) {
  .order-tab {
    margin: 0 auto;
    width: 100%;
    padding-left: 0;

    .wrapper-head {
      border-bottom: 1px solid $gray_3;
      margin: -15px 0 0;
      padding: 30px 12px 13px;

      .head {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 18px;
      }

      .back {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        padding: 0;
      }
    }

    .list-head {
      color: $gray_9;
      padding: 14px 12px 20px 12px;
      font-size: 16px;
    }

    .container {
      padding: 0 12px;

      .member-info {
        .coupon {
          .coupon-contents {
            width: 100%;
          }
        }

        .point {
          .point-contents {
            width: 100%;
            margin: 20px 0;

            .point-box {
              width: calc(100% - 23px);
            }

            .desc {
              font-size: 12px;
            }
          }

          .point-left {
            font-size: 12px;
          }

          .point-desc {
            font-size: 12px;
          }
        }

        .address {
          .address-contents {
            font-size: 14px;

            .row {
              input {
                width: 80%;
                padding: 0 8px;
              }

              .title {
                width: 25%;
              }

              .input-name,
              .input-number,
              .input-address,
              .input-request {
                width: 75%;
                padding: 0 8px;
              }

              .input-post-num {
                flex: 0.7;
                width: 100%;
              }

              .post-search {
                flex: 0.3;
                padding: 12px 8px;
              }
            }

            .row.post-num {
              display: flex;
            }
          }
        }

        .pay-method {
          .pay-method-contents {
            .btn-general-pay {
              .label {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .btn-order {
      width: 100%;
      height: 56px;
      margin: 20px 0 10px;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}
