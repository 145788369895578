@import "~styles/color";

.wrapper {
  position: relative;
}

.filter-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filter-text {
  font-size: 14px;
  color: $gray_10;
  &.isFilting {
    color: $red;
    margin-left: 4px;
    font-weight: 500;
  }
}

@media screen and (max-width: 450px) {
}
