@import "~styles/color";

.price {
  position: relative;
  text-align: left;

  border: 1px solid $gray_4;

  line-height: 40px;

  .condition {
    font-size: 14px;

    &.blue {
      color: $blue;
    }
  }

  .hidden {
    display: none;
  }

  .dimmed-layer {
    display: none;
    position: absolute;
    background-color: $black;

    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
  }

  .box {
    position: absolute;
    background: $white;
    box-sizing: border-box;

    width: 320px;
    padding: 24px;
    border: 1px solid $gray_4;
    border-radius: 5px;

    z-index: 200;
    top: 43px;
    left: -1px;

    .header {
      display: flex;
      margin-bottom: 24px;
      height: 24px;

      .sub-title {
        color: $gray_9;

        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-weight: 500;
        width: 100%;
      }

      .close {
        cursor: pointer;
        color: $gray_5;
      }
    }

    .range-wrapper {
      display: flex;
      flex-wrap: wrap;

      .price-value {
        color: $gray_8;

        height: 24px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .track-wrapper {
        display: flex;

        height: 36px;
        width: 100%;
        margin: 0 10px 16px 10px;

        .track {
          align-self: center;

          height: 5px;
          width: 100%;
        }

        .oval {
          background-color: $white;
          box-sizing: border-box;

          border: solid 1px $blue;
          border-radius: 10px;

          width: 20px;
          height: 20px;
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;

      .button {
        cursor: pointer;

        padding: 0;
        line-height: 1;
      }

      .reset {
        color: $gray_half_8;

        margin-right: 24px;
      }

      .apply {
        font-weight: bold;
        color: $blue;
      }
    }
  }
}

/* 414px 기준 */
@media screen and (max-width: 450px) {
  .price {
    position: static;
    border: 0px;

    &.up {
      background-position: right 24px center;
    }

    .text {
      font-size: 11px;
    }

    .dimmed-layer {
      display: block;
    }

    .box {
      top: 40%;
      left: 6%;
      width: 88%;
    }
  }
}
