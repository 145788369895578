@import "~styles/color";
@import "~styles/variables";

.itemDetail {
  margin-left: 0;
  background-color: $white;

  .view {
    display: block;
    margin-bottom: 0;
    min-height: unset;
    background-color: $white;

    .imageTab {
      width: 100%;
      margin-right: 60px;

      .image {
        display: inline-block;
        max-width: 488px;
        max-height: 570px;
        height: auto;
        width: 100%;
      }

      .subImageArea {
        padding-top: 5px;
        padding-left: 5px;

        &::after {
          display: block;
          clear: both;
          content: "";
        }

        .subImageWrapper {
          float: left;
          width: 40px;
          height: 40px;
          cursor: pointer;
          margin-right: 5px;
        }

        .subImage {
          width: 100%;
        }
      }
    }

    .textTab {
      display: flex;
      justify-content: space-between;
      text-align: left;
      line-height: 200%;
      width: 100%;
      margin: 0;
      padding: 15px;
      box-sizing: border-box;

      .discount-txt {
        color: $blue;
        font-weight: 500;
        font-size: 13px;

        .free {
          opacity: 0.4;
        }
      }

      .title {
        display: block;
        color: $gray_8;
        font-weight: 500;
        margin-top: 0px;
        letter-spacing: 0.2px;
        width: 100%;
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 1.5;
      }

      .priceTab {
        margin-bottom: 0;
        font-size: 18px;

        .price {
          color: $red;
          margin-bottom: 12px;
          margin-right: 6px;
        }

        .prevPrice {
          margin-bottom: 8px;
          color: $gray_4;
          text-decoration: line-through;
        }
      }
    }

    .methods-descripton {
      position: relative;
      line-height: 1.8;
      border-top: 1px solid $gray_3;
      font-size: 12px;
      margin: 0px 6px;
      padding: 15px 6px;
    }

    .hr {
      margin: 0;
      border: none;
      width: 100%;
      height: 8px;
      background-color: $gray_1;
    }
  }

  .mobileTab-wrapper {
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: $gray_8;
    outline: none;

    div {
      display: inline-block;
      width: calc(100% / 3);
    }

    box-shadow: 0 1px 0 0 $gray_3;
  }

  .details {
    height: 400px;
    overflow: hidden;

    &.more {
      height: auto;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .more-wrapper {
    padding: 12px 0 0 0;
    text-align: center;

    .more {
      display: inline-block;
      background-color: $blue;
      color: $white;
      width: 172px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      box-sizing: border-box;
      font-size: 16px;

      svg {
        margin-left: 6px;
        vertical-align: text-bottom;
      }
    }
  }

  .mobileBtns-wrapper {
    position: fixed;
    background-color: $black;
    color: $white;
    width: 100%;
    height: 48px;
    line-height: 48px;
    bottom: 0px;
    font-size: 15px;
    box-sizing: border-box;
    z-index: 9;

    .cart,
    .pin,
    .purchase,
    .cancel {
      display: inline-block;
      box-sizing: border-box;

      &:not(.pinBtnIcon, .optionView) {
        width: calc(100% / 3);
      }
    }

    &.isOptionOpen {
      z-index: 1000;

      .cart,
      .purchase,
      .cancel {
        width: calc(100% / 2);

        span {
          display: inline-block;
          width: 100%;
          border-right: 1px solid $gray_8;
        }
      }

      .optionView {
        position: relative;
        background-color: $gray_0;
        color: $gray_8;
        box-sizing: border-box;
        border-top: 1px solid $gray_3;
        width: 100%;
        bottom: 136px;
        padding: 0 22px 14px;

        .head {
          width: 100%;

          span {
            display: inline-block;
            box-sizing: border-box;
            border: none;
            width: 50%;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }

          .head-totalPrice {
            span {
              color: $red;
            }
          }
        }
      }

      .cancel {
        position: absolute;
        left: 0;
        border-right: 1px solid $gray_8;
      }

      .purchase {
        position: absolute;
        top: 0;
        right: 0;
      }

      .cart {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $gray_6;
  background-color: $white;
  border-top: 1px solid $gray_1;
  padding: 0;
  height: 48px;
  font-size: 15px;

  &.isPin {
    border: 0;
    color: $white;
    background-color: $red;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 18px;
    margin-right: 3px;
  }
}

.cart {
  color: $white;
  background-color: $black;
  cursor: pointer;
  border-right: 1px solid $gray_8;
  padding: 0;
  height: 48px;
  font-size: 14px;
}

.purchase {
  color: $white;
  background-color: $black;
  cursor: pointer;
  padding: 0;
  height: 48px;
  font-size: 14px;
}

.infoTab {
  text-align: left;
  display: none;
  margin-top: 0;
  font-size: 14px;
  border-top: 1px solid $gray_3;
  background-color: $white;

  &.isSelected {
    display: block;
  }
}

.rightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .copy {
    cursor: pointer;
    border: 1px solid $gray_4;
    text-align: center;
    color: $gray_8;
    font-weight: 400;
    border-radius: 2px;
    font-size: 11px;
    width: 60px;
    height: 30px;
  }

  .rating {
    margin-top: 25px;
    text-align: right;
  }
}

.starCount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #f74a56;
}

.rateCountText {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: $gray_half_8;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 252px;
}

.photo-reviews {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 80px;

  .photo-image-wrapper {
    position: relative;
    margin-right: 4px;
  }

  .dark-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 37, 41, 0.7);
    font-size: 15px;
    font-weight: bold;
    color: $white;
    cursor: pointer;
  }
}

.reviewHeaderWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .review-sort {
    display: flex;
    color: $gray_6;
    font-size: 14px;

    .selected {
      font-weight: 500;
      color: $gray_10;
    }

    div {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.reviewTitleType {
  font-size: 16px;
  color: $gray_9;
  font-weight: 500;

  .reviewTotalCount {
    font-weight: normal;
    font-size: 14px;
    color: $gray_7;
  }
}

.empty-review-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: $gray_0;
  color: $gray_7;
  font-size: 14px;
}

.photo-review-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 24px;
  padding: 0px 12px 0px 14px;
}

.photoMore {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $blue;
}

.review-summary-average-satisfaction {
  padding: 0px 6px 0px 6px;
}

.review-header-wrapper {
  display: flex;
  margin-top: 24px;
  margin-bottom: 30px;
  padding-left: 6px;

  .summary-count-text {
    color: $gray_7;
    margin-left: 8px;

    .count-number {
      color: $gray_9;
    }
  }
}

.summary-average-count {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  padding-left: 6px;

  .summary-average-text {
    font-size: 16px;
    color: #f74a56;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  > * {
    margin-right: 8px;
  }
}

.review-section {
  margin-bottom: 80px;
  text-align: center;
}

.info-wrapper {
  display: flex;
  justify-content: space-between;
  height: 44px;
  border-bottom: solid 1px $gray_3;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }

  .selectedTab {
    box-sizing: border-box;
    border-bottom: 2px solid $blue;
    color: $blue;
    font-weight: bold;
  }
}

.mobile-qna-wrapper {
  .qna-button-wrapper {
    padding: 16px;

    .qna-button {
      width: 100%;
      height: 40px;
      border: 1px solid $blue;
      background-color: $white;
      color: $blue;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.qna-body {
  background-color: $white;
  text-align: left;

  .qna-hidden-label {
    display: flex;
    align-items: center;
    padding: 0 16px 16px 16px;
    border-bottom: 1px solid $gray_2;
  }

  .qna-checkbox {
    width: 20px;
    height: 20px;
  }

  .qna-checkbox-title {
    font-size: 14px;
    font-weight: 500;
    color: $gray_8;
    margin-left: 8px;
  }

  .pagination-wrapper {
    margin-top: 20px;
  }
}

.qna-list-no-exist {
  text-align: center;
  margin-top: 30px;
}
