@import "~styles/color";
.navWrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.pageNavIcon {
  position: absolute;
  transform: translate(-50%, 0);
  top: 0;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  background-color: rgba(33, 37, 41, 0.2);
  border-radius: 20px;
  font-size: 11px;
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
  color: $white;
}

.gridViewWrapper {
  margin-right: 12px;
  position: absolute;
  top: 0;
  right: 0;
}
