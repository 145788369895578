@import "~styles/color";
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 38px;
  border-radius: 19px;
  border: solid 1px $gray_4;
  background-color: $white;
  font-size: 12px;
  color: $gray_6;
  cursor: pointer;
  &.isLiked {
    border: none;
    background-color: $red;
    color: $white;
  }
  .recommend-text {
    margin-left: 4px;
  }
}
