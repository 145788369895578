@import "~styles/color";
@import "~styles/variables";
@import "~styles/animate";

.info-contents {
  display: none;
  padding: 16px;
  overflow: hidden;

  &.display {
    display: block;
    animation: unFold 0.5s;
  }
}

.text {
  font-size: 15px;
  line-height: 1.53;
  margin-bottom: 8px;
  color: $gray_9;
}

.link {
  color: $blue;
  text-decoration: underline;
  font-weight: 500;
}

.bold {
  font-weight: 500;
  color: $black;
}

@media screen and (max-width: 450px) {
  .info-contents {
    text-align: left;
  }

  .text {
    font-size: 15px;
    margin-bottom: 16px;
  }
}
