@import "~styles/color";

.title {
  font-size: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $gray_10;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  height: 37px;
  background-color: $gray_0;

  .container {
    display: flex;
    align-items: center;
    color: $gray_10;
    font-size: 12px;
    box-sizing: border-box;
    padding-left: 8px;

    .property {
      color: $gray_7;
      text-align: left;
      margin-right: 24px;
    }

    .product-picture {
      width: 26px;
      height: 26px;
      margin-right: 6px;
      display: inline-block;
      box-sizing: border-box;
    }

    .product-img {
      height: 100%;
      width: 100%;
      border: solid 1px $gray_3;
    }
  }

  .container:nth-child(n + 2) {
    margin-left: 61px;
  }
}

.reason {
  display: inline-block;
  margin-top: 16px;
  text-align: left;
  width: 100%;

  .select {
    width: 320px;
    height: 36px;
    text-indent: 16px;
    font-size: 14px;
    border: solid 1px $gray_3;
  }

  .select:focus {
    outline: none;
  }

  .select:disabled {
    color: $gray_7;
  }

  .select::-ms-expand {
    display: none;
  }
}

.detailed-description {
  margin-top: 16px;
  width: 996px;
  height: 296px;
  background-color: $white;

  .message {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: solid 1px $gray_3;
    resize: none;
    font-size: 14px;
    line-height: 1.71;
    text-align: left;
    padding: 20px 16px;
  }

  .message:focus {
    outline: none;
  }

  .message:placeholder {
    color: $gray_7;
  }
}

.delivery-method,
.refund-account-info {
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $gray_8;
  margin-top: 40px;
  text-align: left;
}

.delivery {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 688px;
  border: solid 1px $gray_3;
  border-bottom: 0;

  .auto,
  .self {
    width: 100%;
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    border-bottom: solid 1px $gray_3;
    background-color: $white;
    padding: 16px;
  }

  .auto.selected,
  .self.selected {
    background-color: $cream;
  }

  .input {
    vertical-align: middle;
    margin: 0;
    margin-right: 8px;
  }

  .label {
    text-align: left;
    color: $gray_10;
    font-size: 13px;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
  }

  .label.selected {
    margin-bottom: 12px;
  }
}

.pickup-info {
  border-top: solid 1px $gray_3;
  margin-left: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .member-info {
    width: 100%;
    font-size: 12px;
    line-height: 1.83;
    text-align: left;
    color: $gray_8;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
  }

  .address-change {
    width: 55px;
    height: 27px;
    background-color: $gray_2;
    padding: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #4d4d4d;
    margin-right: 12px;
    cursor: pointer;
  }
}

.choice {
  font-weight: bold;
  width: 100px;
}

.description {
  display: inline-block;
  line-height: 1.5;
  font-size: 12px;
  text-align: left;
  color: $blue;
  margin-left: 26px;
  margin-top: 6px;
}

.description-additional {
  display: inline-block;
  line-height: 1.5;
  font-size: 12px;
  text-align: left;
  color: $blue;
  margin-left: 26px;
}

.description-precautions {
  color: $gray_half_8;
  line-height: 1.5;
  font-size: 12px;
  margin: 12px 10px 12px 26px;

  .return-delivery-fee {
    font-weight: bold;
  }

  .return-delivery-fee-additional {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .info-wrapper {
    display: block;

    .description-info {
      display: inline-block;
    }
  }
}

.refund-info {
  margin-left: 26px;
  border-top: solid 0.5px $gray_2;
  padding: 8px 12px 8px 0;
  box-sizing: border-box;

  .element {
    display: flex;
    align-items: center;
    line-height: 1.4;
    font-size: 12px;
    color: $gray_8;
    margin: 4px 0;
  }

  .refund-property {
    text-align: left;
    width: 70px;
    font-size: 12px;
    line-height: 2;
    color: $gray_7;
  }

  .refund-text {
    flex: 1;

    .refund-price {
      display: block;
    }

    .refund-account {
      display: inline-block;
    }

    .refund-bank {
      display: inline-block;
    }
  }
}

.account {
  margin: 0 8px;

  .bank-area,
  .account-number-area,
  .holder-area {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .account-property {
    width: 97px;
    font-size: 14px;
    color: $gray_8;
    text-align: left;
  }

  .bank,
  .account-number {
    width: 268px;
    height: 40px;
    border: solid 1px $gray_3;
    background-color: $white;
    box-sizing: border-box;
    font-size: 14px;
    text-indent: 14px;
  }

  .holder {
    width: 167px;
    height: 40px;
    border: solid 1px $gray_3;
    background-color: $white;
    box-sizing: border-box;
    font-size: 14px;
    text-indent: 14px;
  }

  .bank:focus,
  .account-number:focus,
  .holder:focus {
    outline: none;
  }

  .certify {
    width: 91px;
    height: 40px;
    background-color: $gray_2;
    font-size: 14px;
    text-align: center;
    color: $gray_8;
    padding: 0;
    cursor: pointer;
    margin-left: 10px;
  }

  .disabled-certify {
    cursor: not-allowed;
  }

  .certify-notice {
    margin-top: 12px;
    text-align: left;
    font-size: 12px;
    margin-left: 97px;
  }

  .certified {
    color: $blue;
  }

  .not-certified {
    color: $red;
  }
}

.notice {
  margin-top: 16px;
  text-align: left;
  font-size: 12px;
  line-height: 2;
  color: $red;
  font-weight: 500;
}

.policy {
  text-align: left;

  .box {
    max-width: 500px;
  }

  img {
    width: 100%;
  }
}

.actions {
  margin-top: 48px;

  .cancel,
  .submit {
    padding: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    width: 175px;
    height: 52px;
  }

  .cancel {
    border: solid 1px $gray_7;
    background-color: $white;
    color: $gray_8;
  }

  .submit {
    background-color: $darknavy;
    color: $white;
    margin-left: 40px;
  }

  .disabled {
    cursor: not-allowed;
    background-color: $gray_4;
  }
}

.bank::-ms-expand {
  display: none;
}

@media screen and (max-width: 450px) {
  .main {
    margin-left: 0;
    background-color: $gray_0;
    padding-bottom: 40px;
  }

  .title {
    height: 68px;
    padding-left: 8px;
    font-size: 20px;
    font-weight: bold;
    color: $gray_9;
  }

  .info {
    flex-direction: column;
    border: none;
    height: 108px;
    margin-top: 0;
    background-color: $white;

    .container {
      height: 36px;
      width: 100%;
      border-bottom: solid 1px $gray_3;
      margin-left: 0;
    }

    .container:nth-child(n + 2) {
      margin-left: 0px;
    }

    .property {
      color: $gray_6;
      width: 53px;
      margin-right: 0;
    }
  }
  .reason {
    .select {
      margin: 0 8px;
      width: calc(100% - 16px);
      height: 40px;
      border: solid 1px $gray_3;
      background-color: $white;
      text-indent: 8px;
    }
  }

  .delivery-method,
  .refund-account-info {
    margin-left: 8px;
  }

  .detailed-description {
    width: 100%;
    height: 360px;

    .message {
      border-left: none;
      border-right: none;
    }
  }

  .delivery {
    width: 100%;
  }

  .account {
    .account-property {
      width: 68px;
    }
  }

  .choice {
    width: auto;
  }

  .description {
    flex-basis: 100%;
    margin-top: 8px;
    margin-left: 26px;
  }
  .description-additional {
    flex-basis: 100%;
    margin-top: 2px;
    margin-left: 26px;
  }

  .description-precautions {
    margin-left: 26px;

    .return-delivery-fee {
      display: block;
      font-weight: bold;
    }

    .return-delivery-fee-additional {
      display: block;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .description-info {
      display: block;
    }

    .description-info-additional {
      display: block;
    }
  }

  .refund-text {
    .refund-account {
      display: block;
    }
    .refund-bank {
      display: block;
    }
  }

  .certify-notice {
    margin-left: 68px;
  }

  .notice {
    margin: 16px 9px 0 9px;
  }

  .actions {
    margin-top: 40px;

    .cancel,
    .submit {
      width: 136px;
      height: 40px;
    }
  }
}
