@import "~styles/color";

.list {
  font-size: 14px;

  li {
    line-height: 200%;

    .key {
      color: $gray_7;
    }

    .value {
      color: $gray_8;

      .original {
        color: $gray_4;
        text-decoration: line-through;
        margin-left: 6px;
      }
    }
  }

  li.not-between {
    display: flex;

    .key {
      width: 120px;
    }

    .value {
      flex: 1;
    }
  }

  li.between {
    display: flex;
    justify-content: space-between;
  }

  .blue {
    color: $blue;
  }
}
