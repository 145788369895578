@import "~styles/color";

.order-result-tab {
  box-sizing: content-box;

  .section {
    margin-top: 40px;
  }

  .title {
    line-height: 240%;

    .head {
      color: $blue;
      font-size: 24px;
    }

    .desc {
      color: $blue;
      font-size: 18px;
    }
  }

  .receipt-box {
    background-color: $gray_0;
    margin: 32px auto;
    width: 440px;
    padding: 20px 0;

    .box {
      text-align: left;
      margin: 30px;

      .head {
        font-size: 16px;
        margin-bottom: 24px;
      }

      .total-price {
        display: flex;
        color: $blue;
        font-size: 14px;
        margin-top: 10px;

        .key {
          width: 120px;
        }

        .value {
          flex: 1;
        }
      }
    }

    .box:not(:last-child) {
      padding-bottom: 30px;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 372px;

    .btn {
      border: 1px solid $gray_3;
      color: $gray_8;
      border-radius: 4px;
      width: 47%;
      line-height: 42px;
    }

    .btn.blue {
      border: none;
      background-color: $blue;
    }
    .white {
      color: white;
    }
  }

  @media screen and (max-width: 450px) {
    margin-left: 0;

    .receipt-box {
      width: 100%;
    }

    .btns {
      width: 90%;
    }
  }
}
