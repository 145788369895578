@import "~styles/color";

div.modal__points {
  position: absolute;
  left: 0;
  top: 30px;
  width: 240px;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(73, 80, 87, 0.2);
  z-index: 1000;

  .modal__points--header {
    background-color: $gray_0;
  }

  .modal__points--level {
    width: 70px;
    border-right: 0.5px solid $gray_3;
  }

  .modal__points--level.lv {
    font-weight: 900;
    height: 35px;
  }

  .modal__points--percent {
    border-right: 0.5px solid $gray_3;
  }

  #lv1 {
    color: $lv1;
  }

  #lv5 {
    color: $lv5;
  }

  #lv8 {
    color: $lv8;
  }

  #lv11 {
    color: $lv11;
  }

  #lv13 {
    color: $lv13;
  }

  li:not(:last-child) {
    border-bottom: 0.5px solid $gray_3;
  }

  span {
    display: inline-block;
    width: 50px;
    height: 36px;
    padding: 0 10px;
    line-height: 36px;
    font-size: 12px;
    text-align: center;
    color: $gray_8;
  }
}
