@import "~styles/color";

.wrapper {
  display: inline-block;
  position: relative;

  width: 18px;
  height: 18px;
  margin-left: 8px;

  button {
    padding: 0;
  }
}

.box {
  display: none;
  position: absolute;
  box-sizing: border-box;

  z-index: 100;
  top: 30px;
  width: 360px;
  padding: 20px;
  left: 50%;
  margin-left: -135px;

  box-shadow: 0 0 3px 0 rgba(73, 80, 87, 0.2);
  background-color: $white;
}

.warning {
  font-size: 12px;
  line-height: 1.5;

  color: $gray_7;
}

.property {
  display: inline-block;
  vertical-align: top;

  width: 100px;
  font-size: 0.75rem;
  line-height: 1.5;

  color: $gray_7;
}

.value {
  display: inline-block;

  width: 220px;
  font-size: 0.75rem;
  line-height: 1.5;

  color: $gray_8;
}

.show {
  display: block;
}

.list {
  text-align: left;
  li {
    margin-bottom: 5px;
  }
}
