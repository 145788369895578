.wrapper {
  //desktop
  // background: black;
  width: 100%;
  margin-left: -20px;
}

@media screen and (max-width: 1024px) {
  //tablet
  .wrapper {
    width: 100vw;
    margin-left: -20px;
    background: black;
  }
}

@media screen and (max-width: 450px) {
  //mobile
  .wrapper {
    width: 100%;
    height: 100vh;
    margin: 0;
  }
}
