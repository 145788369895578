@import "~styles/color";

.store {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  height: 84px;

  padding-top: 16px;
  padding-bottom: 18px;
}

.rank-and-new {
  height: 50px;
  width: 30px;
}

.rank {
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  font-style: italic;
  text-align: center;
  color: $gray_8;
}
