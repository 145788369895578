@import "~styles/color";

.wrapper {
  margin-left: 0px;
}

.button-wrapper {
  display: flex;
  padding: 16px 8px;
}

.button {
  flex: 1;
  height: 40px;
  font-size: 14px;
  background-color: $gray_1;
  border: solid 1px $gray_4;
  color: $gray_6;
  &.isSelect {
    background-color: $white;
    border: solid 1px $gray_10;
    font-weight: bold;
    color: $gray_10;
  }
}
