@import "~styles/color";

.coupons-tab {
  header.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 20px;
  }

  button.coupon-register {
    cursor: pointer;
    color: $blue;
    text-align: center;
    font-weight: normal;

    width: 65px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    border: solid 1px $blue;

    font-size: 12px;

    margin-left: 16px;
  }

  section {
    margin-top: 40px;

    .discount-coupon {
      justify-content: flex-start;
      box-shadow: none;
      border-bottom: 1px solid $gray_1;

      img {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        margin-top: 14px;
      }

      ul {
        li.name {
          font-size: 15px;
        }

        li.desc {
          color: $gray_7;
          font-size: 13px;
          line-height: 160%;
        }

        li.usable-date {
          color: $blue;
          font-size: 12px;
          line-height: 200%;
        }
      }
    }

    .no-list {
      text-align: center;
      padding: 100px;

      .no-list-text {
        margin-top: 16px;
        font-size: 15px;
        text-align: center;
        color: $gray_5;
      }
    }
  }

  @media screen and (max-width: 450px) {
    padding-left: 0;
    margin-left: 0;
    width: 100%;

    header.title {
      justify-content: space-between;

      padding: 16px 10px 0 10px;
    }

    button.coupon-register {
      margin-left: 0;
    }

    section {
      .discount-coupon {
        padding-left: 10px;
      }
    }
  }
}
