@import "~styles/color";

.main {
  margin-left: 115px;
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

.popular {
  width: 240px;
  margin-left: 23px;
}

.popular-header {
  text-align: left;
  font-weight: bold;
  color: $gray_9;

  height: 27px;
  line-height: 27px;

  font-size: 18px;
  margin-bottom: 10px;
}

.popular-hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $gray_4;
}

.popular-flex {
  display: flex;
}

.column-direction {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.keyword {
  color: $gray_8;
  text-decoration: none;

  height: 17px;
  line-height: 14px;
  margin-top: 22px;
  font-size: 14px;
}

.keyword-rank {
  display: inline-block;
  color: $blue;
  font-weight: bold;

  width: 22px;
  margin-right: 11px;
}

.keyword-text {
  color: $gray_8;
}

.title-box {
  display: flex;
  justify-content: space-between;

  height: 29px;
  line-height: 29px;
  margin-bottom: 24px;
}

.product-ranking {
  margin-top: 79px;
}

.margin-top-68 {
  margin-top: 68px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.more-box {
  display: flex;
  align-items: center;
  color: $blue;
  font-weight: 500;
  font-size: 14px;
}

.products,
.coordi {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.coordi {
  height: 839px;
}

.loading {
  align-items: center;
  justify-content: center;
}

.tags {
  display: flex;
  align-items: center;
  height: 45px;
}

.tag {
  color: $gray_8;
  cursor: pointer;

  font-size: 12px;
  height: 100%;
  margin-right: 20px;
  padding: 0;
}

.tag:hover,
.active {
  font-weight: bold;
  color: $blue;

  border-bottom: solid 2px $blue;
}

.tags-hr {
  color: #e9ecef;

  opacity: 0.2;
  margin-top: 0;
  margin-bottom: 24px;
}

.stores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
}

.store-rank {
  font-size: 12px;
  font-weight: 500;
  color: $gray_7;
  text-align: left;
}

.store-rank.blue {
  color: $blue;
}

.store-picture {
  display: flex;
  background-color: $white;
  border-radius: 100%;

  width: 56px;
  height: 56px;

  margin-top: 6px;
}

.store-img {
  object-fit: cover;

  width: 100%;
  border-radius: 100%;
}

.store-name {
  font-size: 12px;
  margin-top: 4px;
  color: $gray_8;
  text-align: left;
}

@media screen and (max-width: 450px) {
  .main {
    background-color: $gray_1;

    margin-left: 0;
    margin-bottom: 0;
    padding-bottom: 150px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .popular {
    display: none;
  }

  .product-ranking {
    margin-top: 40px;
  }

  .title-box {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .title {
    font-weight: bold;

    font-size: 16px;
  }

  .tags-hr {
    margin-bottom: 0;
    opacity: 0.1;
  }

  .coordi {
    background-color: $white;
    height: 895px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 16px;
  }

  .tags {
    background-color: $white;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    font-size: 13px;

    border-top: 1px solid $gray_2;

    .tag {
      box-sizing: content-box;

      font-size: 13px;
      margin-right: 0;
      margin-left: 19px;
      padding-top: 9px;
      padding-bottom: 9px;

      &:first-child {
        margin-left: 6px;
      }

      &:last-child {
        padding-right: 6px;
      }
    }
  }

  .stores {
    margin-left: 8px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .store {
    margin-right: 24px;
    margin-bottom: 12px;
  }

  .store-img {
    border-radius: 30px;
  }
}
