@import "~styles/color";

.header {
  position: fixed;
  background: $gray_11;
  min-width: 783px;

  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .width-box {
    display: block;
    margin: 0 auto;

    max-width: 1100px;

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin: 0 12px;

      height: 52px;

      .gender-wrapper {
        margin-left: 24px;

        .button {
          cursor: pointer;

          padding: 0;
          height: 50px;

          &.highlight {
            border-bottom: 4px solid $red;
          }

          &:last-child {
            margin-left: 24px;
          }
        }
      }

      .recents-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        cursor: pointer;
        outline: none;
        user-select: none;

        font-size: 14px;

        flex: 1;

        height: 100%;
        margin-left: 16px;

        .thumbnail-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          background-color: $gray_5;
          margin-right: 8px;
          height: 20px;
          width: 20px;
          border-radius: 100%;

          .thumbnail {
            user-select: none;
            height: 20px;
            width: 20px;
            border-radius: 100%;
          }
        }
      }

      .cart-wrapper {
        color: $white;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;

        height: 20px;
        font-size: 12px;

        .cart-badge {
          color: $white;
          background-color: $red;

          height: 14px;
          width: 14px;
          border-radius: 50%;
          line-height: 12px;

          font-size: 9px;
          font-weight: bold;
          text-align: center;

          margin-left: 2px;
        }

        &.active {
          font-weight: bold;
          color: $blue;
        }
      }

      .division {
        background-color: $gray_4;
        box-sizing: border-box;

        opacity: 0.5;
        width: 1px;
        height: 18px;
        margin: 0 7px;
      }

      .member {
        color: $white;
        cursor: pointer;
        padding: 0;

        font-size: 12px;

        &.active {
          font-weight: bold;
          color: $blue;
        }
      }

      .level-wrapper {
        display: flex;
        align-items: center;

        .level-bg {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;

          height: 20px;
          width: 20px;

          border-radius: 50%;
        }

        .level-data {
          display: flex;
          align-items: center;

          font-size: 12px;

          margin-left: 8px;

          .border-base {
            display: flex;
            position: relative;
            box-sizing: border-box;

            height: 20px;
            width: 80px;

            border-bottom: 2px solid $gray_3;

            .border-percent {
              position: absolute;

              bottom: -2px;

              height: 2px;
            }

            .level {
              color: $white;
              font-weight: bold;
            }

            .percent {
              color: $white;

              margin-left: 6px;
            }
          }
        }
      }

      .point-wrapper {
        display: flex;
        align-items: center;

        font-size: 12px;

        .point-icon {
          margin-left: 8px;

          height: 20px;
          width: 20px;
        }

        .point-text-wrapper {
          display: inline-block;
          color: $white;
          margin-left: 4px;
          height: 20px;

          line-height: 20px;

          .point-text {
            font-weight: bold;
          }
        }
      }

      .sign {
        color: $white;
        cursor: pointer;
        padding: 0;

        font-size: 12px;
      }
    }
  }
}

.nav {
  position: fixed;
  top: 52px;
  min-width: 783px;

  left: 0;
  width: 100%;

  z-index: 99;

  background-color: $gray_1;

  .width-box {
    display: block;
    margin: 0 auto;

    max-width: 1100px;

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 56px;

      margin-left: 12px;

      .routes {
        .route {
          margin: 16px 0;
          font-size: 14px;
          color: $gray_9;
          margin-right: 36px;

          &.active {
            color: $red;
            font-weight: bold;
          }
        }
      }

      .space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          background-color: $white;

          width: 400px;

          border: 1px solid $gray_3;

          height: 40px;

          .condition-wrapper {
            background: $gray_1;
            box-sizing: border-box;
            color: $gray_7;

            font-size: 12px;
            border-radius: 2px;
            margin: 5px;

            .product {
              padding: 0;
              height: 30px;
              width: 43px;
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }

            .store {
              padding: 0;
              height: 30px;
              width: 54px;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
            }

            .active {
              background-color: $white;
              color: $blue;

              border: solid 1px $blue;
            }
          }

          .keyword-wrapper {
            box-sizing: border-box;
            text-align: left;

            margin-left: 3px;
            margin-right: 12px;

            flex: 1;
            height: 30px;

            .keyword {
              width: 100%;
              height: 30px;
              font-size: 14px;
              border: 0;
              padding: 0;
              margin-right: 12px;

              &::placeholder {
                color: $gray_5;
              }
            }
          }

          .find-wrapper {
            display: flex;
            align-items: center;
            width: 50px;
            height: 32px;
            margin: 4px;

            .find {
              cursor: pointer;
              width: 100%;
              height: 30px;
              border-radius: 2px;
              background-color: $blue;
              font-size: 12px;
              color: $white;
              padding: 0;
            }
          }
        }

        .apps {
          .android {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
