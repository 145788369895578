@import "~styles/color";

.discount-coupon {
  margin: 10px auto;
  width: 60%;

  ul {
    li.name {
      font-size: 0.875em;
      font-weight: 500;
    }

    li.discount {
      font-size: 1.25em;
    }

    li.desc {
      color: $gray_7;
      line-height: 160%;
      font-size: 0.75em;
    }

    li.issuable-date {
      color: $blue;
      font-weight: 500;
      font-size: 0.7em;
    }

    li.usable-at {
      color: $red;
      font-weight: 500;
      font-size: 0.7em;
    }
  }

  .download {
    cursor: pointer;
    background-color: $gray_10;
  }

  .already-issued {
    border-left: 1px dashed $gray_4;
    color: $gray_9;
    font-size: 14px;

    span {
      width: 60%;
    }
  }

  .not-issuable {
    background-color: $gray_5;
    color: $white;
    font-size: 12px;
    padding: 3px;
    width: 205px;
  }
}

@media screen and (max-width: 450px) {
  .discount-coupon {
    width: 95%;
    margin: 0px auto 10px;
  }
}
