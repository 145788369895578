@import "~styles/color";
.wrapper {
  display: flex;
  width: 569px;
  border-right: solid 1px $gray_3;
  padding: 30px 0px 30px 30px;
  &.borderNone {
    border-right: none;
  }
}
.image {
  width: 140px;
  height: 134px;
}

.description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 30px;
  width: 100%;
  .name {
    margin-bottom: 14px;
  }
  .name-text {
    font-size: 18px;
    font-weight: bold;
    color: $gray_9;
  }
  .option {
    margin-bottom: 15px;
  }
  .option-text {
    font-size: 13px;
    color: $gray_7;
  }
  .price-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .cancle-line {
      text-decoration: line-through;
      font-size: 13px;
      color: $gray_5;
      margin-right: 10px;
    }
    .price {
      font-size: 15px;
      font-weight: 500;
      color: $gray_9;
    }
  }
  .store {
    display: flex;
    align-items: center;
    > * {
      margin-right: 15px;
    }
    .description {
      font-size: 13px;
      line-height: 1.54;
      color: $gray_7;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 57px;
      height: 26px;
      border-radius: 3px;
      background-color: $gray_1;
      .button-text {
        font-size: 12px;
        color: $gray_10;
      }
    }
    &.review-type {
      margin-bottom: 16px;
    }
  }
}
