@import "./color";

.light {
  font-weight: 300;
}

.middle {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.link {
  display: block;
  text-decoration: none;
  color: $gray_9;
}

.ticket-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 10px 0;

  ul {
    text-align: left;
    width: 75%;
    padding: 0 14px;

    li {
      margin: 14px 0;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
  }
}

select {
  background: url("~assets/img/ic-more-down-gray.svg") no-repeat;
  background-position: right 8px center;
  background-size: 18px;
  background-color: $white;
  border-radius: 2px;

  &:invalid {
    color: $gray_7;
    font-weight: 500;
  }
}

.btn-loading {
  pointer-events: none;
}

picture img {
  &:hover {
    opacity: 0.9;
  }
}
