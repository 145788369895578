@import "~styles/color";
@import "~styles/variables";
.graph-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 128px;
  .graph-data-title {
    margin: 0;
    color: $gray_9;
    font-weight: bold;
  }
}
.rod-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 34px;
}
.rod-contents {
  display: flex;
}

@media (max-width: 450px) {
  .graph-data-wrapper {
    flex-direction: row;
    padding: 24px 16px 17px 10px;
    height: 64px;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid $gray_1;
    .graph-data-title {
      font-weight: normal;
      flex: 1;
    }
  }
  .rod-wrapper {
    margin-top: 0px;
    flex: 3;
  }
}
