@import "~styles/color";

.recents {
  position: absolute;
  background-color: $white;
  box-sizing: border-box;

  border: 1px solid $gray_2;
  border-top: 0;

  width: 100%;
  min-width: 360px;
  top: 52px;

  .recent {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: $gray_8;

    height: 38px;
    font-size: 12px;

    border-top: solid 1px $gray_2;

    .product {
      display: flex;
      align-items: center;
      flex: 1;

      .thumbnail {
        user-select: none;

        margin-left: 10px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }

      .product-name {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;

        margin-left: 4px;

        width: 250px;
        height: 18px;
        line-height: 18px;
      }
    }

    .price {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 450px) {
  .recents {
    top: 40px;

    max-width: 100%;
  }
}
