@import "~styles/color";
.wrapper {
  background: url("~assets/img/img-select-down-arrow-fill.svg") no-repeat 90% 50%;
  width: 80px;
  height: 32px;
  padding-left: 13px;
  font-size: 12px;
  color: $gray_8;
  border: solid 1px $gray_3;
}

.wrapper::-ms-expand {
  display: none;
}

@media (max-width: 450px) {
  .wrapper {
    border: none;
    padding-left: 0px;
    margin-left: 4%;
    font-size: 14px;
  }
}
