@import "~styles/color";

.photoReviewGridWrapper {
  display: flex;
  flex-direction: column;
  width: 860px;
  height: 628px;
  padding: 0px 22px 0px 22px;
  background-color: $white;
  &.isMobile {
    width: 100%;
  }
}

.photoReviewGridHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
}
.photoBodyGridWrapper {
  width: 100%;
  margin-top: 32px;
  text-align: left;
  height: 580px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
}

.image-grid-wrapper {
  display: block;
  width: 100%;
}
.photoGridTitleText {
  font-size: 16px;
  font-weight: bold;
  color: $gray_10;
}

.photoContents {
  padding: 1px;
  border-radius: 4px;
  width: 120px;
  height: 120px;
}

.close-modal-button-wrapper {
  cursor: pointer;
}

.photo-wrapper {
  position: relative;
  display: inline-block;
  margin: 1px;
  border-radius: 4px;
  width: 120px;
  height: 120px;
  background-color: $gray_1;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
  .photoReviewGridWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .photoBodyGridWrapper {
    width: 100%;
    height: 100vh;
    margin-left: 2.5%;
  }
  .photoContents {
    margin-left: 4px;
    width: 30%;
  }
  .photoReviewGridHeader {
    padding: 0px 22px 0px 22px;
  }

  .photo-wrapper {
    margin-left: 4px;
    width: 30%;
  }
}
