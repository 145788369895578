@import "~styles/color";
@import "~styles/variables";
@import "~styles/animate";

.title {
  margin-top: 23px;
  font-size: 14px;
  font-weight: bold;
}

.bold {
  font-weight: 500;
}

.info {
  display: none;
  margin-bottom: 24px;
  font-size: 14px;
  overflow-y: hidden;
  text-align: left;

  &.display {
    display: block;
    animation: unFold 0.5s;
  }

  .box {
    display: flex;
    border-bottom: solid 1px $gray_2;

    .key,
    .value {
      font-size: 14px;
      line-height: 1.36;
    }

    .key {
      vertical-align: top;
      color: $gray_half_8;
      background-color: $gray_0;
      padding: 8px 16px;
      width: 128px;
    }

    .value {
      display: inline-block;
      white-space: pre-wrap;
      color: $gray_9;
      padding: 8px;

      @media screen and (max-width: 450px) {
        flex: 1;
      }
    }
  }
}

@media (max-width: 450px) {
  .wrapper {
    margin-top: 24px;
  }

  .title {
    margin-top: 0px;
  }

  .info {
    margin-bottom: 25px;

    .box {
      .key,
      .value {
        font-size: 14px;
        line-height: 1.36;
      }
    }
  }
}
