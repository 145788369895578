@import "~styles/color";
.input-wrapper {
  margin-bottom: 40px;
  .input-container {
    .input-title {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 4px;

      color: $gray_5;
      text-align: left;
    }
    .input-body {
      display: flex;
      justify-content: space-between;
    }
    .input {
      width: 204px;
      height: 40px;
      border-radius: 4px;
      border: solid 1px $gray_3;
      background-color: $white;
      font-size: 15px;
      color: $gray_8;
      padding: 0 0 0 12px;
    }

    .button {
      width: 108px;
      height: 42px;
      border-radius: 4px;
      background-color: $blue;
      color: $white;
      font-size: 15px;
      margin-left: 8px;
      padding: 0px;
      cursor: pointer;
      &:disabled {
        background-color: $gray_3;
        cursor: default;
      }
    }
  }
}

.timer {
  display: block;
  height: 14px;
  font-size: 12px;
  color: $red;
  text-align: left;
  padding: 4px 0px 10px;
  line-height: 1.5;
}

.description-wrapper {
  width: 100%;
  text-align: left;
  .help-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 27px;
    border-radius: 4px;
    background-color: $white;
    border: solid 1px $gray_4;
    color: $gray_8;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.submit-container {
  margin-top: 24px;
}

.signup-description {
  font-size: 13px;
  color: $gray_6;
  line-height: 1.7;
  white-space: pre-wrap;
}
.term-box-wrapper {
  margin-bottom: 26px;
}
@media screen and (max-width: 450px) {
  .container {
    overflow-x: hidden;
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 98px);
    padding-bottom: 16px;
  }
  .input-wrapper {
    padding: 0 6.7%;
  }

  .description-wrapper {
    width: calc(100% - 13.4%);
    padding: 0 6.7%;
  }
}
