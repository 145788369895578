@import "~styles/color";
@import "~styles/variables";

.main {
  font-size: 14px;
  font-weight: bold;
  height: 20px;

  margin-bottom: 16px;

  .category {
    color: $gray_9;
  }
}

.sidebar {
  .mylookpin {
    .title {
      .link {
        color: $blue;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}


.link {
  display: block;
  text-decoration: none;
  color: $gray_7;
  font-size: 12px;
}


.sub {
  font-size: 12px;

  height: 18px;

  margin-bottom: 12px;

  .category {
    color: $gray_7;
  }

  .category:hover {
    color: $gray_9;
    font-weight: bold;
  }
}

li.sub:last-child {
  margin-bottom: 40px;
}

.selected {
  &.category {
    color: $blue;
    font-weight: bold;
  }
}

.sidebar {
  float: left;
  width: 82px;
  margin-top: 148px;
  margin-bottom: 20px;
  padding-left: 12px;
  text-align: left;

  .mylookpin {
    li {
      font-size: 13px;
      margin-bottom: 20px;

      .blue {
        color: $blue;
        font-weight: bold;
      }
    }

    li.bold {
      font-size: 16px;
    }

    li.border-mylookpin::before {
      content: "";
      display: block;
      border-top: 1px solid $black;
      width: 8px;
    }
  }
}