@import "~styles/color";

.wrapper {
  flex-basis: 100%;
  padding: 30% 0;

  @media screen and (max-width: 450px) {
    position: fixed;
    background-color: white;
    display: flex;
    align-items: center;
    opacity: 0.9;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
