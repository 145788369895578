@import "~styles/color";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $gray_1;
  background-color: $white;
  padding: 16px 16px 16px 0px;
  cursor: pointer;
  @media screen and (max-width: 450px) {
    padding: 16px;
  }
}

.title-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: $gray_9;
  text-align: left;
}

.sub-title-text {
  text-align: left;
  font-size: 11px;
  line-height: 1.5;
  color: $gray_6;
  margin-bottom: 4px;
}
