@import "~styles/color";
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: $gray_9;
    .range {
      font-size: 12px;
      font-weight: normal;
      color: $gray_7;
    }
  }
}
.icon-wrapper {
  display: flex;

  border-top: solid 1px $gray_3;
  border-bottom: solid 1px $gray_3;
  justify-content: space-between;
}

@media screen and (max-width: 450px) {
  .header {
    margin-bottom: 24px;
    padding: 0px 8px;
  }
  .icon-wrapper {
    border-top: none;
    border-bottom: none;
    padding: 0px 4px;
  }
}
