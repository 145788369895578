@import "~styles/color";

.wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  border-style: inset outset outset inset;
  border-radius: 4px;
  border: 1px solid $gray_4;

  width: 239px;
  height: 40px;
  padding-left: 12px;
}

.keyword {
  outline: none;
  border: none;

  flex: 1;

  box-sizing: border-box;
  font-size: 14px;

  margin-right: 6px;

  &::placeholder {
    color: $gray_5;
  }
}

.button {
  cursor: pointer;
  color: $blue;
  padding: 0;
  width: 24px;
  margin-right: 12px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
  }

  .keyword {
    background-color: $gray_1;

    margin-left: 0px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
  }
}
