@import "~styles/color";

.contacts-tab {
  .card-container {
    display: none;
  }

  header.title {
    text-align: left;
    font-size: 1.25em;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
  }

  .table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 40px;
    width: 100%;
    border-top: 1px solid $gray_1;

    .row {
      display: table-row;
      border-bottom: 1px solid $gray_1;
    }

    .row:not(.header) {
      cursor: pointer;
      outline: none;
    }

    .row.header {
      font-size: 0.875em;

      .cell:nth-child(2) {
        width: 30%;
      }

      .cell:nth-child(3) {
        width: 45%;
      }
    }

    .row.list {
      font-size: 0.75em;
    }

    .row.list.on {
      background-color: rgba(255, 253, 225, 0.3);
    }

    .row.no-flip {
      display: none;
    }

    .row.flip {
      display: table-row;
      background-color: rgba(255, 253, 225, 0.3);
    }

    .row.no-list {
      cursor: auto;
      display: table-caption;
      caption-side: bottom;
      text-align: center;
      color: $gray_8;
      font-size: 14px;
      padding: 100px 0;
    }

    .cell {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $gray_8;
      padding: 10px 15px;

      .product {
        display: flex;
        align-items: center;
      }

      .product-image {
        border: 1px solid $gray_1;
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
    }

    .cell.left {
      text-align: left;
      line-height: 180%;
    }

    .cell.no-wrap {
      word-break: keep-all;
      white-space: nowrap;
    }

    .cell.date {
      white-space: normal;
      word-break: keep-all;
      line-height: 160%;
      font-size: 0.7rem;
    }

    .cell.answer-done {
      color: $blue;
      font-weight: 500;
    }

    .cell.response {
      text-align: left;
      line-height: 200%;
      font-size: 13px;

      .store {
        color: $blue;
      }

      pre {
        white-space: pre-wrap;
        width: 300%;
      }
    }
  }

  @media screen and (max-width: 450px) {
    margin: 0 8px;

    header.title {
      font-size: 1.428em;
      padding-top: 16px;
    }

    .table {
      display: none;
    }

    .card-container {
      display: block;
      margin: 30px 0;

      .no-list {
        color: $gray_8;
        padding: 100px 0;
      }

      .card {
        border: 1px solid $gray_3;
        margin-bottom: 20px;

        ul.content {
          text-align: left;
          color: $gray_8;
          padding: 8px 12px 20px;

          li.info {
            display: flex;
            align-items: center;
            font-size: 13px;

            img {
              width: 40px;
              height: 40px;
            }

            .name {
              padding-left: 8px;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              width: 220px;
              overflow: hidden;
            }

            .is-answer {
              text-align: right;
              color: $gray_7;
              flex: 1;
            }

            .answer-done {
              text-align: right;
              color: $blue;
              font-weight: 500;
              flex: 1;
            }
          }

          li.desc {
            margin: 15px 0;
            line-height: 160%;

            pre {
              white-space: pre-wrap;
              word-break: break-all;
            }
          }

          li.date {
            color: $gray_7;
            font-size: 13px;
          }
        }

        ul.response {
          text-align: left;
          border-top: 1px solid $gray_3;
          padding: 20px;

          li.store {
            color: $blue;
            font-size: 12px;
          }

          li.desc {
            margin: 15px 0;
            font-size: 13px;
            line-height: 160%;

            pre {
              white-space: pre-wrap;
              word-break: break-all;
            }
          }

          li.date {
            color: $gray_7;
            font-size: 13px;
          }
        }

        ul.content.flip {
          background-color: rgba(255, 253, 225, 0.3);
        }

        ul.response.flip {
          display: block;
          background-color: rgba(255, 253, 225, 0.3);
        }

        ul.response.no-flip {
          display: none;
        }

        button {
          border-top: 1px solid $gray_3;
          color: $gray_7;
          width: 100%;
          padding: 15px 0;
        }

        button.flip-button::after {
          content: attr(data-flip);
        }

        button.flip-button.on::after {
          content: attr(data-unflip);
        }
      }
    }
  }

  .paging {
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
}
