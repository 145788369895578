@import "~styles/color";

.gray07 {
  color: $gray_7;
}

.blue {
  color: $blue;
}

.points-tab {
  text-align: left;

  header.title {
    text-align: left;
    font-size: 1.25em;
  }

  .my-points {
    .title-box {
      margin: 48px 0 16px 0;
    }

    .own {
      font-size: 22px;
    }

    span:not(.head) {
      font-size: 12px;
    }

    span.head {
      font-size: 16px;
    }
  }

  .points-title {
    margin: 40px 0 15px 0;
    font-size: 16px;
  }

  .table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    .row {
      display: table-row;
    }

    .row:not(.header):not(.title) {
      outline: none;
    }

    .row.header {
      border-top: 1px solid $gray_1;
      border-bottom: 1px solid $gray_1;
      height: 55px;
      font-size: 0.875em;

      .cell:nth-child(1) {
        width: 20%;
      }

      .cell:nth-child(2) {
        width: 40%;
      }
    }

    .row.no-list {
      display: table-caption;
      caption-side: bottom;
      text-align: center;
      color: $gray_8;
      font-size: 14px;
      padding: 100px 0;
    }

    .row.list {
      height: 50px;
      font-size: 12px;
      border-bottom: 1px solid $gray_1;
    }

    .cell {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: $gray_8;
      padding: 10px 15px;
    }

    .cell.no-wrap {
      word-break: keep-all;
      white-space: nowrap;
    }

    .cell.minus {
      color: $red;
    }

    .cell.left {
      text-align: left;
    }

    .cell.right {
      text-align: right;
    }
  }

  @media screen and (max-width: 450px) {
    padding-left: 0;
    margin-left: 0;
    width: 100%;

    header.title {
      padding: 16px 0 0 10px;
    }

    .my-points {
      padding-left: 10px;
    }

    .points-title {
      padding-left: 10px;
      font-size: 14px;
    }

    .table {
      .row.header {
        height: 35px;
      }

      .row.list {
        height: 40px;
      }

      .cell {
        padding: 10px 5px;
      }
    }
  }
}
