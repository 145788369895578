@import "~styles/color";
.wrapper {
  width: 592px;
  text-align: left;
  background-color: $white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $gray_10;
  }
}

.product-wrapper {
  margin-bottom: 40px;
}

.sub-title {
  font-size: 16px;
  font-weight: bold;
  color: $gray_9;
  line-height: 1.5;
  margin-bottom: 6px;
  &.normal-review-margin {
    margin-bottom: 25px;
    span {
      color: $blue;
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.user-wrapper {
  margin-bottom: 40px;
}

.user-info-input-wrapper {
  display: flex;
  margin-bottom: 16px;
}

.description {
  font-size: 13px;
  line-height: 1.7;
  color: $gray_half_8;
  span {
    text-decoration: underline;
  }
}

.button {
  cursor: pointer;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.input-title {
  font-size: 16px;
  color: $gray_8;
  margin-right: 25px;
}

.satisfaction-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.submit-button {
  width: 100%;
  height: 54px;
  border-radius: 4px;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  &:disabled {
    background-color: $gray_3;
  }
}

.content-input {
  width: 100%;
  min-height: 158px;
  border-radius: 4px;
  background-color: $gray_1;
  border: none;
  border-radius: 4px;
  resize: none;
  outline: none;
  padding: 16px;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 1.5;
  color: $gray_8;
  font-size: 16px;
  &::placeholder {
    color: $gray_6;
    font-family: "Noto Sans KR", sans-serif;
  }
}

.submit-wrapper {
  margin-bottom: 24px;
}

.photo-review-title {
  margin-bottom: 20px;
}

.photo-input-wrapper {
  display: flex;
}

.review-write-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 48px;
  background-color: $gray_0;
  color: $gray_8;
  i {
    margin-left: 4px;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: calc(100% - 20px);
    padding: 10px;
  }
  .user-info-input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
