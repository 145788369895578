@import "~styles/color";

.pins-tab {
  min-height: 8200px;
}

.info {
  margin-top: 8px;
  font-size: 12px;
  color: $gray_8;
  text-align: left;

  height: 18px;
  padding-top: 3px;

  .price {
    color: $red;
  }
}
.nav-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray_4;
}

.filter-wrapper {
  text-align: right;
}

.remove {
  margin-top: 40px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    margin: 0;
  }

  .checked {
    text-align: left;
    color: $gray_8;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
  }
}

.button {
  cursor: pointer;
  width: 39px;
  height: 26px;

  border-radius: 4px;
  border: solid 1px $gray_5;
  background-color: $white;

  padding: 0;
  margin-left: 9px;

  font-size: 12px;
  text-align: center;
  color: $gray_9;
}

.list {
  margin-top: 24px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  text-align: left;

  label {
    margin-bottom: 24px;
  }
}

.hidden {
  width: 180px;
}

@media screen and (max-width: 450px) {
  .pins-tab {
    margin-left: 0;
  }
  .nav-wrapper {
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
  .info {
    margin-left: 12px;
  }

  .remove {
    padding: 10px 12px;
    margin: 16px 0;
  }

  .list {
    margin: 16px 6px;
  }

  .card {
    label {
      margin-bottom: 8px;
    }
  }

  .hidden {
    width: 112px;
  }
}
