@import "~styles/color";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: $gray_3;
  margin-bottom: 10px;
  &.highLight {
    background-color: $blue;
  }
}
.title {
  font-size: 13px;
  color: $gray_6;
  &.highLight {
    color: $blue;
    font-weight: bold;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 12px;
  }
}
