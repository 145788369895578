@import "~styles/color";
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 20px 0px 16px;
  & + & {
    border-left: 1px solid $gray_3;
  }
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .target-title {
    font-size: 14px;
    color: $gray_7;
    line-height: 1.5;
    margin-bottom: 4px;
    &.highLighting {
      color: $blue;
    }
  }
  .count {
    font-size: 16px;
    color: $gray_10;
    font-weight: 500;
    line-height: 1.5;
    &.highLighting {
      color: $blue;
    }
  }
}
.img {
  width: 66px;
  height: 66px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    & + & {
      border-left: none;
    }
  }
  .text-wrapper {
    margin-top: 10px;
    .target-title {
      font-size: 11px;
    }
    .count {
      font-size: 13px;
    }
  }
  .img {
    width: 48px;
    height: 48px;
  }
}
