@import "~styles/color";

.feedback-tab {
  header {
    font-weight: bold;
    font-size: 1.25em;
  }

  .desc {
    margin: 20px 0;
  }

  form {
    text-align: center;
    border-top: 1px solid $gray_1;
    margin-top: 40px;
    padding-top: 40px;

    textarea {
      width: 320px;
      height: 400px;
    }

    button {
      border: 1px solid $gray_3;
      width: 320px;
      padding: 10px 0;
      margin-top: 10px;
    }
  }
}
