@import "~styles/color";

.card {
  position: relative;
  text-align: left;
  background-color: white;

  font-size: 16px;
  margin-bottom: 30px;
  width: 180px;
  .isSelect {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 40;
    border-radius: 4px;
    padding-bottom: 14px;
  }
}

.thumbnail-picture-img {
  object-fit: cover;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.thumbnail-picture-img:hover {
  transform: scale(1.05);
  transition: 0.3s;
}
.flex-box {
  display: flex;
}

.sold-out {
  position: relative;
  padding: 0;

  &::after {
    content: attr(data-msg);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 210px;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

@media screen and (max-width: 450px) {
  .card {
    box-sizing: border-box;
    font-size: 12px;
    width: 112px;
    margin-bottom: 24px;
    .isSelect {
      padding-bottom: 5px;
    }
  }
  .sold-out {
    &::after {
      height: 140px;
    }
  }
}
