@import "~styles/color";

.wrapper {
  position: absolute;
  left: 220px;
  width: 240px;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(73, 80, 87, 0.1);
  z-index: 1000;

  .header {
    background-color: $gray_0;

    .level {
      width: 70px;
      border-right: 0.5px solid $gray_3;
    }
  }

  .content {
    .level {
      font-weight: 900;
      height: 35px;
    }

    #lv1 {
      color: $lv1;
    }

    #lv5 {
      color: $lv5;
    }

    #lv8 {
      color: $lv8;
    }

    #lv11 {
      color: $lv11;
    }

    #lv13 {
      color: $lv13;
    }
  }

  .percent {
    width: 70px;
    border-right: 0.5px solid $gray_3;
  }

  li:not(:last-child) {
    border-bottom: 0.5px solid $gray_3;
  }

  span {
    display: inline-block;
    width: 50px;
    height: 36px;
    padding: 0 10px;
    line-height: 36px;
    font-size: 12px;
    text-align: center;
    color: $gray_8;
  }
}
