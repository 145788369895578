@import "~styles/color";

.footer {
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 34px;
  text-align: left;

  @media screen and (max-width: 450px) {
    display: block;
    padding-left: 8px;
    width: 100%;
    margin-bottom: 34px;
    box-sizing: border-box;

    span {
      padding-right: 1px;
      word-break: keep-all;
    }
  }

  .footer__infoTab {
    position: relative;
    left: -34px;
    padding-left: 34px;
    width: 1100px;
    height: 33px;
    line-height: 33px;
    background-color: $gray_0;
    border: 1px solid #e9ecef;
    font-size: 12px;
    color: $gray_8;

    @media screen and (max-width: 450px) {
      width: 100%;
      height: 100%;
    }

    span {
      cursor: pointer;

      a {
        color: inherit;
        text-decoration: none;
      }

      &:not(:last-child) {
        border-right: 1px solid #c7ced4;
        margin-right: 10px;
        padding-right: 10px;
      }

      &:nth-child(2) {
        font-weight: 900;
      }
    }
  }

  .footer__block {
    display: flex;
    margin-top: 18px;
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  .footer__title {
    display: block;
    color: $gray_8;
    font-size: 11px;
    line-height: 1.5;
    font-weight: bold;
    flex: 1;
  }

  .footer__content__wrapper {
    margin-left: 45px;
    flex: 10;
    @media screen and (max-width: 450px) {
      margin-left: 0px;
      margin-top: 8px;
    }
  }

  .footer__content {
    display: block;
    margin-bottom: 8px;
    color: $gray_7;
    font-size: 11px;
    line-height: 1.6;
    letter-spacing: -0.1px;
  }

  .ftcInfo {
    &:hover {
      color: $gray_10;
    }
    text-decoration: underline;
    cursor: pointer;
  }
}

.footer__linker {
  font-size: 11px;
  font-weight: 500;
  color: $gray_8;
  padding-right: 8px;

  & + & {
    padding-left: 8px;
    border-left: 1px solid $gray_3;
  }
}
