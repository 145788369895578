@import "~styles/color";

.track-wrapper {
  display: flex;
  height: 36px;
  width: 100%;
  .track {
    align-self: center;
    height: 5px;
    width: 100%;
  }
  .oval {
    background-color: $white;
    box-sizing: border-box;

    border: solid 1px $blue;
    border-radius: 10px;

    width: 20px;
    height: 20px;
  }
}
