@import "~styles/color";
.wrapper {
  text-align: left;
  border-left: 1px solid $gray_3;
  border-right: 1px solid $gray_3;
  border-bottom: 1px solid $gray_3;
  padding: 32px 0px 0px 32px;
  > * {
    padding-bottom: 32px;
  }
  .head {
    font-size: 16px;
    font-weight: bold;
    color: $gray_9;
  }

  .description {
    font-size: 14px;
    line-height: 1.44;
    color: $gray_half_8;
  }
}
.process {
  .icon-wrapper {
    display: flex;
    justify-content: space-between;
    width: 332px;
    .arrow-wrapper {
      margin-top: 15px;
    }
  }
  .text-wrapper {
    display: flex;
    width: 332px;
    justify-content: space-between;
    .claim-text {
      width: 48px;
      text-align: center;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin-top: 16px;
    padding: 24px 10px 0px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: $white;
    > * {
      padding-bottom: 24px;
    }
    .head {
      font-size: 16px;
    }
    .description {
      width: 100%;
      font-size: 14px;
      line-height: 1.43;
      overflow: auto;
      white-space: pre-wrap;
    }
  }
  .process {
    .icon-wrapper {
      width: 100%;
      justify-content: space-around;
    }
  }
}
