@import "~styles/color";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 0px 34px 0px;
  width: 180px;
  border-top: 1px solid $gray_3;
  border-bottom: 1px solid $gray_3;
  cursor: pointer;
  & + & {
    border-left: 1px solid $gray_3;
  }
  &:last-child {
    width: 226px;
  }
}

.title {
  font-size: 13px;
  color: $gray_7;
  margin-bottom: 15px;
}

.count {
  font-size: 18px;
  font-weight: bold;
  color: $gray_8;
}

.isSelected {
  color: $blue;
}

@media (max-width: 450px) {
  .wrapper {
    border: none;
    padding: 20px 0px 22px 0px;
    width: 19%;
    & + & {
      border-left: none;
      .count {
        width: 100%;
        text-align: center;
        border-left: 1px solid $gray_3;
      }
    }
    &:last-child {
      width: 24%;
    }
  }
  .title {
    font-size: 11px;
    margin-bottom: 14px;
  }
  .count {
    font-size: 15px;
  }
}
