.wrapper {
  margin-left: 100px;
}
.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 24px;
}
.other {
  padding: 0px;
  margin-left: 0px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin-left: 0px;
  }
  .title {
    padding-left: 12px;
    margin-top: 45px;
  }
}
