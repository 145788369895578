@import "~styles/color";
.row {
  display: flex;
  & + & {
    margin-top: 8px;
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  .row-key {
    width: 120px;
    font-size: 14px;
    color: $gray_7;
    &.bold,
    &.keyBold {
      color: $gray_10;
      font-weight: 500;
    }
  }
  .row-value {
    font-size: 14px;
    color: $gray_9;
    line-height: 1.43;
    &.bold {
      font-weight: bold;
    }
    &.red {
      color: $red;
    }
    &.spaceBetween {
      text-align: right;
    }
  }
}

@media screen and (max-width: 450px) {
  .row {
    width: 100%;
    .row-key {
      flex: 1;
      font-size: 14px;
    }
    .row-value {
      flex: 2;
      font-size: 14px;
    }
  }
}
