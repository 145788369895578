@import "~styles/color";
.wrapper {
  text-align: left;
  font-size: 11px;
  color: $gray_7;
  li {
    text-indent: -8px;
    margin-left: 8px;
    line-height: 1.5;
    &::before {
      content: "- ";
    }
  }
}
