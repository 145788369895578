@import "~styles/color";
@import "~styles/variables";

.coupon-container {
  margin-bottom: 8px;
  position: relative;
  text-align: left;

  span {
    display: inline-block;
  }

  .method-txt {
    width: 12%;
    color: $gray_7;
  }

  .usable-coupon {
    cursor: pointer;
    color: white;
    background-color: $gray_10;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 2px;

    svg {
      vertical-align: sub;
      margin-left: 4px;
    }
  }
}
