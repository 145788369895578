@import "~styles/color";

.summary-wrapper {
  display: inline-flex;
  align-items: center;
  max-width: 360px;
  height: 28px;
  border: 1px solid $gray_1;
}

.reviewStarWrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.summary-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  height: 18px;
  padding-left: 8px;
  padding-right: 8px;
  border-left: 1px solid $gray_1;
  font-weight: 500;
  &.good {
    color: $good;
  }
  &.soso {
    color: $soso;
  }
  &.bad {
    color: $bad;
  }
}

@media (max-width: 450px) {
  .summary-wrapper {
    max-width: 100%;
    margin-top: 0;
  }
  .summary-text {
    flex: 1;
  }
}
