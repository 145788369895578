@import "~styles/color";
.wrapper {
  width: 154px;
  border-radius: 4px;
  border: 1px solid $blue;
  font-size: 14px;
  padding: 10px 0px;
  line-height: 1.5;
  color: $blue;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
  }
}
