@import "~styles/color";

label.checkbox {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;

  border: 2px solid $gray_4;
  border-radius: 2px;
  width: 18px;
  height: 18px;
}

label.checked {
  position: relative;
  border-color: $blue;
  background-color: $blue;

  &::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    height: 9px;
    width: 5px;
    top: 0px;
    left: 3px;
  }
}

.check-none {
  display: none;
}
