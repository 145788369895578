@import "~styles/color";
.wrapper {
  width: 930px;
}
.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 24px;
}

.other {
  padding: 0px;
  margin-left: 0px;
}

.not-search-wrapper {
  margin-top: 168px;
  .not-search-title {
    font-size: 18px;
    font-weight: bold;
    color: $gray_9;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  .not-search-description-wrapper {
    display: flex;
    justify-content: center;
    .not-search-description {
      font-size: 14px;
      color: $gray_7;
      line-height: 1.5;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    margin-left: 0px;
  }

  .title {
    padding-left: 12px;
    margin-top: 45px;
  }

  .not-search-wrapper {
    margin-top: 116px;
    .not-search-description-wrapper {
      flex-direction: column;
    }
  }
}
