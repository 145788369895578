// Color palette from Zeplin (https://app.zeplin.io/project/59c8b317704fea8e82384e9e/styleguide)
$cream: #fffde1;
$cream_light: #fffef0;
$cement: #a19e91;
$black: #000000;
$blue: #4285f4;
$blue_light: #77ade0;
$blue_lightgray: #8da5cc;
$darknavy: #040f3b;
$navy: #162357;
$red: #f23538;
$green: #06cc06;
$green_light: #19bf19;
$sky_blue: #6697ff;
$white: #ffffff;
$vibrant_green: #06d906;

$gray_0: #f9f9fa;
$gray_1: #f1f3f6;
$gray_2: #e6eaed;
$gray_3: #dee2e6;
$gray_4: #c7cdd4;
$gray_5: #b0b8bf;
$gray_6: #97a0a8;
$gray_7: #868e96;
$gray_half_8: #666e75;
$gray_8: #495057;
$gray_9: #343a40;
$gray_10: #212529;
$gray_11: #121517;
$battleship_gray: #727980;
$red_star: #f2616b;

$lv1: #e6d345;
$lv5: #51ccb8;
$lv8: #8f7ee6;
$lv11: #f3619e;
$lv13: #d91a1d;

$good: #f2747d;
$good_2: #ff4c58;
$soso: #77ade0;
$soso_2: #4496e3;
$bad: #484a78;
$bad_2: #2d307a;
