@import "~styles/color";

.bookmark {
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: $blue;
  border: 1px solid $blue;

  height: 30px;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 0.875rem;
  margin-left: 12px;

  &.isBookmark {
    color: $gray_6;

    border: 1px solid $gray_4;
  }
}

.status {
  margin-right: 6px;
  margin-top: 1px;
}
