@import "~styles/color";

.wrapper {
  display: flex;
  align-items: center;
  background-color: $white;
  box-sizing: border-box;

  height: 48px;

  border-bottom: 1px solid rgba($gray_4, 0.5);
}

.label {
  background-color: $blue;
  box-sizing: border-box;
  color: $white;

  width: 72px;
  height: 24px;
  padding-top: 5px;

  margin-left: 8px;

  font-size: 13px;
}

.keywords {
  color: $gray_9;

  width: 68%;

  margin-left: 10px;

  font-size: 14px;
}

.slider {
  display: flex;
  flex-direction: column;
}

.keyword {
  text-align: left;
  outline: none;
}

.rank {
  color: $blue;
  font-weight: bold;
}

.more {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  color: $gray_5;
}

.terms-section {
  background-color: $white;
  display: none;
}

.terms {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;

  .term {
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid rgba($gray_4, 0.5);

    text-decoration: none;
    color: $gray_9;
  }

  &:first-child {
    border-right: 1px solid rgba($gray_4, 0.5);
  }
}

.visible {
  display: flex;
}
