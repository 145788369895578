@import "~styles/color";

.picture {
  width: 48px;
  height: 48px;
  border-radius: 2px;
  margin-left: 8px;

  .img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-content: center;

  margin-left: 16px;
  height: 48px;
}

.name-and-star-and-message {
  display: flex;
  align-items: center;
}

.name {
  height: 22px;
  line-height: 22px;
  font-size: 15px;
  color: $gray_9;
}

.star {
  color: $red;
  font-size: 10px;
  margin-left: 2px;
}

.message {
  height: 17px;
  line-height: 17px;
  font-size: 11px;
  color: $blue_lightgray;
  margin-left: 6.2px;
}

.tags {
  height: 18px;
  font-size: 12px;
  color: $gray_6;
  text-align: left;
  margin-top: 8px;
}
