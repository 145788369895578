@import "~styles/color";

.image-wrapper {
  margin-top: 176px;
  margin-bottom: 24px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: $gray_9;
  margin-bottom: 16px;
}

.description-text {
  display: inline;
  font-size: 14px;
  color: $gray_7;
  line-height: 1.5;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 450px) {
  .image-wrapper {
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .description-text {
    display: block;
  }
}
