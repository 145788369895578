@import "~styles/color";

.wrapper {
  padding: 32px;
  border-bottom: 1px solid $gray_3;
  .header {
    font-size: 18px;
    font-weight: bold;
    color: $gray_9;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    background-color: $white;
    margin-top: 16px;
    padding: 24px 10px;
    border-bottom: none;
    .header {
      font-size: 16px;
    }
  }
}
