@import "~styles/animate";
@import "~styles/color";

.photoContents {
  width: 100%;
  height: 100%;
  border-radius: 4px;

  &[src] {
    animation: none;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */
  }
}

@media screen and (max-width: 450px) {
}
