@import "~styles/variables";
@import "~styles/color";

.nav {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  transition: 0.2s;

  .header {
    background: $black;

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .logo-wrapper {
        margin-left: 6px;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;

        button {
          padding: 0;
        }

        .cart-wrapper {
          position: relative;

          .cart-badge {
            position: absolute;
            color: $white;
            background-color: $red;

            height: 14px;
            width: 14px;
            border-radius: 50%;
            line-height: 12px;

            font-size: 9px;
            font-weight: bold;
            text-align: center;

            top: 21px;
            right: 7px;
          }
        }
      }
    }

    .routes {
      display: block;
      text-align: left;
      width: 100%;
      white-space: nowrap;
      overflow-x: scroll;
      height: 48px;
      line-height: 20px;
      touch-action: pan-x;

      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .route {
        display: inline-block;
        color: $white;

        margin: 14px 8px;

        font-size: 14px;
        font-weight: 500;
        opacity: 0.5;

        &.active {
          font-weight: bold;
          opacity: 1;
        }

        &.button {
          padding: 0;
        }
      }
    }
  }

  .sub-header {
    position: relative;
    background-color: $white;

    .status {
      display: flex;
      align-items: center;

      height: 40px;

      .member {
        display: flex;
        align-items: center;

        margin-left: 8px;

        .level-bg {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;

          height: 20px;
          width: 20px;

          border-radius: 50%;
        }

        .level-wrapper {
          display: flex;
          align-items: center;

          margin-left: 4px;

          height: 40px;

          font-size: 12px;

          .border-base {
            display: flex;
            position: relative;
            justify-content: space-between;

            height: 18px;
            width: 80px;

            border-bottom: 2px solid $gray_3;

            .border-percent {
              position: absolute;

              bottom: -2px;

              height: 2px;
            }

            .level {
              color: $gray_8;
              font-weight: bold;
              line-height: 18px;
            }

            .percent {
              color: $gray_7;
              margin-left: 6px;
              line-height: 18px;
            }
          }
        }

        .point-wrapper {
          display: flex;
          align-items: center;

          font-size: 12px;
          height: 40px;

          .point-icon {
            margin-left: 8px;

            height: 20px;
            width: 20px;
          }

          .point-text-wrapper {
            display: inline-block;
            color: $gray_8;
            margin-left: 4px;
            height: 18px;

            line-height: 18px;

            .point-text {
              font-weight: bold;
            }
          }
        }
      }

      .user {
        width: 204px;
        font-size: 12px;
        text-align: left;
        margin-left: 8px;
        font-weight: 500;
        color: $gray_half_8;

        .login {
          color: $blue;
          font-weight: bold;
          cursor: pointer;

          padding: 0;
        }
      }

      .division {
        background-color: $gray_4;
        box-sizing: border-box;

        opacity: 0.5;
        width: 1px;
        height: 18px;
        margin-left: 15px;
      }

      .recents-wrapper {
        display: flex;
        align-items: center;
        outline: none;

        height: 40px;
        flex: 1;

        .thumbnail-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          color: $white;
          background-color: $gray_5;

          margin-left: 7px;

          height: 20px;
          width: 20px;
          border-radius: 100%;

          .thumbnail {
            height: 20px;
            width: 20px;
            border-radius: 100%;
          }
        }

        .recent-text {
          color: $gray_8;
          line-height: 18px;

          margin-left: 8px;
          padding: 0;

          height: 18px;
          font-size: 12px;
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      box-sizing: border-box;

      border-top: solid 1px $gray_3;
      border-bottom: solid 1px $gray_3;

      height: 40px;

      .condition-wrapper {
        background: $gray_1;
        box-sizing: border-box;
        color: $gray_7;

        font-size: 12px;
        border-radius: 2px;
        margin: 5px;

        .product {
          padding: 0;
          height: 30px;
          width: 43px;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        .store {
          padding: 0;
          height: 30px;
          width: 54px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        .active {
          background-color: $white;
          color: $blue;

          border: solid 1px $blue;
        }
      }

      .keyword-wrapper {
        box-sizing: border-box;
        text-align: left;

        margin-left: 3px;
        margin-right: 12px;

        flex: 1;
        height: 30px;

        .keyword {
          width: 100%;
          height: 30px;
          font-size: 12px;
          border: 0;
          padding: 0;
          margin-right: 12px;

          &::placeholder {
            color: $gray_5;
          }
        }
      }

      .find-wrapper {
        width: 50px;
        height: 32px;
        margin: 4px;
        display: flex;
        align-items: center;

        .find {
          width: 100%;
          height: 30px;
          border-radius: 2px;
          background-color: $blue;
          font-size: 14px;
          color: $white;
          padding: 0;
        }
      }
    }
  }

  .categories {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    overflow-x: scroll;
    height: 40px;
    border-bottom: solid 1px $gray_2;
    padding: 0px 12px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.category {
  width: 100%;
  white-space: nowrap;
  & + & {
    margin-left: 12px;
  }
  .category-link {
    display: inline-block;
    text-decoration: none;
    color: $gray_9;
    font-size: 12px;
  }
}

.nav.hide {
  top: calc(#{$m-nav-block-height} * -2) !important;
  transition: 0.2s;
}
