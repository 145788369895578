@import "./color";

// react-slick
div.slick-slider {
  display: flex;
  flex-direction: column;

  ul.slick-dots {
    visibility: hidden;
    text-align: right;
    bottom: 5px;
    right: 5px;

    li {
      margin: 0;
    }

    li button::before {
      color: $white;
      text-shadow: 0px 0px 1px $gray_5;
      opacity: 0.75;
    }

    li.slick-active {
      button:before {
        color: $red;
        font-size: 14px;
        opacity: 1;
        transition: 0.5s;
      }
    }
  }

  div.slick-list {
    div.slick-slide {
      height: auto;
    }
  }

  .slick-arrow {
    z-index: 1;
  }

  .slick-prev,
  .slick-next {
    top: auto;
    left: auto;
    color: $gray_5;
    bottom: -5px;
  }

  .slick-prev {
    right: 80px;
  }

  .slick-next {
    right: 3px;
  }

  .slick-prev::before,
  .slick-next::before {
    content: none;
  }

  @media screen and (max-width: 450px) {
    ul.slick-dots {
      visibility: visible;
    }

    .slick-arrow {
      visibility: hidden;
    }
  }
}
