@import "~styles/color";

.select {
  box-sizing: border-box;
  user-select: none;

  width: 124px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid $gray_4;

  background: url("~assets/img/ic-more-down-gray.svg") no-repeat;
  background-position: right 8px center;
  background-size: 18px;

  &:focus {
    outline: none;
  }

  &.group {
    width: 180px;
  }
}

.select::-ms-expand {
  display: none;
}
@media screen and (max-width: 450px) {
  .select {
    font-size: 11px;
    width: 100%;
    border: 0px;

    &.group {
      width: 150px;
    }
  }
}
