@import "~styles/color";
.wrapper {
  text-align: left;
  border-left: 1px solid $gray_3;
  border-right: 1px solid $gray_3;
  border-bottom: 1px solid $gray_3;
}
.info-wrapper {
  background-color: $white;

  .info-body {
    display: flex;
    .status-info {
      padding: 32px;
      flex: 2;
    }
    .pay-info {
      padding: 32px;
      flex: 1;
      border-left: 1px solid $gray_3;
    }
  }
  .info-header {
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: bold;
    color: $gray_9;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    border-bottom: none;
  }
  .info-wrapper {
    margin-top: 16px;
    padding: 24px 10px 24px;
    .info-body {
      flex-direction: column;
      .status-info {
        display: block;
        padding: 0;
      }
      .pay-info {
        display: block;
        padding: 0;
        margin-top: 40px;
        border-left: none;
      }
    }
    .info-header {
      font-size: 16px;
    }
  }
}
