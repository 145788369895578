@import "~styles/color";
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px $gray_3;
  width: 205px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray_8;

  .status-text {
    font-size: 18px;
    font-weight: 500;
  }
  .tracking-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 62px;
    height: 26px;
    border: solid 1px $blue;
    border-radius: 5px;

    font-size: 12px;
    color: $blue;

    margin-top: 13px;
  }
}
.bank-info {
  text-align: center;
  margin-top: 14px;
  line-height: 1.54;
  .bank-text {
    font-size: 13px;
    color: $gray_7;
  }
}

.pay-expiration {
  margin-top: 13px;
  font-size: 15px;
}

.pay_cancel {
  color: $gray_6;
}

.pay_wait,
.ship_ing,
.re_ship {
  color: $blue;
}

.claim {
  color: $red;
}
