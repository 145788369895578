@import "~styles/color";

.wrapper {
  margin: 80px auto 0 auto;
}

.asset {
  margin-top: 32px;
}

.notice {
  color: $gray_9;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
  height: 27px;
  font-size: 18px;
}

.advise {
  color: $gray_7;
  text-align: center;

  margin-top: 16px;
  height: 44px;
  font-size: 14px;
  line-height: 1.57;
}
