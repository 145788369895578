@import "~styles/color";
.event-main {
  margin-left: 0px;
}
.header {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: $gray_10;
  padding-bottom: 24px;
}
.banner-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .banner {
    display: inline-block;
    width: 532px;

    margin-bottom: 36px;
    cursor: pointer;
    .banner-img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .header {
    font-size: 16px;
    font-weight: bold;
    color: $gray_9;
    padding: 24px 0px 16px 8px;
  }
  .banner-list {
    .banner {
      width: 100%;
      height: 200px;
      padding: 0px;
      margin: 0px 0px 30px 0px;
      .banner-img {
        width: 100%;
        height: 200px;
      }
    }
  }
}
