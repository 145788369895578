@import "~styles/color";

.main {
  margin-left: 0;
}

.types {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: $gray_5;

  width: 100%;
  height: 45px;
  border-bottom: solid 1px $gray_3;
}

.type {
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 3px;
}

.active {
  color: $red;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: solid 3px $red;
  margin-bottom: 0;
  transition: 0.5s;
}

.store {
  display: flex;
  align-items: center;

  height: 76px;
}
