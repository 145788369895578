.photoArrowNavWrapper {
  display: flex;
  justify-content: space-between;
}
.arrowWrapper {
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  &.right {
    justify-content: flex-end;
  }
}
