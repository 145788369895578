@import "~styles/color";

.cart-tab {
  box-sizing: content-box;
  .fire-emoji {
    font-family: "Noto Sans KR", "Segoe UI Emoji", sans-serif;
  }
  .wrapper-head {
    text-align: left;
    color: $gray_10;
    margin-bottom: 40px;
    padding: 0 10px;
    font-size: 20px;
  }

  .head-bar {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 10px 16px;

    .btn-delete {
      cursor: pointer;
      text-align: center;
      border: 1px solid $gray_5;
      color: $gray_9;
      width: 40px;
      height: 24px;
      margin-left: 12px;
      font-size: 12px;
      border-radius: 3px;
      padding: 0;
    }

    .checkbox {
      vertical-align: middle;
    }
  }

  .wrapper-list {
    margin-bottom: 64px;

    .list {
      background-color: $gray_0;
      padding-bottom: 24px;
      font-size: 14px;

      .title {
        text-align: left;
        font-weight: 500;
        background-color: $gray_2;
        padding: 10px 12px;
      }

      .mobile-element {
        display: block;
        padding: 12px 12px 0;

        .mobile-checkbox {
          display: flex;
          margin-bottom: 12px;
        }

        .mobile-item-box-wrapper {
          display: flex;
        }

        .thumbnail {
          width: 48px;
          height: 48px;
          display: flex;
          min-width: 48px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          width: 100%;
          text-align: left;
          line-height: 180%;
          padding-left: 12px;
          font-size: 14px;
          color: $gray_9;
          line-height: normal;

          .product-box {
            margin-bottom: 8px;

            .product-name {
              font-weight: 500;
              font-size: 14px;
              color: $gray_9;
              line-height: normal;
            }

            .red {
              display: block;
              color: $red;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .option-box {
            margin-bottom: 9px;

            .option-name {
              font-size: 14px;
              color: $gray_8;
              line-height: normal;
              font-weight: normal;
            }

            .red {
              display: block;
              color: $red;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .price-box {
            display: block;

            .notice-sale-wrapper {
              width: 100%;
              display: flex;
              flex-direction: row-reverse;
              margin-bottom: 8px;

              .notice-sale {
                padding: 1px 2px 1px 4px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: bold;
                color: $white;
                background-color: $red;
              }
            }

            .option-count-price {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .option-count {
                color: $gray_7;
                font-size: 14px;
              }

              .price-wrapper {
                display: flex;
              }

              .prev-price {
                color: $gray_4;
                text-decoration-line: line-through;
                font-size: 14px;
                letter-spacing: 0.5;
                font-weight: 500;
                margin-right: 4px;
              }

              .price {
                font-size: 14px;
                color: $gray_9;
                letter-spacing: 0.5;
                font-weight: bold;
              }
            }
          }
        }
      }

      .element {
        display: flex;
        padding: 12px 12px 0;

        .thumbnail {
          width: 48px;
          height: 48px;
          padding-left: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          text-align: left;
          line-height: 180%;
          padding-left: 10px;
          flex: 1;

          .product-name {
            font-size: 14px;
            font-weight: 500;
            color: $gray_9;
            line-height: normal;
          }

          .option-name {
            color: $gray_8;
            font-size: 14px;
          }

          .red {
            color: $red;
            font-size: 14px;
            font-weight: normal;
          }

          .price-count-wrapper {
            margin-top: 5px;

            .notice-sale-wrapper {
              width: 68px;
              height: 20px;
              display: flex;
              margin-bottom: 8px;
              position: relative;
              background-color: $red;
              align-items: center;
              border-radius: 2px;
              padding: 1px 2px 3px 6px;

              .notice-sale {
                font-size: 12px;
                font-weight: bold;
                color: $white;

                span {
                  font-size: 12px;
                }
              }
            }

            .option-count-price {
              display: flex;
              width: 100%;
              justify-content: space-between;

              .option-count {
                color: $gray_7;
                font-size: 14px;
              }

              .price-wrapper {
                display: flex;
              }

              .prev-price {
                color: $gray_4;
                text-decoration-line: line-through;
                font-size: 14px;
                letter-spacing: 0.5;
                font-weight: 500;
                margin-right: 4px;
              }

              .price {
                font-size: 14px;
                color: $gray_9;
                letter-spacing: 0.5;
                font-weight: bold;
              }
            }
          }
        }

        .link {
          display: flex;
          color: $gray_8;
          flex: 1;

          &.sold-out {
            pointer-events: none;
          }
        }
      }
    }
  }

  .btn-order {
    cursor: pointer;
    text-align: center;
    margin: auto;
    background-color: $red;
    color: $white;
    width: 40%;
    font-size: 18px;
    line-height: 52px;
    border-radius: 4px;
    z-index: 1;
  }

  .not-orderable {
    pointer-events: none;
    background-color: $gray_5;
  }

  @media screen and (max-width: 450px) {
    margin: 0;

    .wrapper-head {
      padding: 16px 0 0 10px;
    }

    .btn-order {
      position: fixed;
      border: 10px solid $white;
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 13px;
    }
  }
}

.sold-out {
  opacity: 0.4;
}
