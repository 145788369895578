@import "~styles/color";
@import "~styles/variables";

.rod-wrapper {
  width: 140px;
  height: 3px;
  border-radius: 2px;
  background-color: #f1f3f5;
  &.isBig {
    height: 7px;
    border-radius: 3px;
  }
}
.rod-fill {
  height: 100%;
  border-radius: 3px;
  &.good {
    background-color: $good_2;
  }
  &.soso {
    background-color: $soso;
  }
  &.bad {
    background-color: $bad;
  }
}

.graph-text {
  font-size: 11px;
  color: $gray_4;
  margin-left: 16px;
  &.isBig {
    font-size: 14px;
    font-weight: bold;
    &.good {
      color: $good_2;
    }
    &.soso {
      color: $soso_2;
    }
    &.bad {
      color: $bad;
    }
  }
}

.graphWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 450px) {
  .graphWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .graph-text {
    text-align: right;
    width: 56px;
    margin-right: 8px;
  }
  .rod-wrapper {
    margin-right: 8px;
  }
  .percent-text {
    text-align: right;
    width: 38px;
    font-size: 11px;
    color: $gray_4;
    &.isBig {
      font-size: 14px;
      font-weight: bold;
      &.good {
        color: $good_2;
      }
      &.soso {
        color: $soso_2;
      }
      &.bad {
        color: $bad;
      }
    }
  }
}
