.recent {
  flex: 4;
  margin-left: 150px;

  .sub-title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  button {
    width: 33.3%;
    height: 133px;
    padding: 0;
    margin: 0;
  }

  div:first-child {
    text-align: left;
    font-weight: 500;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    picture {
      display: flex;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }
  }
  .sold-out {
    position: relative;

    &::after {
      content: attr(data-msg);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-size: 12px;
      top: 0;
      left: 0;
      width: 100%;
      height: 133px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}

@media screen and (max-width: 450px) {
  .recent {
    margin-left: 0;
    margin-top: 40px;
    .sub-title {
      font-size: 1.14em;
      margin-left: 13px;
    }
  }
}
