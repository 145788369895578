/* autoprefixer grid: autoplace */

@import "~styles/color";

.title {
  font-weight: bold;
  text-align: left;
  color: $gray_10;

  height: 29px;
  font-size: 20px;
  margin-bottom: 39px;
}

.filter-container {
  display: flex;
}

.filter-list {
  display: flex;
  flex: 1;
  height: 40px;

  border-radius: 4px;
}

.filter-item {
  list-style: none;
  margin-right: 16px;
}

.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  // display: -ms-grid;
  // display: grid;
  // grid-gap: 32px 25px;
  // -ms-grid-columns: repeat(5, 180px);
  // grid-template-columns: repeat(5, 180px);
  // -ms-grid-rows: auto;
  // grid-template-rows: auto;
}

.hidden-card {
  width: 18.5%;
  visibility: hidden;
}

.main {
  min-height: 8200px;
}

@media screen and (max-width: 450px) {
  .main {
    background-color: $gray_1;
    margin-left: 0;
    padding-bottom: 150px;
  }

  .title {
    margin: 24px 0;
    line-height: 29px;
    margin-left: 12px;
    display: none;
  }

  .filter-container {
    display: block;

    min-width: 0;
    width: 100%;
  }

  .filter-list {
    border-bottom: 1px solid $gray_4;
    border-radius: 0;
  }

  .filter-item {
    flex-basis: 33.333333%;
    margin-right: 0;
    background-color: $white;
  }

  .hidden-card {
    width: 31.5%;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: auto;
  }
}
