@import "~styles/color";
@import "~styles/variables";

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
    width: 32px;
    height: 18px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 900;
}

.lv1,
.lv2,
.lv3,
.lv4 {
    color: $lv1;
    border: solid 1px $lv1;
}

.lv5,
.lv6,
.lv7 {
    color: $lv5;
    border: solid 1px $lv5;
}

.lv8,
.lv9,
.lv10 {
    color: $lv8;
    border: solid 1px $lv8;
}

.lv11,
.lv12 {
    color: $lv11;
    border: solid 1px $lv11;
}

.lv13 {
    color: $lv13;
    border: solid 1px $lv13;
}