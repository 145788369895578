@import "~styles/color";
.more-box {
  display: flex;
  align-items: center;
  color: $blue;
  font-size: 14px;
  font-weight: 500;

  .more {
    height: 16px;
  }
}
