@import "~styles/color";
.wrapper {
  border: solid 1px $gray_3;
  background-color: $white;
  & + & {
    margin-top: 24px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  padding: 19px 31px 16px 31px;

  .anchor {
    text-decoration: none;
    color: $gray_8;
  }
  .order-num {
    font-size: 15px;
    font-weight: 500;
  }
  .product-detail {
    display: flex;
    align-items: center;
    .margin {
      margin-right: 7px;
    }
    .order-detail {
      font-size: 13px;
    }
  }
}

.link:hover {
  .order-num {
    text-decoration: underline;
  }
}

.action-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  &.review-type {
    flex: 1;
    align-items: flex-end;
    padding-right: 32px;
  }
}
.body {
  display: flex;
}
