/* autoprefixer grid: autoplace */

@import "~styles/color";

.top {
  display: flex;

  margin-bottom: 88px;
}

.picture {
  width: 440px;
  height: 513px;
}

.img {
  width: 100%;
  height: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 504px;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 24px;
}

.title {
  color: $gray_9;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 36px;
  font-size: 24px;
}

.url-copy {
  color: $gray_9;
  background-color: $white;
  text-align: center;
  cursor: pointer;

  border: solid 1px $gray_5;
  border-radius: 4px;

  padding: 8px;
  font-size: 12px;
}

.description {
  color: $gray_8;
  text-align: left;
  white-space: pre-wrap;
  margin-top: 32px;
  font-size: 13px;
  line-height: 1.69;
}

.like {
  display: flex;
  justify-content: center;

  margin: 32px auto 0 auto;

  .url-copy {
    display: none;
  }
}

.tags {
  display: flex;

  margin-bottom: 24px;
}

.tag {
  color: $gray_8;
  background-color: $white;
  text-decoration: none;
  cursor: pointer;

  border: solid 1px $gray_4;
  margin-right: 14px;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
}

.hr {
  color: $gray_1;
  opacity: 0.2;
  &.only-mobile {
    display: none;
  }
}

.products-title {
  margin-top: 88px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: repeat(5, 1fr);
  // grid-template-rows: auto;

  margin-top: 24px;
}

.trending {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title-wrapper {
  margin-top: 24px;
}
@media screen and (max-width: 450px) {
  .main {
    margin-left: 0;
  }

  .top {
    display: block;
    margin-bottom: 0;
  }

  .actions {
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px;
    width: 100%;
  }

  .title {
    font-size: 18px;
    height: 30px;
    margin-left: 12px;

    .url-copy {
      display: none;
    }
  }

  .description {
    margin-top: 24px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .like {
    display: flex;
    justify-content: space-between;

    margin-top: 24px;
    margin-left: 12px;
    margin-right: 12px;

    .url-copy {
      display: inline;
    }
  }

  .tags {
    margin-top: 24px;
    margin-left: 12px;
    margin-bottom: 24px;
  }

  .store {
    margin-top: 12px;
    margin-left: 12px;
  }

  .trending {
    margin-top: 24px;
    margin-left: 6px;
    margin-right: 6px;
  }
  .hr {
    margin: 0;
    border: none;
    opacity: 1;
    width: 100%;
    height: 8px;
    background-color: $gray_1;
    &.only-mobile {
      display: block;
    }
  }
}

.hide-border {
  border: 0;
}
