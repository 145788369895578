@import "../../../styles/color";

.container {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0px 0px 15px;
  font-size: 14px;
  z-index: 1000;
  background: linear-gradient(216deg, rgba(119, 143, 249, 0.9), rgba(66, 133, 244, 0.9));

  .close {
    text-align: right;
    color: $white;

    svg {
      width: 18px;
      padding: 10px 0 0;
    }
  }
}

.box {
  text-align: center;
  margin: 0 0 15px;

  .txt {
    line-height: 160%;

    p {
      color: white;
      margin: 4px 0;
    }
  }
}

.open-app-btn {
  margin: auto;
  background-color: white;
  width: 296px;
  font-size: 14px;
  border-radius: 5px;
  padding: 12px 0;

  &:focus {
    outline: none;
  }
}
