@import "~styles/color";

.info__txt--head {
  text-align: left;
  font-size: 14px;

  @media screen and (max-width: 450px) {
    position: relative;
    left: -12px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 18px;
    padding-left: 12px;
    background-color: $gray_0;
    font-size: 15px;
    color: $gray_9;
  }
}

.info__wrapper--content {
  margin-bottom: 60px;
  font-size: 14px;
  color: $gray_7;
  text-align: left;

  @media screen and (max-width: 450px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  div {
    &.highlight {
      color: $blue;

      .info__txt--message {
        font-weight: 700;
        color: $blue;
      }
    }

    &.failure {
      color: $red;

      .info__txt--message {
        color: $red;
      }
    }
  }

  .info__list {
    margin-bottom: 16px;

    @media screen and (max-width: 450px) {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .info__txt--title {
      width: 120px;
      font-size: 14px;
      color: inherit;

      @media screen and (max-width: 450px) {
        margin-top: 2px;
        font-size: 13px;
        vertical-align: top;
      }
    }

    .info__txt--message {
      font-size: 14px;
      color: $gray_8;

      @media screen and (max-width: 450px) {
        width: calc(100% - 132px);
        font-size: 13px;
        line-height: 1.5;
      }
    }

    .info__originalPrice {
      margin-left: 5px;
      text-decoration: line-through;
      color: $gray_4;
    }

    div {
      display: inline-block;
    }
  }
}

.info__btn--myLookpin {
  display: inline-block;
  width: 175px;
  height: 52px;
  border: 1px solid $gray_7;
  margin-top: 33px;
  margin-right: 40px;
  font-size: 18px;
  color: $gray_8;
  background-color: $white;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
  box-sizing: border-box;

  @media screen and (max-width: 450px) {
    width: 136px;
    margin-right: 24px;
  }
}

.info__btn--main {
  @extend .info__btn--myLookpin;
  margin-right: 0;
  color: $white;
  border: 1px solid $darknavy;
  background-color: $darknavy;
}
