@import "~styles/color";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.review-button {
  position: relative;
  width: 154px;
  height: 40px;
  background-color: $blue;
  color: $white;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px;
  & + & {
    margin-top: 10px;
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $gray_2;
    color: $gray_6;
    cursor: default;
  }

  &.small {
    width: 80px;
  }
}

.fund-wrapper {
  position: absolute;
  font-size: 14px;
  color: $gray_10;
  background-color: $gray_1;
  border-radius: 4px;
  padding: 5px 6px;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0);
  span {
    font-weight: bold;
    color: $blue;
  }

  &.down {
    top: -34px;
    &:after {
      border-top: 6px solid $gray_1;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &.up {
    bottom: -34px;
    &:after {
      border-top: 0px solid transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid $gray_1;
      content: "";
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.dummy-height {
  width: 1px;
  height: 36px;
  content: " ";
}

@media screen and (max-width: 450px) {
  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 19px;
    padding-bottom: 24px;
  }
  .review-button {
    width: 48%;

    & + & {
      margin-top: 0px;
    }
  }
}
