@import "~styles/color";

.banner-tab {
  position: relative;
  width: 680px;

  button {
    cursor: pointer;
    padding: 0;
    margin: 0;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .slide-box {
    position: absolute;
    color: white;
    width: 104px;
    right: 0;
    bottom: 1px;
    padding: 7px 0;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.4);

    button#prev,
    button#next {
      visibility: hidden;
    }

    .slide-count {
      text-align: center;
      width: 100%;

      span:last-child {
        color: $gray_5;
      }
    }
  }

  @media screen and (max-width: 450px) {
    flex: auto;
    width: 100%;

    .slide-box {
      visibility: hidden;
    }

    button {
      height: auto;
    }
  }
}

$paddingBottom: ((690 / 1080) * 100) * 1%;

.banner-image-wrap {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: $paddingBottom;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
