@import "~styles/color";
.orders-tab {
  text-align: left;
  width: 949px;
}
.title {
  text-align: left;
  font-size: 20px;
}

.select-toggle-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  .margin-wrapper {
    margin-right: 20px;
  }
}

.nav-wrapper {
  display: flex;
  background-color: $white;
  padding: 0px 8px;
}

.list-wrapper {
  margin-top: 52px;
  margin-bottom: 31px;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 282px;
}

@media (max-width: 450px) {
  .orders-tab {
    width: 100%;
    margin-left: 0px;
    padding-bottom: 0px;
  }

  .title {
    padding: 22px 0px;
    margin-left: 3.6%;
  }

  .select-toggle-wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: $white;
    margin-bottom: 8px;
    padding-bottom: 12px;
    .margin-wrapper {
      margin-right: 4%;
    }
  }

  .list {
    background-color: $gray_1;
    margin-bottom: 0px;
  }
  .list-wrapper {
    margin-top: 9px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray_1;
    height: 108px;
  }

  .loading-wrapper {
    height: 510px;
    margin-top: 0px;
    opacity: 0.9;
  }
}

.bold {
  font-weight: bold;
}

.blue {
  color: $blue;
}

.red {
  color: $red;
}
