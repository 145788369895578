@import "~styles/color";

.container {
  width: 364px;
  height: 261px;
  border-radius: 4px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: bold;

  padding: 22px;
}

.previous {
  display: none;
}

.close {
  position: relative;
  cursor: pointer;

  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: $gray_8;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.notice {
  text-align: left;

  margin-top: 8px;
  margin-left: 22px;

  font-size: 14px;
  color: $gray_8;
}

.contents {
  margin-top: 24px;
}

.code {
  background-color: $white;
  box-sizing: border-box;

  width: 320px;
  height: 42px;
  border-radius: 4px;
  border: solid 1px $gray_3;

  padding: 12px;

  &::placeholder {
    color: $gray_5;
    font-size: 14px;
  }
}

.submit {
  color: $white;
  background-color: $darknavy;

  margin-top: 24px;
  padding: 0;
  width: 320px;
  height: 52px;
  border-radius: 4px;
}

@media screen and (max-width: 450px) {
  .container {
    width: 100%;
  }

  .header {
    justify-content: flex-start;
    font-weight: normal;

    height: 56px;
    font-size: 20px;

    padding: 0;

    border-bottom: solid 1px $gray_3;
  }

  .previous {
    display: inline;
    color: $gray_8;

    padding: 0;

    margin-left: 12px;
    margin-right: 8px;
  }

  .left-arrow {
    object-fit: cover;

    width: 100%;
  }

  .close {
    display: none;
  }

  .notice {
    color: $gray_10;
    text-align: left;

    margin-left: 26px;
    margin-top: 24px;

    font-size: 15px;
  }
}
