@import "~styles/color";

.input-none {
  display: none;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: $gray_1;
  cursor: pointer;
}
