@import "~styles/color";
@import "~styles/variables";
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  width: 36px;
  height: 16px;
  border-radius: 12px;
}

.lv1,
.lv2,
.lv3,
.lv4 {
  color: $lv1;
  border: 1px solid $lv1;
}

.lv5,
.lv6,
.lv7 {
  color: $lv5;
  border: 1px solid $lv5;
}

.lv8,
.lv9,
.lv10 {
  color: $lv8;
  border: 1px solid $lv8;
}

.lv11,
.lv12 {
  color: $lv11;
  border: 1px solid $lv11;
}

.lv13 {
  color: $lv13;
  border: 1px solid $lv13;
}
