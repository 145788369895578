@import "~styles/color";
.wrapper {
  padding: 16px 16px 23px;
  & + & {
    border-top: 1px solid $gray_3;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.product-image {
  width: 34px;
  height: 34px;
  border-radius: 4px;
}

.product-name {
  font-size: 13px;
  color: $gray_8;
  margin-left: 16px;
}

.section {
  margin-bottom: 16px;
}

.product-option {
  font-size: 12px;
  color: $gray_7;
}

.body {
  text-align: left;
  width: 100%;
  font-size: 12px;
  margin-top: 23px;
  color: $gray_9;
}

.review-contents {
  white-space: pre-wrap;
  color: $gray_9;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
}

.picture {
  & + & {
    margin-left: 8px;
  }
}

.review-images {
  width: 64px;
  height: 64px;
  border-radius: 4px;
}

.change-button {
  padding: 0px;
  font-size: 14px;
  color: $blue;
  cursor: pointer;
}

.bottom-wrapper {
  display: flex;
  justify-content: space-between;
}

.bottom-str {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $gray_7;
}

.margin-text {
  margin-left: 4px;
}
