@import "~styles/color";

.search {
  display: flex;
  align-items: center;

  padding-bottom: 16px;
  border-bottom: solid 1px $gray_10;

  margin-bottom: 40px;
}

.input {
  flex: 1;
  height: 36px;
  font-size: 22px;
  line-height: 36px;
  padding: 0;
  border: 0;
  padding-right: 24px;

  &::placeholder {
    color: $gray_5;
  }
}

.submit {
  width: 70px;
  height: 36px;
  background-color: $black;

  padding: 0;
  color: $white;
}

@media screen and (max-width: 450px) {
  .search {
    display: none;
  }
}
