@import "~styles/color";

.review-thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $gray_0;
  height: 80px;
  padding: 0 31px;
  margin-bottom: 83px;
}

.total-summary {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.total-summary-count {
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
  color: #222222;
}

.divider {
  width: 2px;
  height: 20px;
  background-color: #cccccc;
  margin: 1px 20px 0 18px;
}

.satisfaction-wrapper {
  display: flex;
  align-items: center;
}

.satisfaction-count {
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  color: #f7445d;
  margin-left: 5px;
}

.satisfaction-user {
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  margin-left: 8px;
  color: #999999;
}

.review-description-wrapper {
  display: flex;
  align-items: center;

  svg {
    margin-top: 1px;
  }
}

.review-description-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #4285f4;
  margin-left: 5px;
}
