@import "~styles/color";

.title {
  font-size: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  color: $gray_9;
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 32px;

  height: 44px;

  border-top: solid 1px $gray_3;
  border-bottom: solid 1px $gray_3;
}

.info-property {
  display: flex;
  align-items: center;

  color: $gray_10;

  font-size: 14px;

  box-sizing: border-box;
}

.info-property:nth-child(n + 2) {
  margin-left: 63px;
}

.property {
  color: $gray_8;

  text-align: left;
  margin-right: 24px;
}

.info-product-picture {
  width: 28px;
  height: 28px;
  margin-right: 6px;

  display: inline-block;
  box-sizing: border-box;
}

.info-product-img {
  height: 100%;
  width: 100%;
  border: solid 1px rgba(206, 212, 218, 0.5);
}

.type {
  margin-top: 16px;
  width: 996px;
  height: 44px;
}

.select {
  text-indent: 16px;
  font-size: 14px;

  width: 100%;
  height: 100%;
  border: solid 1px $gray_3;
  background-color: $white;

  color: $gray_8;
}

.select:focus {
  outline: none;
}

.select::-ms-expand {
  display: none;
}

.area {
  margin-top: 16px;

  width: 996px;
  height: 496px;
  background-color: $white;
}

.contents {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: solid 1px $gray_3;
  resize: none;

  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  padding: 20px 16px;
}

.contents:focus {
  outline: none;
}

.contents::placeholder {
  color: $gray_7;
  font-family: "Noto Sans KR", sans-serif;
}

.notice {
  margin-top: 16px;

  font-size: 14px;
  color: $gray_8;

  text-align: left;
}

.contacts {
  color: $blue;
}

.actions {
  margin-top: 48px;
}

.cancel,
.submit {
  padding: 0;
  text-align: center;
  cursor: pointer;

  width: 175px;
  height: 52px;
}

.cancel {
  border: solid 1px $gray_7;
  background-color: $white;
  color: $gray_8;
}

.submit {
  background-color: $darknavy;
  color: $white;

  margin-left: 40px;
}

.disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 450px) {
  .main {
    margin-left: 0;
    background-color: $gray_0;
  }

  .title {
    height: 68px;
    padding-left: 8px;

    font-size: 20px;
    font-weight: bold;
    color: $gray_9;
  }

  .info {
    flex-direction: column;
    border: none;
    height: 108px;
    margin-top: 0;
    background-color: $white;
  }

  .info-property {
    height: 36px;
    width: 100%;
    font-size: 12px;

    border-bottom: solid 1px $gray_3;

    margin-left: 0;
  }

  .info-property:nth-child(n + 2) {
    margin-left: 0px;
  }

  .property {
    color: $gray_6;
    width: 53px;

    margin-right: 0;
    margin-left: 8px;
  }

  .type {
    height: 60px;
    background-color: $gray_0;

    display: flex;
    align-items: center;

    margin-top: 0;
    width: 100%;
  }

  .select {
    font-size: 12px;
    margin: 0 8px;
    width: calc(100% - 16px);
    height: 40px;
    background-color: $white;
  }

  .area {
    margin-top: 0;

    width: 100%;
    height: 360px;

    textarea {
      border-left: none;
      border-right: none;
    }
  }

  .notice {
    line-height: 150%;
    font-size: 12px;
    margin: 16px 8px 0 8px;
  }

  .cancel,
  .submit {
    width: 136px;
    height: 40px;
  }
}
