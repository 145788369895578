@import "~styles/color";
@import "~styles/variables";

.option-hr {
  border: 1px solid rgba(241, 243, 246, 0.5);

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.count-with-option {
  margin: 4px 0;
  padding: 0 4px 4px;
  font-size: 12px;
  line-height: 28px;
  border-bottom: 1px solid $gray_3;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .option-container {
    &::after {
      display: block;
      clear: both;
      content: "";
    }

    .icon-cancel {
      float: right;
      position: relative;
      top: 6px;
      width: 16px;
      height: 16px;
      border: 1px solid $gray_7;
      border-radius: 50%;
      cursor: pointer;

      span {
        position: absolute;
        top: -6px;
        left: 3px;
        font-size: 20px;
        transform: rotate(45deg);
        color: $gray_7;
      }
    }

    .selected {
      float: left;
      width: 300px;
      color: $gray_10;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
  .counter {
    float: left;
    width: 115px;
    margin-bottom: -2px;

    .number {
      display: inline-block;
      margin: 0 10px;
      width: 15px;
      text-align: center;
      vertical-align: top;
      cursor: default;
    }

    .count-btn {
      position: relative;
      top: 2px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      padding: 0;
    }
  }

  .item-price {
    float: right;
    text-align: right;
  }
}
@media screen and (max-width: 450px) {
  .count-with-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 36px;
    border: none;
    padding: 0;
    line-height: 36px;
    text-align: left;

    .icon-cancel {
      display: inline-block;
      width: 17px;
      height: 17px;
      border: 1px solid $gray_7;
      border-radius: 50%;
      margin-right: 7px;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      vertical-align: top;
      background-color: $white;

      .cancel {
        display: inline-block;
        border: none;
        vertical-align: top;
        line-height: 1;
        transform: rotate(45deg);
        color: $gray_7;
      }
    }

    .option-container {
      display: inline-block;
      width: calc(100% - 31px);
      border: 1px solid $gray_3;
      padding-left: 12px;
      padding-right: 6px;
      text-align: right;
      vertical-align: top;
      background-color: $white;
      box-sizing: border-box;

      .selected {
        line-height: 34px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 50%;
        color: $gray_10;
        font-size: 12px;
        word-wrap: break-word;
        display: inline-block;
        box-sizing: border-box;
      }

      .counter {
        width: 50%;
        margin-bottom: -2px;
        display: inline-block;
        box-sizing: border-box;
      }

      .count-btn {
        position: relative;
        top: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        padding: 0;
      }

      .number {
        height: 36px;
        line-height: 34px;
        border-right: none;
        display: inline-block;
        margin: 0 10px;
        text-align: center;
        vertical-align: top;
      }
    }
  }
}
