@import "~styles/color";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 282px;
}

.text {
  font-size: 15px;
  color: $gray_5;
  margin-top: 25px;
}

@media (max-width: 450px) {
  .wrapper {
    height: 510px;
    margin-top: 0px;
  }

  .text {
    font-size: 17px;
  }
}
