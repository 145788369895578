@import "~styles/color";

.level-tab {
  text-align: left;
  width: 678px;

  header.title {
    text-align: left;
    font-size: 20px;
  }

  .current,
  .formula {
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      span {
        font-size: 12px;
        margin-left: 6px;
        font-weight: normal;
      }
      .graylight {
        color: $gray_7;
      }
      .highlight {
        color: $blue;
      }
    }
  }

  section.current {
    padding-top: 48px;

    .level {
      font-size: 22px;
      margin: 25px 0;
    }

    .info {
      border: 1px solid $gray_3;
      margin-bottom: 48px;

      ul {
        font-size: 14px;

        li {
          display: flex;
          justify-content: space-between;
          padding: 18px 16px;

          .name {
            color: $gray_half_8;
          }

          .desc {
            color: $gray_9;
            font-weight: 500;
          }
        }

        li:not(:last-child) {
          border-bottom: 1px solid $gray_3;
        }
      }
    }
  }

  section.formula {
    .desc {
      color: $gray_8;
      line-height: 180%;
      margin: 16px 0 32px;
      font-size: 12px;
    }

    .btm-desc {
      color: $gray_8;
      margin: 10px 0;
      font-size: 11px;
    }
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-left: 0;

    header.title {
      padding: 16px 0 0 10px;
    }

    .current,
    .formula {
      .title {
        padding-left: 10px;
      }
    }

    section.current {
      padding-top: 36px;

      .level {
        padding-left: 10px;
        font-size: 20px;
        letter-spacing: 0.8px;
        margin: 18px 0px 28px;
      }

      .info {
        margin-bottom: 32px;
        ul {
          li {
            padding: 18px 12px;
          }
        }
      }
    }

    section.formula {
      .desc {
        margin: 10px 0px 32px;
        padding-left: 10px;
        .text-indent {
          display: inline-block;
          padding-left: 8px;
          text-indent: -8px;
          width: 86%;
        }
      }

      .level-info {
        content: url("~assets/img/img-level-info-mobile.png");
        width: 100%;
      }

      .btm-desc {
        margin: 10px;
      }
    }
  }
}
