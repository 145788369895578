@import "~styles/color";

.wrapper {
  display: flex;

  .image {
    width: 56px;
    height: 56px;
  }

  .name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 10px;
  }

  .store-name {
    font-size: 13px;
    color: $gray_7;
    margin-bottom: 8px;
  }

  .product-title {
    font-size: 14px;
    line-height: 1.5;
    color: $gray_10;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 400px;
    overflow: hidden;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    .product-title {
      font-size: 14px;
      line-height: 1.5;
      color: $gray_10;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      width: 250px;
      overflow: hidden;
    }
  }
}
