@import "~styles/color";

.issuable-coupons {
  width: 592px;
  font-size: 16px;

  .contents {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: $gray_1;
    height: 560px;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    height: 100%;

    .contents {
      overflow: unset;
      height: 100%;
      padding-top: 10px;
    }
  }
}
