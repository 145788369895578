@import "~styles/color";
.filter-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.close-button {
  cursor: pointer;
}

.header-text {
  font-size: 16px;
  font-weight: 500;
  color: $gray_10;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.filter-sale {
  display: flex;
  align-items: center;
  color: $gray_6;
  cursor: pointer;
  margin-bottom: 32px;
  padding: 0px;
  .filter-sale-font {
    font-size: 15px;
    margin-left: 18px;
    color: $gray_8;
  }
}

.filter-wrapper {
  padding: 20px 16px;
  text-align: left;
  position: absolute;
  background-color: $white;
  border: solid 1px $gray_4;
  border-radius: 5px;
  width: 308px;
  left: 100%;
  transform: translate3d(-100%, 0, 0);
  z-index: 999;

  &:focus {
    outline: none;
  }
}

.filter-content-wrapper {
  border-bottom: solid 1px $gray_2;
  padding: 0px 8px 16px;
}

.bottom-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px 0px;
  .nav-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    color: $gray_half_8;
    &.submit {
      color: $blue;
    }
  }
}
.category-button {
  padding: 0px;
  cursor: pointer;
  display: inline-flex;
  justify-self: flex-start;
  align-items: center;
  width: 50%;
  margin-top: 16px;
  .filter-category-text {
    margin-left: 16px;
    font-size: 15px;
    color: $gray_8;
  }
}
@media screen and (max-width: 450px) {
  .close-button {
    display: none;
  }

  .header-text {
    font-size: 15px;
    font-weight: 500;
    color: $gray_10;
  }
  .filter-sale {
    margin-bottom: 40px;
    .filter-sale-font {
      font-size: 14px;
      margin-left: 16px;
    }
  }
  .filter-background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    z-index: 999;
    display: flex;
    align-items: flex-end;
  }

  .category-button-mobile {
    display: inline-block;
    padding: 6px 10px;
    background-color: $gray_1;
    color: $gray_5;
    margin-bottom: 16px;
    margin-right: 12px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.5;
    &.select {
      background-color: $blue;
      color: $white;
    }
  }
  .category-header {
    margin-bottom: 24px;
  }

  .filter-header {
    margin-bottom: 16px;
  }

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    border-radius: 0px;
    position: static;
    width: 100%;
    transform: translate3d(0, 0, 0);
    text-align: left;
  }

  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 0px;
    .nav-button {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: $gray_4;
      font-size: 18px;
      font-weight: 500;
      color: $white;
      &.submit {
        background-color: $black;
        color: $white;
      }
    }
  }

  .filter-content-wrapper {
    border-bottom: none;

    padding: 32px 16px 12px;
  }
}
